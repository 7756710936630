export default value => {
  if (value === 'VERIFIED') {
    return 'Verified';
  } if (value === 'NOT_VERIFIED') {
    return 'Not Verified';
  } if (value === 'PROCESSED') {
    return 'Processed';
  } if (value === 'VERIFICATION_PENDING') {
    return 'Pending';
  } return 'Not Verified';
};
