import { DONATION_DETAILS_PAGES } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  donationPageDetailsStatus: undefined,
  donationPageDetailsError: undefined,
  donationSlug: [],
  payments: {},
  offlineDonations: [],
  donationReports: [],
  donationPagesEntries: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DONATION_DETAILS_PAGES.FETCH:
      return {
        ...state,
        donationPageDetailsStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case DONATION_DETAILS_PAGES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        donationPageDetailsStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case DONATION_DETAILS_PAGES.FAILED:
      return {
        ...state,
        donationPageDetailsError: action.payload,
        donationPageDetailsStatus: CONSTANTS.API_STATUS.FAILED,
      };
    case DONATION_DETAILS_PAGES.SLUG_SUCCESS:
      return {
        ...state,
        donationSlug: action.payload,
      };
    case DONATION_DETAILS_PAGES.DONATION_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };
    case DONATION_DETAILS_PAGES.DONATION_OFFLINE_PAYMENTS_SUCCESS:
      return {
        ...state,
        offlineDonations: action.payload,
      };
    case DONATION_DETAILS_PAGES.DONATION_PAGE_SUCCESS:
      return {
        ...state,
        donationPagesEntries: action.payload,
      };
    default:
      return state;
  }
};
