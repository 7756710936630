import { DONATION_PAGES } from '../actionTypes';

export const fetchDonationPages = action => ({
  type: DONATION_PAGES.FETCH,
  payload: action.payload,
});

export const addDonationPage = (action) => ({
  type: DONATION_PAGES.DONATION_ADD,
  payload: action.payload,
});

export const updateDonationPage = (action) => ({
  type: DONATION_PAGES.DONATION_UPDATE,
  payload: action.payload,
});
