/* eslint-disable no-shadow */
import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  Icon,
  Select,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import {
  donationPageDetailsActions,
  donationPagesActions,
  notificationActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  PAGES,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';
import CONSTANTS from 'store/constants';
import getPlaceholder from 'utils/getPaymentPagePlaceholder';
import formatPaymentNumber from 'utils/formatNumber';
import imageOnError from 'utils/imageOnError';
import formatAmount from 'utils/formatAmount';
import { validationRegex } from 'utils/validationMessages';
import reorderIcon from '../../assets/imgs/reorder-icon.png';
import arrowUp from '../../assets/imgs/arrow-upward.png';
import arrowDown from '../../assets/imgs/arrow-down.png';
import arrowUpDisabled from '../../assets/imgs/arrow-upward-disabled.png';
import arrowDownDisabled from '../../assets/imgs/arrow-down-disabled.png';

import PageDetails from './components/PageDetails';
import ReviewPaymentPage from './components/ReviewPaymentPage';


const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;

type Props = ContextRouter & {
  selectedAccount: Object,
  addDonationPage: Function,
  paymentPagesDetailsStatus: string,
  paymentPagesDetails: Object,
  validator: boolean,
  submitting: boolean,
  loading: boolean,
  accountSlug: Array<Object>,
  history: {
    replace: Function,
  },
  setNotification: Function,
  fetchDonationPageDetails: Function,
  donationPageDetails: Object,
  donationPageDetailsStatus: Boolean,
  updateDonationPage: Function,
};

const UpdateDonationPage = (props: Props) => {
  const {
    selectedAccount,
    history,
    addDonationPage,
    validator,
    submitting,
    setNotification,
    fetchDonationPageDetails,
    donationPageDetails,
    donationPageDetailsStatus,
    updateDonationPage,
  } = props;
  const { gid } = props.match.params;
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  // const [documenturl, setDocumenturl] = useState();
  // const [description, setDescription] = useState();
  const [logo, setLogo] = useState();

  const [termsAndConditions, setPageTerms] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [venueAddress, setAddress] = useState('');
  const [receiptNote, setReceiptNote] = useState('');
  const [coordinate, setCoordinates] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [ytVideoHeading, setYoutubeHeading] = useState('');
  const [ytVideoDescription, setYoutubeDescription] = useState('');
  const [ytVideoId, setytVideoId] = useState('');
  const [location, setLocation] = useState(null);
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setColorCode] = useState('#007bff');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [paymentHeading, setPaymentHeading] = useState(null);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  const [enableTip, setEnableTip] = useState(false);
  const [isTipOptional, setIsTipOptional] = useState(true);
  const [firstOption, setFirstOption] = useState(null);
  const [secondOption, setSecondOption] = useState(null);
  const [thirdOption, setThirdOption] = useState(null);
  const [intervalMsg, setIntervalMsg] = useState('');
  const [donationPageIntervals, setDonationPageIntervals] = useState([]);
  const [defaultInterval, setDefaultInterval] = useState('One-time');
  const [amountList, setAmountList] = useState([{ amount: null }]);
  const [addCustomAmount, setAddCustomAmount] = useState(false);
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [showOfflinePayment, setShowOfflinePayment] = useState(false);
  const [paymentPageMetafields, setPaymentPageMetafields] = useState();
  const [tipMetafield, setTipMetafield] = useState([]);
  const [tipGid, setTipGid] = useState();
  const [imageSupport, setImageSupport] = useState('VERTICAL');
  const {
    contactEmail,
    name,
    largeLogo,
  } = selectedAccount;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const currencyType = selectedAccount && selectedAccount.currency;

  const account = { name: name || null, largeLogo: largeLogo || null };

  const [productDetailsArray, setProductDetailsArray] = useState([{
    collaboratorType: 'PROVIDER',
    name: '',
    email: '',
  }]);

  const pageData = {
    title,
    content,
    logo,
    preview,
    fileName,
    contactUsEmail,
    contactPhone,
    phoneNumberCode,
    termsAndConditions,
    location,
    primaryColor,
    venueAddress,
    receiptNote,
    coordinate,
    redirectUrl,
    paymentHeading,
    ytVideoId,
    youtubeUrl,
    phoneFormate,
    ytVideoHeading,
    ytVideoDescription,
    serviceFeeEnabled,
    serviceFeeRate,
    serviceFee,
    taxPercentage,
    supportOfflinePayment,
    imageSupport,
  };


  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minLimit: {
        message: 'Min limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val?.replace(/,/g, '')) && Number(val && val?.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val?.replace(/,/g, '')) && Number(val && val?.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      optionalMinAmount: {
        message: 'Min amount should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      uploadLimit: {
        message: 'Upload limit should be between 1 and 100',
        rule: (val) => val && Number(val) > 0 && Number(val) <= 100,
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  // const handleDescriptionChange = (event) => {
  //   setDescription(event.target.value);
  // };

  const [customerDetailsArray, setCustomerDetailsArray] = useState([{
    type: 'INPUT_EMAIL',
    label: 'Email',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  {
    type: 'INPUT_PHONE',
    label: 'Phone',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  ]);

  const addProductDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setShow(true);
    setProductDetailsArray([...productDetailsArray, {
      collaboratorType: 'PROVIDER',
      name: '',
      email: '',
    }]);
  };

  const removeProductDetails = (i) => {
    if (productDetailsArray.length === 2) {
      setShow(false);
    }
    const list = [...productDetailsArray];
    list.splice(i, 1);
    setProductDetailsArray(list);
  };

  const addCustomerDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setCustomerDetailsArray([...customerDetailsArray, {
      type: '',
      label: '',
      isRequired: true,
      checked: 'checked',
      value: '',
      validation: '',
      disableRequired: false,
      documentUrl: '',
      description: '',
      quantityMinLimit: '',
      quantityMaxLimit: '',

    }]);
  };

  const removeCustomerDetails = (j) => {
    const list = [...customerDetailsArray];
    list.splice(j, 1);
    setCustomerDetailsArray(list);
  };

  const getPlaceDetails = async (place) => {
    if (place) {
      const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${GOOGLE_MAPS_API_KEY}`)
        .then(response => response.json())
        .catch(error => console.error(error));
      // eslint-disable-next-line
      const { place_id } = data.results[0];
      setDefaultLocation({
        label: place,
        value: {
          description: place,
          place_id,
          reference: place_id,
        },
      });
    }
  };

  useEffect(() => {
    if (gid) {
      fetchDonationPageDetails(gid);
    }
  }, [gid]);

  const getInerval = (val) => {
    if (val === 'WEEK') {
      return 'Weekly';
    }
    if (val === 'MONTH') {
      return 'Monthly';
    }
    if (val === 'YEAR') {
      return 'Yearly';
    }
    if (val === 'ONETIME') {
      return 'One-time';
    }
    return '';
  };

  useEffect(() => {
    if (donationPageDetails?.gid) {
      setTitle(donationPageDetails.title);
      setContent(donationPageDetails.content);
      setLogo(donationPageDetails?.bannerImageUrl);
      setImageSupport(donationPageDetails?.bannerType);
      setFileName(donationPageDetails?.bannerImageUrl);
      setEmail(donationPageDetails.contactUsEmail);
      const phoneResult = (donationPageDetails && (donationPageDetails.contactUsPhone))
        ? findPhoneNumbersInText(donationPageDetails && donationPageDetails.contactUsPhone) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
      setPreview(
        <img
          src={`${donationPageDetails?.bannerImageUrl}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      setPageTerms(donationPageDetails.termsAndConditions);
      setColorCode(donationPageDetails.primaryColor || '#007bff');
      setAddress(donationPageDetails.venueAddress);
      getPlaceDetails(donationPageDetails.venueAddress);
      setReceiptNote(donationPageDetails.receiptNote);
      setCoordinates(donationPageDetails.coordinate);
      setYoutubeUrl(donationPageDetails.ytVideoId && `https://www.youtube.com/watch?v=${donationPageDetails.ytVideoId}`);
      setYoutubeHeading(donationPageDetails.ytVideoHeading);
      setYoutubeDescription(donationPageDetails.ytVideoDescription);
      setytVideoId(donationPageDetails.ytVideoId);
      setRedirectUrl(donationPageDetails.redirectUrl);
      setPaymentHeading(donationPageDetails.paymentHeading);
      setServiceFeeEnabled(donationPageDetails.serviceFeeEnabled);
      setSupportOfflinePayment(donationPageDetails.supportOfflinePayment);
      setShowOfflinePayment(donationPageDetails.supportOfflinePayment);
      setServiceFeeRate(donationPageDetails.serviceFeeRate
        ? ((donationPageDetails.serviceFeeRate)).toFixed(2) : null);
      setServiceFee(donationPageDetails.serviceFee
        ? ((donationPageDetails.serviceFee).toFixed(2)) : null);
      setTaxPercentage(donationPageDetails.taxPercentage
        ? (donationPageDetails.taxPercentage).toFixed(2) : null);
      const metafields = donationPageDetails.paymentPageMetaFields ? ((donationPageDetails.paymentPageMetaFields)) : '';
      setPaymentPageMetafields(JSON.parse(JSON.stringify(metafields)));
      const tipDetails = donationPageDetails.paymentPageMetaFields ? donationPageDetails.paymentPageMetaFields.filter(res => res.type === 'TIP') : '';
      if (tipDetails && tipDetails.length > 0) {
        setTipMetafield(tipDetails);
        setEnableTip(true);
        setTipGid(tipDetails[0].gid);
        setIsTipOptional((tipDetails[0].isRequired));
        setFirstOption((tipDetails[0].tipPercentages[0] / 100).toFixed(2));
        setSecondOption((tipDetails[0].tipPercentages[1] / 100).toFixed(2));
        setThirdOption((tipDetails[0].tipPercentages[2] / 100).toFixed(2));
      }
      const listItems = donationPageDetails?.donationPageCollaborators;
      const productDetails = [];
      for (let i = 0; i < listItems.length; i += 1) {
        let tempObject = {};
        tempObject.collaboratorType = listItems[i].collaboratorType;
        tempObject.name = listItems[i].name;
        tempObject.email = listItems[i].email;
        productDetails.push(tempObject);
        tempObject = {};
      }
      if (productDetails?.length > 1) {
        setShow(true);
      }
      setProductDetailsArray(productDetails);
      const intervals = donationPageDetails?.donationPageIntervals;
      const itemIntervals = [];
      for (let j = 0; j < intervals.length; j += 1) {
        let tempInterval = {};
        if (intervals[j].default) {
          setDefaultInterval(getInerval(intervals[j].interval));
        }
        tempInterval.default = intervals[j].default;
        tempInterval.interval = intervals[j].interval;
        itemIntervals.push(tempInterval);
        tempInterval = {};
      }
      setDonationPageIntervals(itemIntervals);
      setAddCustomAmount(donationPageDetails?.showCustomAmount);
      const list = donationPageDetails?.donationPageAmounts;
      const listAmount = [];
      for (let k = 0; k < intervals.length; k += 1) {
        if (list[k]?.amount) {
          let tempAmount = {};
          const amount = parseFloat(list[k].amount);
          tempAmount.amount = formatAmount((amount / 100), currencyType);
          listAmount.push(tempAmount);
          tempAmount = {};
        }
      }
      setAmountList(listAmount);
      if (donationPageDetails?.maxAmount) {
        setMaxAmount(formatAmount((donationPageDetails?.maxAmount / 100), currencyType));
      }
      if (donationPageDetails?.minAmount) {
        setMinAmount(formatAmount((donationPageDetails?.minAmount / 100), currencyType));
      }
    }
  }, [donationPageDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: 'Donation page updated successfully',
      });
      history.push(PAGES.DONATIONPAGES);
    }
    if (redirect) {
      const list = [...amountList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].amount = formatAmount((list[i].amount / 100), currencyType);
      }
      setAmountList(list);
    }
  }, [validator]);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const YouTubeGetID = (url) => {
    const url1 = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // eslint-disable-next-line no-useless-escape
    return (url1[2] !== undefined) ? url1[2].split(/[^0-9a-z_\-]/i)[0] : url1[0];
  };

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Page Details',
    },
    {
      title: 'Collaborator Details',
    },
    {
      title: 'Widget Details',
    },
    // {
    //   title: 'Customer Details',
    // },
    {
      title: 'Review',
    },
  ];

  const getTempIntervalse = (val) => {
    if (val === 'One-time') {
      return 'ONETIME';
    }
    if (val === 'Weekly') {
      return 'WEEK';
    }
    if (val === 'Monthly') {
      return 'MONTH';
    }
    if (val === 'Yearly') {
      return 'YEAR';
    }
    return '';
  };

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setTitle(valuesArray[0].title);
      setContent(valuesArray[0].content);
      setImageSupport(valuesArray[0].imageSupport);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setPageTerms(valuesArray[0].termsAndConditions ? valuesArray[0].termsAndConditions : null);
      setAddress(valuesArray[0].venueAddress ? valuesArray[0].venueAddress : null);
      setReceiptNote(valuesArray[0].receiptNote ? valuesArray[0].receiptNote : null);
      setCoordinates(valuesArray[0].coordinate ? valuesArray[0].coordinate : null);
      setYoutubeUrl(valuesArray[0].youtubeUrl ? valuesArray[0].youtubeUrl : null);
      setYoutubeHeading(valuesArray[0].ytVideoHeading ? valuesArray[0].ytVideoHeading : null);
      setRedirectUrl(valuesArray[0].redirectUrl ? valuesArray[0].redirectUrl : null);
      setPaymentHeading(valuesArray[0].paymentHeading ? valuesArray[0].paymentHeading : null);
      setLocation(valuesArray[0].location ? valuesArray[0].location : null);
      setColorCode(valuesArray[0].primaryColor);
      setYoutubeDescription(valuesArray[0].ytVideoDescription ? valuesArray[0].ytVideoDescription : null);
      setServiceFeeEnabled(valuesArray[0].serviceFeeEnabled);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      if (valuesArray[0].serviceFeeRate) {
        const feerate = Number((valuesArray[0].serviceFeeRate).replace(/[^\d]/g, ''));
        setServiceFeeRate(feerate);
      }
      if (valuesArray[0].taxPercentage) {
        const taxrate = Number((valuesArray[0].taxPercentage).replace(/[^\d]/g, ''));
        setTaxPercentage(taxrate);
      } else setTaxPercentage(null);
      if (valuesArray[0].serviceFee) {
        const fee = Number((valuesArray[0].serviceFee).replace(/[^\d]/g, ''));
        setServiceFee(fee);
      }
      if (valuesArray[0].serviceFeeEnabled === false) {
        setServiceFeeRate(null);
        setServiceFee(null);
      }
      let ytCode;
      if (valuesArray[0].youtubeUrl) {
        ytCode = YouTubeGetID(valuesArray[0].youtubeUrl);
      }
      setytVideoId(ytCode || null);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      if (donationPageIntervals?.length === 0) {
        setIntervalMsg('Please select payment interval');
        return;
      }
      if (defaultInterval === 'One-time') {
        const index = donationPageIntervals.findIndex((item) => item.interval === 'ONETIME');
        if (index > -1) {
          const list = [...donationPageIntervals];
          list[index].default = true;
          setDonationPageIntervals(list);
        }
      } else if (defaultInterval === 'Weekly') {
        const index = donationPageIntervals.findIndex((item) => item.interval === 'WEEK');
        if (index > -1) {
          const list = [...donationPageIntervals];
          list[index].default = true;
          setDonationPageIntervals(list);
        }
      } else if (defaultInterval === 'Monthly') {
        const index = donationPageIntervals.findIndex((item) => item.interval === 'MONTH');
        if (index > -1) {
          const list = [...donationPageIntervals];
          list[index].default = true;
          setDonationPageIntervals(list);
        }
      } else if (defaultInterval === 'Yearly') {
        const index = donationPageIntervals.findIndex((item) => item.interval === 'YEAR');
        if (index > -1) {
          const list = [...donationPageIntervals];
          list[index].default = true;
          setDonationPageIntervals(list);
        }
      }
      const index = donationPageIntervals.findIndex((item) => item.default === true);
      if (index < 0) {
        setIntervalMsg('Please select default interval');
        return;
      }
      const tempIndex = donationPageIntervals.findIndex((item) => item.interval === getTempIntervalse(defaultInterval));
      if (tempIndex < 0) {
        setIntervalMsg('Please select default interval');
        return;
      }
      if (tempIndex > -1) {
        for (let i = 0; i < donationPageIntervals.length; i += 1) {
          if (tempIndex !== i) {
            donationPageIntervals[i].default = false;
          }
        }
      }
      if (index > -1) {
        setCurrent(current + 1);
      }
    } else if (current === 3) {
      // event.preventDefault();
      // const formValid = simpleValidator.current.allValid();
      // if (!formValid) {
      //   simpleValidator.current.showMessages();
      //   forceUpdate(1);
      //   return;
      // }
      // setCurrent(current + 1);
      setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      if (serviceFeeEnabled && (serviceFeeRate === null)) {
        pageData.serviceFeeRate = 0;
      }
      if (serviceFeeEnabled && (serviceFee === null)) {
        pageData.serviceFee = 0;
      }
      const list = [...amountList];
      for (let i = 0; i < list.length; i += 1) {
        list[i].amount = (Number(list[i].amount.replaceAll(',', ''))) * 100;
      }
      const payload = {
        coordinate: pageData?.coordinate,
        title: pageData?.title,
        bannerImageUrl: pageData?.logo,
        bannerType: pageData?.imageSupport,
        content: pageData?.content,
        termsAndConditions: pageData?.termsAndConditions,
        contactUsEmail: pageData?.contactUsEmail,
        contactUsPhone: contactNumber,
        serviceFeeEnabled: pageData?.serviceFeeEnabled,
        serviceFeeRate: pageData?.serviceFeeRate,
        serviceFee: pageData?.serviceFee,
        taxPercentage: pageData?.taxPercentage,
        venueAddress: pageData?.venueAddress,
        status: 'ACTIVE',
        redirectUrl: pageData?.redirectUrl,
        venueName: pageData?.venueAddress,
        ytVideoId: pageData?.ytVideoId,
        ytVideoHeading: pageData?.ytVideoDescription,
        ytVideoDescription: pageData?.ytVideoHeading,
        primaryColor: pageData?.primaryColor,
        showCustomAmount: addCustomAmount,
        minAmount: minAmount ? ((Number(minAmount.replaceAll(',', ''))) * 100) : null,
        maxAmount: maxAmount ? ((Number(maxAmount.replaceAll(',', ''))) * 100) : null,
        actionMessage: 'Action message',
        thankYouMessage: 'Thanks for the donation',
        donationPageCollaborators: productDetailsArray,
        donationPageIntervals,
        donationPageAmounts: list,
        currencyCode: selectedAccount?.currency?.name,
        supportOfflinePayment,
      };
      updateDonationPage({ payload, gid });
      // addDonationPage(payload);
      setRedirect(true);
    }
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setCurrent(current - 1);
  };

  const onArrowUp = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex - 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  const onArrowDown = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex + 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };


  const handleInputChange = (e, index) => {
    const { value } = e.currentTarget;
    const list = [...amountList];
    if (validationRegex.amountRegex.test(value) || value === '') {
      list[index].amount = value;
    } else if (!validationRegex.amountRegex.test(value)) {
      list[index] = null;
    }
    setAmountList(list);
  };

  const formatInputChange = (e, index) => {
    const { value } = e.currentTarget;
    const list = [...amountList];
    const amount = parseFloat(value);
    if (Number(amount)) {
      list[index].amount = parseInt(amount, 10).toFixed(2);
    }
    setAmountList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...amountList];
    list.splice(index, 1);
    setAmountList(list);
    simpleValidator.current.purgeFields();
  };

  const handleAddClick = () => {
    setAmountList([...amountList, { amount: null }]);
  };


  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(customerDetailsArray);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setCustomerDetailsArray(reorderedItems);
  };

  return (
    <>
      {
        pageData?.title && donationPageDetails.gid && donationPageDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Edit Donation Page
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.DONATIONPAGES)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: '800px' }}>
                <div>
                  {current === 0 && (
                  <PageDetails
                    ref={refArray[0]}
                    selectedAccount={selectedAccount}
                    pageData={pageData}
                    isIndia={isIndia}
                  />
                  )}
                  {current === 1 && (
                  <div>
                    {
                  productDetailsArray.map((item, i) => (
                    <div>
                      <Row>
                        <Col span={16} push={4}>
                          <label htmlFor=""><SpH5>Collaborator type</SpH5></label>
                          <div>
                            <Select
                              style={{ width: '100%' }}
                              value={item.collaboratorType}
                              onChange={(e) => {
                                const list = [...productDetailsArray];
                                list[i].collaboratorType = e;
                                setProductDetailsArray(list);
                              }}
                            >
                              <Option key={1} value="PROVIDER">PROVIDER</Option>
                              <Option key={2} value="ORGANIZER">ORGANIZER</Option>
                              <Option key={3} value="CHARITY">CHARITY</Option>
                            </Select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <label htmlFor=""><SpH5>Name</SpH5></label>
                          <Input
                            placeholder="Name"
                            value={item.name}
                            onChange={(e) => {
                              const list = [...productDetailsArray];
                              list[i].name = e.currentTarget.value;
                              setProductDetailsArray(list);
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('name', productDetailsArray[i].name, 'required',
                              { messages: { required: 'The name field is required' } })}
                          </SpError>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <label htmlFor=""><SpH5>Email</SpH5></label>
                          <Input
                            placeholder="Email"
                            value={item.email}
                            onChange={(e) => {
                              const list = [...productDetailsArray];
                              list[i].email = e.currentTarget.value;
                              setProductDetailsArray(list);
                            }}
                          />
                          <SpError>
                            {simpleValidator.current.message('email', productDetailsArray[i].email, 'required|email',
                              { messages: { required: 'The email field is required' } })}
                          </SpError>
                        </Col>
                      </Row>
                      {show ? (
                        <Row className="mt-4 mb-4">
                          <Col span={16} push={4}>
                            <SpButton
                              type="secondary"
                              style={{ width: '50%' }}
                              onClick={() => removeProductDetails(i)}
                            >
                              {'\u2212'}&nbsp;Remove Input Field
                            </SpButton>
                          </Col>
                        </Row>

                      ) : ''}
                    </div>
                  ))
                }
                    <Row className="mt-4">
                      <Col span={16} push={4}>
                        <SpButton
                          type="secondary"
                          style={{ width: '50%' }}
                          onClick={addProductDetails}
                        >
                          {'\u002B'}&nbsp;Add Another Input Field
                        </SpButton>
                      </Col>
                    </Row>
                  </div>
                  )}
                  {
                    current === 2 && (
                      <div>
                        <Row>
                          <Col span={16} push={4}>
                            <label htmlFor=""><SpH5>Payment Intervals</SpH5></label>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={24}>
                                <div className="d-flex" style={{ marginTop: '15px' }}>
                                  <label htmlFor="" className="mr-3">
                                    <Checkbox
                                      className="mr-2"
                                      checked={donationPageIntervals.findIndex((item) => item.interval === 'ONETIME') > -1}
                                      onChange={({ target: { checked } }) => {
                                        if (checked) {
                                          const list = [...donationPageIntervals];
                                          list.push({
                                            interval: 'ONETIME',
                                            default: false,
                                          });
                                          setDonationPageIntervals(list);
                                          setIntervalMsg('');
                                        } else if (!checked) {
                                          const list = [...donationPageIntervals];
                                          const index = list.findIndex((item) => item.interval === 'ONETIME');
                                          if (index > -1) {
                                            list.splice(index, 1);
                                            setDonationPageIntervals(list);
                                          }
                                        }
                                      }}
                                    />
                                    <SpText fontSize="14px">One-time</SpText>
                                  </label>
                                  <label htmlFor="" className="mr-3">
                                    <Checkbox
                                      className="mr-2"
                                      checked={donationPageIntervals.findIndex((item) => item.interval === 'WEEK') > -1}
                                      onChange={({ target: { checked } }) => {
                                        if (checked) {
                                          const list = [...donationPageIntervals];
                                          list.push({
                                            interval: 'WEEK',
                                            default: false,
                                          });
                                          setDonationPageIntervals(list);
                                          setIntervalMsg('');
                                        } else if (!checked) {
                                          const list = [...donationPageIntervals];
                                          const index = list.findIndex((item) => item.interval === 'WEEK');
                                          if (index > -1) {
                                            list.splice(index, 1);
                                            setDonationPageIntervals(list);
                                          }
                                        }
                                      }}
                                    />
                                    <SpText fontSize="14px">Weekly</SpText>
                                  </label>
                                  <label htmlFor="" className="mr-3">
                                    <Checkbox
                                      className="mr-2"
                                      checked={donationPageIntervals.findIndex((item) => item.interval === 'MONTH') > -1}
                                      onChange={({ target: { checked } }) => {
                                        if (checked) {
                                          const list = [...donationPageIntervals];
                                          list.push({
                                            interval: 'MONTH',
                                            default: false,
                                          });
                                          setDonationPageIntervals(list);
                                          setIntervalMsg('');
                                        } else if (!checked) {
                                          const list = [...donationPageIntervals];
                                          const index = list.findIndex((item) => item.interval === 'MONTH');
                                          if (index > -1) {
                                            list.splice(index, 1);
                                            setDonationPageIntervals(list);
                                          }
                                        }
                                      }}
                                    />
                                    <SpText fontSize="14px">Monthly</SpText>
                                  </label>
                                  <label htmlFor="" className="mr-3">
                                    <Checkbox
                                      className="mr-2"
                                      checked={donationPageIntervals.findIndex((item) => item.interval === 'YEAR') > -1}
                                      onChange={({ target: { checked } }) => {
                                        if (checked) {
                                          const list = [...donationPageIntervals];
                                          list.push({
                                            interval: 'YEAR',
                                            default: false,
                                          });
                                          setDonationPageIntervals(list);
                                          setIntervalMsg('');
                                        } else if (!checked) {
                                          const list = [...donationPageIntervals];
                                          const index = list.findIndex((item) => item.interval === 'YEAR');
                                          if (index > -1) {
                                            list.splice(index, 1);
                                            setDonationPageIntervals(list);
                                          }
                                        }
                                      }}
                                    />
                                    <SpText fontSize="14px">Yearly</SpText>
                                  </label>
                                </div>
                                <SpError>
                                  {intervalMsg}
                                </SpError>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col span={16} push={4}>
                            <label htmlFor=""><SpH5>Set as Default</SpH5></label>
                            <div className="mb-1">
                              <Select
                                style={{ width: '100%' }}
                                value={defaultInterval}
                                onChange={(e) => { setDefaultInterval(e); setIntervalMsg(''); }}
                              >
                                <Option key={1} value="One-time">One-time</Option>
                                <Option key={2} value="Weekly">Weekly</Option>
                                <Option key={3} value="Monthly">Monthly</Option>
                                <Option key={4} value="Yearly">Yearly</Option>
                              </Select>
                            </div>
                            <SpText fontSize="12px" color="rgba(0, 0, 0, 0.5)">The default interval will be pre-selected</SpText>
                          </Col>
                        </Row>
                        <div>
                          {
                        amountList.map((item, i) => (
                          <Row className="mt-3">
                            <Col span={16} push={4}>
                              <label htmlFor=""><SpH5>Amount</SpH5></label>
                              <Row type="flex" justify="start" align="middle">
                                <Col span={18} className="mr-2">
                                  <Input
                                    value={item?.amount}
                                    placeholder="0.00"
                                    onKeyPress={e => {
                                      const keyCode = e.charCode || e.which;
                                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={e => handleInputChange(e, i)}
                                    onBlur={e => formatInputChange(e, i)}
                                  />
                                </Col>
                                <Col span={4}>
                                  {i !== 0 ? (
                                    <Icon
                                      type="minus-circle"
                                      className="ml-2"
                                      style={{ cursor: 'pointer', color: '#ff4d50' }}
                                      onClick={() => handleRemoveClick(i)}
                                    />
                                  ) : (
                                    <span className="ml-2">&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                </Col>
                              </Row>
                              <SpError>
                                {simpleValidator.current.message('amount', amountList[i].amount, 'required',
                                  { messages: { required: 'The amount field is required.' } })}
                              </SpError>
                            </Col>
                          </Row>
                        ))
                      }
                        </div>
                        <Row>
                          <Col span={16} push={4}>
                            <div className="ml-2" style={{ cursor: 'pointer', marginTop: '29px' }}>
                              <SpText
                                fontWeight="600"
                                color="#3DA8FE"
                                onClick={handleAddClick}
                                fontSize="14px"
                              >
                                {'\u002B'}&nbsp; Add Amount
                              </SpText>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={16} push={4}>
                            <div className="ml-2" style={{ cursor: 'pointer', marginTop: '29px' }}>
                              <label htmlFor="" className="mr-3">
                                <Checkbox
                                  className="mr-2"
                                  checked={addCustomAmount}
                                  onChange={({ target: { checked } }) => {
                                    setAddCustomAmount(checked);
                                  }}
                                />
                                <SpText fontSize="14px">Add Custom Amount Field</SpText>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        {
                          addCustomAmount && (
                            <div>
                              <Row className="mt-3">
                                <Col span={16} push={4}>
                                  <label htmlFor=""><SpH5>Min Amount</SpH5></label>
                                  <Row type="flex" justify="start" align="middle">
                                    <Col span={18} className="mr-2">
                                      <Input
                                        placeholder="0.00"
                                        value={minAmount}
                                        onChange={(e) => setMinAmount(e.currentTarget.value)}
                                        onKeyPress={e => {
                                          const keyCode = e.charCode || e.which;
                                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onBlur={e => {
                                          if (e.currentTarget.value) {
                                            const value = formatAmount(e.currentTarget.value, currencyType);
                                            setMinAmount(value);
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <SpError>
                                    {simpleValidator.current.message('min amount', minAmount, 'required|amount',
                                      { messages: { required: 'The min amount field is required.' } })}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col span={16} push={4}>
                                  <label htmlFor=""><SpH5>Max Amount</SpH5></label>
                                  <Row type="flex" justify="start" align="middle">
                                    <Col span={18} className="mr-2">
                                      <Input
                                        placeholder="0.00"
                                        value={maxAmount}
                                        onChange={(e) => setMaxAmount(e.currentTarget.value)}
                                        onKeyPress={e => {
                                          const keyCode = e.charCode || e.which;
                                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onBlur={e => {
                                          if (e.currentTarget.value) {
                                            const value = formatAmount(e.currentTarget.value, currencyType);
                                            setMaxAmount(value);
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <SpError>
                                    {simpleValidator.current.message('max amount', maxAmount, 'required|amount',
                                      { messages: { required: 'The max amount field is required.' } })}
                                  </SpError>
                                </Col>
                              </Row>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {/* {current === 3 && (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="item-list">
                    {(provided) => (

                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {customerDetailsArray.map((item, j) => (
                          <Draggable key={`${item.type}${j}`} draggableId={`${item.type}${j}`} index={j}>
                            {(provided) => (
                              <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div
                                  style={{
                                    backgroundColor: '#fff',
                                    padding: '10px',
                                    margin: '25px 0',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <p>
                                    <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                      <Col className="d-flex align-items-center">
                                        <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                      </Col>
                                      <Col className="d-flex align-items-center">
                                        {
                                                j === 0 ? (
                                                  <div className="mr-2">
                                                    <img src={arrowUpDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowUp(j)}
                                                  >
                                                    <img src={arrowUp} alt="" />
                                                  </div>
                                                )
                                              }
                                        {
                                                j === (customerDetailsArray.length - 1) ? (
                                                  <div
                                                    className="mr-2"
                                                  >
                                                    <img src={arrowDownDisabled} alt="" />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="mr-2"
                                                    onClick={() => onArrowDown(j)}
                                                  >
                                                    <img src={arrowDown} alt="" />
                                                  </div>
                                                )
                                              }

                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={16} push={4}>
                                        <label htmlFor=""><SpH5>Input Field Types</SpH5></label>
                                        <div>
                                          <Select
                                            style={{ width: '50%' }}
                                            placeholder="Select field type"
                                            value={item.type || undefined}
                                            disabled={item.disableRequired ? true : ''}
                                            onChange={(e) => {
                                              const list = [...customerDetailsArray];
                                              list[j].type = e;
                                              setCustomerDetailsArray(list);
                                            }}
                                          >
                                            <Option key={1} value="INPUT_EMAIL">Email</Option>
                                            <Option key={2} value="INPUT_PHONE">Phone</Option>
                                            <Option key={3} value="INPUT_ALPHA">Name</Option>
                                            <Option key={4} value="INPUT_SINGLE_LINE_TEXT">Address</Option>
                                          </Select>
                                        </div>
                                        <SpError>
                                          {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                            { messages: { required: 'Please select field type' } })}
                                        </SpError>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                        <Input
                                          placeholder={getPlaceholder(item.type || '')}
                                          value={item.label}
                                          maxlength="100"
                                          onChange={(e) => {
                                            const list = [...customerDetailsArray];
                                            list[j].label = e.currentTarget.value;
                                            setCustomerDetailsArray(list);
                                          }}
                                        />
                                        <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                        <SpError>
                                          {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                            { messages: { required: 'The label field is required' } })}
                                        </SpError>
                                      </Col>
                                    </Row>


                                    {
                                            (!item.disableRequired) ? (
                                              <Row className="mt-4 mb-4">
                                                <Col span={16} push={4}>
                                                  <SpButton
                                                    type="secondary"
                                                    style={{ width: '50%' }}
                                                    onClick={() => removeCustomerDetails(j)}
                                                  >
                                                    {'\u2212'}&nbsp;Remove Input Field
                                                  </SpButton>
                                                </Col>
                                              </Row>
                                            ) : ''
                                          }
                                  </p>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>


                <Row className="mt-4">
                  <Col span={16} push={8}>
                    <SpButton
                      type="secondary"
                      style={{ width: '50%' }}
                      onClick={addCustomerDetails}
                    >
                      {'\u002B'}&nbsp;Add Another Input Fields
                    </SpButton>
                  </Col>
                </Row>
              </div>
              )} */}

                  {
                    current === 3 && (
                      <ReviewPaymentPage
                        pageData={pageData}
                        addParent={[]}
                        submitting={submitting}
                        productDetailsArray={productDetailsArray}
                        customerDetailsArray={customerDetailsArray}
                        account={account}
                        contactEmail={contactEmail}
                        enableTip={enableTip}
                        isTipOptional={isTipOptional}
                        firstOption={firstOption}
                        secondOption={secondOption}
                        thirdOption={thirdOption}
                        addCustomAmount={addCustomAmount}
                        minAmount={minAmount}
                        maxAmount={maxAmount}
                        donationPageIntervals={donationPageIntervals}
                        currencyType={currencyType}
                        amountList={amountList}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                  <SpButton type="secondary" className="mr-4" onClick={prev}>
                    Back
                  </SpButton>
                  )}
                  {current < steps.length - 1 && (
                  <SpButton type="primary" onClick={next}>
                    Next
                  </SpButton>
                  )}
                  {current === steps.length - 1 && (
                  <SpButton type="primary" onClick={next}>
                    Create
                  </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  donationPageDetails: state.donationpageDetails,
  donationPageDetailsStatus: state.donationpageDetails.donationPageDetailsStatus,
  accountSlug: state.accountSlug.content,
});

const mapDispatchToProps = (dispatch) => ({
  addDonationPage: param => dispatch(donationPagesActions.addDonationPage({
    payload: param,
  })),
  updateDonationPage: param => dispatch(donationPagesActions.updateDonationPage({
    payload: param,
  })),
  fetchDonationPageDetails: param => dispatch(donationPageDetailsActions.fetchDonationPageDetails({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDonationPage);
