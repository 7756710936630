// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentPageBackground from 'utils/getPaymentPageBackground';
import getPaymentPageStatus from 'utils/getPaymentPageStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import getOrdersBackground from 'utils//getOrdersBackground';
import getOrderStatus from 'utils/getOrderStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Select,
  Tooltip,
  Menu,
  Icon,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  paymentPagesDetailsActions,
  accountSlugActions,
  notificationActions,
  paymentDetailsAction,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  PAYMENTPAGE_STATUSES,
  ACQUIRER_TYPES,
  COUNTRIES,
} from 'appconstants';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import RBAC from 'components/rbac';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import Clipboard from 'components/NavIcons/copyclipboard';
import CONSTANTS from 'store/constants';
import ResendMail from 'imgs/ResendMail.svg';

import NotifyPaymentPage from './components/NotifyPaymentPage';
import DeletePaymentPage from './components/deletePaymentPage';
import SoldOutPaymentPage from './components/soldOutPaymentPage';
import CheqPaymentPage from './components/cheqPaymentPage';
import DeleteOfflinePayment from './components/deleteOfflinePaymentPage';

const { Option } = Select;

type Props = {
  fetchPaymentPagesDetails: Function,
  fetchPayments: Function,
  fetchAccountSlug: Function,
  fetchPaymentPageSlug: Function,
  setNotification: Function,
  paymentPagesDetails: Object,
  accountSlug: Array<Object>,
  paymentSlug: Array,
  notifyPaymentPage: Function,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  totalElements: number,
  paymentPagesDetailsStatus: string,
  validator: boolean,
  DeactivePaymentPage: Function,
  soldOutPaymentPage: Function,
  resumeSalePaymentPage: Function,
  fetchPaymentsOrders: Function,
  orders: Array<Object>,
  updateCashPayment: Function,
  role: Object,
  fetchOfflinePayments: Function,
  fetchPaymentReports: Function,
  fetchPaymentPagesEntries: Function,
  fetchPaymentPagesEntries: Function,
  offlinePayments: Array<Object>,
  deleteOfflinePayments: Function,
  paymentReports: Array<Object>,
  paymentPagesEntries: Array<Object>,
  notifyPaymentPageDetails: Function,
  totalReportsElements: Number,
  totalofflineElements: Number,
  totalOrderElements: Number,
}

const PaymentPagesDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPaymentPagesDetails,
    fetchAccountSlug,
    fetchPaymentPageSlug,
    fetchPayments,
    setNotification,
    paymentPagesDetails,
    notifyPaymentPage,
    linkAccountSlug,
    unLinkAccountSlug,
    loading,
    submitting,
    accountSlug,
    paymentSlug,
    history,
    payments,
    totalElements,
    selectedAccount,
    paymentPagesDetailsStatus,
    validator,
    DeactivePaymentPage,
    soldOutPaymentPage,
    resumeSalePaymentPage,
    fetchPaymentsOrders,
    updateCashPayment,
    orders,
    role,
    fetchOfflinePayments,
    fetchPaymentReports,
    fetchPaymentPagesEntries,
    offlinePayments,
    deleteOfflinePayments,
    paymentReports,
    paymentPagesEntries,
    notifyPaymentPageDetails,
    totalReportsElements,
    totalofflineElements,
    totalOrderElements,
  } = props;
  const { gid } = props.match.params;
  const path = props.match.url;
  const env = process.env.REACT_APP_ENV;
  const [pagination, setPagination] = useState({});
  const [notifyPaymentPageModal, setNotifyPaymentPageModal] = useState(false);
  const [deletePaymentPageModal, setDeletePaymentPageModal] = useState(false);
  const [soldOutPaymentPageModal, setSoldOutPaymentPageModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();
  const [enableUnlink, setEnableUnlink] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);
  const { prefix } = selectedAccount && selectedAccount.currency;
  const { id } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const [current, setCurrent] = useState('loadBalance');
  const [isSearch, setIsSearch] = useState(false);
  const [cheqModal, setCheqModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const superUser = ((role && role.name) === 'superuser');
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const [selectedOffline, setSelectedOffline] = useState({});
  const [deleteOfflineModal, setDeleteOfflineModal] = useState(false);

  const getData = (query) => {
    let sortBy;
    const parsed = queryString.parse(query);
    if (current === 'loadBalance') {
      sortBy = 'paymentDate';
    } else {
      sortBy = parsed.sortBy || 'createdAt';
    }
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const params = `&sortBy=${parsed.sortBy || 'createdAt'}&direction=${direction}`;
    const size = 1000;
    if (!isSearch) {
      fetchPaymentPagesDetails(gid);
      fetchPaymentPageSlug(gid);
    }
    if (current === 'loadBalance') {
      fetchPayments({ currentPage, sortParams, gid });
    } else if (current === 'withdrawBalance') {
      fetchPaymentsOrders({ currentPage, sortParams, gid });
    } else if (current === 'offlinePayments') {
      fetchOfflinePayments({ currentPage, sortParams, gid });
    } else if (current === 'downloadingReport') {
      fetchPaymentReports({ currentPage, sortParams, gid });
    } else if (current === 'paymentPageEntries') {
      fetchPaymentPagesEntries({ currentPage, sortParams, gid });
    }
    fetchAccountSlug({ currentPage, params, size });
  };

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTPAGES);
    } else if (!validator) {
      setNotifyPaymentPageModal(false);
      setCheqModal(false);
      setDeleteOfflineModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!submitting) {
      setCheqModal(false);
      setDeleteOfflineModal(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (paymentSlug && paymentSlug.length > 0) {
      setEnableUnlink(true);
      setSlugLink(paymentSlug[0].slugName);
      setSlugGid(paymentSlug[0].gid);
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [paymentSlug]);

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGES.SUCCESS,
    });
  };

  const copyReceiptCode = (record) => {
    let selectedPath;
    if (env === 'Staging') {
      selectedPath = 'https://staging.swirepay.com';
    }
    if (env === 'Production') {
      selectedPath = 'https://dashboard.swirepay.com';
    }
    const el = `${selectedPath}${PAGES.PAGE_RECIPT}/${record}`;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGES.SUCCESS,
    });
  };

  const downloadReports = async (record) => {
    const idx = record && record.gid;
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.GET_REPORTS}/${idx}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const downloadReport = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance
        .post(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/generate-report`, { type: 'PAYMENT_PAGE_ONLINE' });
      console.log(entity, 'entity');
      setCurrent('downloadingReport');
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: ((e.response && e.response.data && e.response.data.messageCode) === '5002')
          ? MESSAGES.REPORT.NODATA : MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const downloadOfflineReport = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance
        .post(`${API_END_POINTS.PAYMENTPAGES_API}/${gid}/generate-report`, { type: 'PAYMENT_PAGE_OFFLINE' });
      console.log(entity, 'entity');
      setCurrent('downloadingReport');
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: ((e.response && e.response.data && e.response.data.messageCode) === '5002')
          ? MESSAGES.REPORT.NODATA : MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  const updatePaymentPage = () => {
    const paymentPageGid = gid;
    history.push(`${PAGES.UPDATE_PAYMENT_PAGE}/${paymentPageGid}`);
  };

  const clonePaymentPage = () => {
    const paymentPageGid = gid;
    history.push(`${PAGES.ADDPAYMENTPAGE}/${paymentPageGid}`);
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };
  const selectedRecordPaymentPageEntries = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `/payment-page/${searchParams}`,
    });
  };

  const selectDetails = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `/payment-orders/${searchParams}`,
      state: location.search,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount, current]);

  const getTotalElements = () => {
    if (current === 'loadBalance') {
      return totalElements;
    } if (current === 'withdrawBalance') {
      return totalOrderElements;
    } if (current === 'offlinePayments') {
      return totalofflineElements;
    } if (current === 'downloadingReport') {
      return totalReportsElements;
    }
    return null;
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: getTotalElements(),
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments, offlinePayments, paymentReports, orders]);

  const handleTableChange = (page, sorter, filters) => {
    setIsSearch(true);
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.PAYMENTPAGES}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeletePaymentPageModal = () => {
    setDeletePaymentPageModal(true);
  };

  const showSoldOutPaymentPageModal = () => {
    setSoldOutPaymentPageModal(true);
  };

  const soldoutSelectedPaymentPage = async (data: string) => {
    const {
      status,
    } = data;
    if (status === 'SOLD_OUT') {
      soldOutPaymentPage(gid);
    } else {
      resumeSalePaymentPage(gid);
    }
    setRedirect(true);
  };

  const deleteSelectedPaymentPage = async (paymentPageGid: string) => {
    DeactivePaymentPage(paymentPageGid);
    setRedirect(true);
  };

  const OfflinePayment = (paymentDatils: Object) => {
    const params = paymentDatils;
    const paymentLinkGid = paymentPagesDetails.paymentPageLinks[0].gid;
    updateCashPayment({
      gid: paymentLinkGid,
      params,
    });
    setRedirect(true);
  };

  const notifyPayment = (customer: Object) => {
    const {
      emailId,
      phoneNumber,
      countryCode,
      subject,
      message,
      type,
    } = customer;
    notifyPaymentPage({
      phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
      emailId,
      subject,
      message,
      type,
    });
    setRedirect(true);
  };

  const linkSlug = () => {
    const params = {
      type: 'PAYMENT_PAGE',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };
  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  const showDeleteOfflineModal = (value: Object) => {
    setSelectedOffline(value);
    setDeleteOfflineModal(true);
  };

  const deleteSelectedOfflinePayment = async (offlineGid: string) => {
    deleteOfflinePayments({ offlineGid, gid });
  };

  const selectedNotify = (paymentDetails: object) => {
    const isPaymentPage = paymentDetails && paymentDetails.paymentPage;
    notifyPaymentPageDetails(isPaymentPage && isPaymentPage.gid);
  };

  const offlineOrderColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record && record.paymentPage && record.paymentPage.name;
        return (
          <span>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const email = record && record.paymentPage && record.paymentPage.email;
        return (
          <span>
            {email || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const phone = record && record.paymentPage && record.paymentPage.phone;
        return (
          <span>
            {phone || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'offlinePaymentType',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const offlinePaymentType = record && record.paymentPage && record.paymentPage.offlinePaymentType;
        let type;
        if (offlinePaymentType === 'CHECK') {
          type = isIndia ? 'Cheque' : 'Check';
        } else if (offlinePaymentType === 'CASH') {
          type = 'Cash';
        } else if (offlinePaymentType === 'CUSTOM') {
          type = 'Custom';
        }
        return (
          <span>
            {type || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const amount = record && record.paymentPage && record.paymentPage.amount;
        const total = (amount / 100).toFixed(2);
        return (
          <span>
            {`${prefix}${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        const createdAt = record && record.paymentPage && record.paymentPage.createdAt;
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '10%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showDeleteOfflineModal(record); }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const orderColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { name } = record.paymentPage;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {name}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getOrdersBackground(status) }}>
              {getOrderStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { email } = record.paymentPage;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {email || <>&#8211;</>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      width: '20%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const { phone } = record.paymentPage;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {phone || <>&#8211;</>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net) => {
        const total = (net / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => {
        const customerName = record && record.customer && record.customer.name;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {customerName || <>&#8211;</>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'receiptEmail',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (receiptEmail) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptEmail || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'receiptSms',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (receiptSms) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptSms || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '12%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '14%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        if (record && record.status === 'REQUIRE_PAYMENT_METHOD') {
          return (
            <div
              onClick={(e) => { e.stopPropagation(); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                disabled
                style={{
                  height: '50px',
                  marginTop: '5px',
                  cursor: 'not-allowed',
                }}
              />
            </div>
          );
        }
        return (
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (net) => {
        const total = (net / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => {
        const customerName = record && record.customer && record.customer.name;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {customerName || <>&#8211;</>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'receiptEmail',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (receiptEmail) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptEmail || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PHONE',
      dataIndex: 'receiptSms',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (receiptSms) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {receiptSms || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '15%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '14%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <>
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const reportColumns = [
    {
      title: 'REPORT TYPE',
      dataIndex: 'type',
      width: '25%',
      align: 'left',
    },
    {
      title: 'REQUESTED ON',
      dataIndex: 'generatedAt',
      width: '25%',
      align: 'left',
      render: (generatedAt) => {
        if (generatedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(generatedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DURATION',
      dataIndex: 'date',
      width: '25%',
      align: 'left',
      render: (date, record) => {
        if (record.startDate && record.endDate && selectedAccount && selectedAccount.timezone) {
          const fromDate = moment
            .utc(record.startDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          const toDate = moment
            .utc(record.endDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{fromDate} To {toDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DOWNLOAD',
      dataIndex: 'actions',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.status === 'GENERATED' ? (
              <Icon
                type="download"
                style={{ fontSize: '24px', marginRight: '8px', cursor: 'pointer' }}
                onClick={() => downloadReports(record)}
              />
            ) : (
              <span> {record.status ? record.status.replace('_', ' ') : '-'}</span>
            )
          }
        </>
      ),
    },
  ];

  const paymentPageEntriesColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '25%',
      align: 'left',
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '25%',
      align: 'left',
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      width: '25%',
      align: 'left',
    },
    {
      title: 'SUBSCRIPTION',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = (amount / 100).toFixed(2);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentPagesDetails.gid && paymentPagesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontSize="20px" fontWeight="600">
                    {paymentPagesDetails.title
                      ? `${paymentPagesDetails.title}`
                      : <>&#8211;</>}
                  </SpText>
                  {paymentPagesDetails.status
                    ? (
                      <SpStatusTag style={{ backgroundColor: getPaymentPageBackground(paymentPagesDetails.status) }}>
                        {getPaymentPageStatus(paymentPagesDetails.status)}
                      </SpStatusTag>
                    )
                    : <>&#8211;</>}
                </Col>
              </Row>
              <Row type="flex" justify="end" className="my-3 mb-3">
                <Col className="d-flex justify-content-end">
                  {
                    paymentPagesDetails.supportOfflinePayment && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="mr-2"
                          type="secondary"
                          shape="round"
                          onClick={() => setCheqModal(true)}
                          ghost
                        >
                          Offline Payment
                        </SpButton>
                      </RBAC>
                    )
                  }
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => clonePaymentPage()}
                      ghost
                    >
                      Clone Payment Pages
                    </SpButton>
                  </RBAC>
                  {
                    (paymentPagesDetails.status === PAYMENTPAGE_STATUSES.ACTIVATED) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={() => updatePaymentPage()}
                          ghost
                        >
                          Edit
                        </SpButton>
                      </RBAC>
                    )
                  }

                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <SpButton
                      className="ml-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setNotifyPaymentPageModal(true)}
                      ghost
                    >
                      Notify
                    </SpButton>
                  </RBAC>
                  {
                    (paymentPagesDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED)
                    && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="ml-2"
                          type="secondary"
                          shape="round"
                          onClick={showSoldOutPaymentPageModal}
                          ghost
                        >
                          {
                            paymentPagesDetails.status === PAYMENTPAGE_STATUSES.ACTIVATED ? 'Sold Out' : 'Resume Sales'
                          }
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    (paymentPagesDetails.status !== PAYMENTPAGE_STATUSES.ACTIVECANCEL)
                    && (paymentPagesDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          className="ml-2"
                          type="danger"
                          shape="round"
                          onClick={showDeletePaymentPageModal}
                        >
                          Deactivate
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Created On</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {paymentPagesDetails.createdAt
                        ? (
                          moment
                            .utc(paymentPagesDetails.createdAt)
                            .tz(selectedAccount.timezone)
                            .format('MMM DD, YYYY')
                        )
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Link</span>
                  </Col>
                  {
                    (paymentPagesDetails.paymentPageLinks) && (paymentPagesDetails.paymentPageLinks.length > 0) ? (
                      <Col span={12}>
                        {paymentPagesDetails.paymentPageLinks[0].link && paymentPagesDetails.status !== PAYMENTPAGE_STATUSES.ACTIVEDEACTIVATED
                          && paymentPagesDetails.status !== PAYMENTPAGE_STATUSES.DELETED
                          ? (
                            <SpH5>{paymentPagesDetails.paymentPageLinks[0].link}
                              <SpButton
                                type="secondary"
                                className={paymentPagesDetails.paymentPageLinks[0].link ? 'ml-2' : ''}
                                borderRadius="50px"
                                onClick={() => { copyCode(paymentPagesDetails.paymentPageLinks[0].link); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : <>&#8211;</>}
                      </Col>
                    ) : (<Col><>&#8211;</></Col>)
                  }
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Total Online Payments</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{paymentPagesDetails.totalSales >= 0
                      ? `${paymentPagesDetails.totalSales}`
                      : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={24}>
                    <Col span={4}>
                      <span>Total Online Revenue</span>
                    </Col>
                    <Col span={10}>
                      <SpH5>
                        {paymentPagesDetails.totalRevenue < 0
                          ? `(${prefix} 
                      ${formatNumber((paymentPagesDetails.totalRevenue / 100).toFixed(2).replace(/-/g, ''), currencyType)})`
                          : `${prefix} 
                      ${formatNumber((paymentPagesDetails.totalRevenue / 100).toFixed(2), currencyType)}`}
                      </SpH5>
                    </Col>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{paymentPagesDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                {
                  (accountSlug.length > 0) && (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={6}>
                          <SpText className="mr-3" fontSize="14px" fontWeight="600">
                            Link Account Slug
                          </SpText>
                          <Select
                            className="mt-2 w-100"
                            showSearch
                            disabled={enableUnlink}
                            value={slugLink || 'Select slug'}
                            onChange={(value) => {
                              setSlugGid(value[0]);
                              setSlugLink(value[1]);
                            }}
                          >
                            {
                              accountSlug.map(slug => (
                                slug.status === 'UNLINKED' && (
                                  <Option
                                    key={1}
                                    value={[slug.gid, slug.slugName]}
                                  >
                                    {slug.slugName}
                                  </Option>
                                )
                              ))
                            }
                          </Select>
                        </Col>
                      </Row>
                      <Row className="mt-4" type="flex" justify="start" align="middle">
                        <Col span={3}>
                          {
                            enableUnlink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={unlinkSlug}
                              >
                                Unlink
                              </SpButton>
                            ) : slugLink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={linkSlug}
                              >
                                Link
                              </SpButton>
                            ) : (
                              <SpButton
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Link
                              </SpButton>
                            )
                          }

                        </Col>
                      </Row>
                    </>
                  )
                }
              </SpForm>
              {/* NOTIFY PAYMENTPAGE MODAL */}
              {notifyPaymentPageModal && (
                <NotifyPaymentPage
                  visible={notifyPaymentPageModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  paymentPagesDetails={paymentPagesDetails}
                  close={() => setNotifyPaymentPageModal(false)}
                  submit={notifyPayment}
                />
              )}
              {/* DELETE PAGE MODAL */}
              {deletePaymentPageModal && (
                <DeletePaymentPage
                  visible={deletePaymentPageModal}
                  paymentPagesDetails={paymentPagesDetails}
                  submitting={submitting}
                  close={() => setDeletePaymentPageModal(false)}
                  submit={deleteSelectedPaymentPage}
                />
              )}
              {/* SOLD OUT PAGE MODAL */}
              {soldOutPaymentPageModal && (
                <SoldOutPaymentPage
                  visible={soldOutPaymentPageModal}
                  paymentPagesDetails={paymentPagesDetails}
                  status={paymentPagesDetails && paymentPagesDetails.status}
                  submitting={submitting}
                  close={() => setSoldOutPaymentPageModal(false)}
                  submit={soldoutSelectedPaymentPage}
                />
              )}
              {/* CHEQ PAYMENTPAGE MODAL */}
              {cheqModal && (
                <CheqPaymentPage
                  visible={cheqModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  paymentPagesDetails={paymentPagesDetails}
                  offlineNameDetails={paymentPagesDetails}
                  close={() => setCheqModal(false)}
                  submit={OfflinePayment}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="loadBalance">
                  <SpTab
                    to={`${path}?status.IN=PAYMENTS`}
                    selected={current === 'loadBalance'}
                  >
                    PAYMENTS
                  </SpTab>
                </Menu.Item>
                {
                  // eslint-disable-next-line max-len
                  ((selectedAccount && selectedAccount.paymentPageOrderFcmType === 'FIREBASE_FCM') || (selectedAccount && selectedAccount.paymentPageOrderFcmType === 'FIREBASE_DATA')) && (
                    <Menu.Item key="withdrawBalance">
                      <SpTab
                        to={`${path}?status.IN=PAYMENTSORDERS`}
                        selected={current === 'withdrawBalance'}
                      >
                        PAYMENTS ORDERS
                      </SpTab>
                    </Menu.Item>
                  )
                }
                {
                  (paymentPagesDetails && paymentPagesDetails.supportOfflinePayment) && (
                    <Menu.Item key="offlinePayments">
                      <SpTab
                        to={`${path}?status.IN=OFFLINEPAYMENTS`}
                        selected={current === 'offlinePayments'}
                      >
                        OFFLINE PAYMENTS
                      </SpTab>
                    </Menu.Item>
                  )
                }
                <Menu.Item key="downloadingReport">
                  <SpTab
                    to={`${path}?status.IN=DOWNLOADREPORT`}
                    selected={current === 'downloadingReport'}
                  >
                    DOWNLOAD REPORT
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="paymentPageEntries">
                  <SpTab
                    to={`${path}?status.IN=DOWNLOADREPORT`}
                    selected={current === 'paymentPageEntries'}
                  >
                    PAYMENT PAGE ENTRIES
                  </SpTab>
                </Menu.Item>
              </Menu>
              {
                current === 'loadBalance' ? (
                  <>
                    <Row className="my-2">
                      <Col span={24} className="d-flex justify-content-end">
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={() => downloadReport()}
                          ghost
                        >
                          Download Report
                        </SpButton>
                      </Col>
                    </Row>
                    <Table
                      onRow={(record) => ({
                        onClick: () => {
                          selectedRecord(record);
                        },
                      })}
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={
                        (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                      }
                      rowKey={(record) => record.gid}
                      dataSource={payments}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  </>
                ) : (
                  <>
                    {
                      current === 'withdrawBalance' ? (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col>
                              <SpText className="text-uppercase mb-3" fontSize="20px">&nbsp;</SpText>
                            </Col>
                          </Row>
                          <Table
                            onRow={(record) => ({
                              onClick: () => {
                                selectDetails(record);
                              },
                            })}
                            rowClassName={() => 'ant-table-clickable-row'}
                            columns={orderColumns}
                            rowKey={(record) => record.gid}
                            dataSource={orders}
                            pagination={pagination}
                            onChange={handleTableChange}
                          />
                        </>
                      ) : current === 'downloadingReport' ? (
                        <>
                          <Table
                            columns={reportColumns}
                            rowKey={(record) => record.gid}
                            dataSource={paymentReports}
                            pagination={pagination}
                            onChange={handleTableChange}
                          />
                        </>
                      ) : current === 'paymentPageEntries' ? (
                        <>
                          <Table
                            columns={paymentPageEntriesColumns}
                            rowKey={(record) => record.gid}
                            onRow={(record) => ({
                              onClick: () => {
                                selectedRecordPaymentPageEntries(record);
                              },
                            })}
                            dataSource={paymentPagesEntries}
                            pagination={pagination}
                            onChange={handleTableChange}
                          />
                        </>
                      ) : (
                        <>
                          <Row type="flex" justify="space-between" className="my-2">
                            <Col span={24} className="d-flex justify-content-end">
                              {
                                (paymentPagesDetails && paymentPagesDetails.supportOfflinePayment) && (
                                  <SpButton
                                    type="secondary"
                                    shape="round"
                                    className="mr-4"
                                    ghost
                                    onClick={() => downloadOfflineReport()}
                                  >
                                    Download Offline Report
                                  </SpButton>
                                )
                              }
                            </Col>
                          </Row>
                          {/* DELETE OFFLINE MODAL */}
                          {deleteOfflineModal && (
                            <DeleteOfflinePayment
                              visible={deleteOfflineModal}
                              selectedOffline={selectedOffline}
                              submitting={submitting}
                              close={() => setDeleteOfflineModal(false)}
                              submit={deleteSelectedOfflinePayment}
                            />
                          )}
                          <Table
                            columns={offlineOrderColumns}
                            rowKey={(record) => record.gid}
                            dataSource={offlinePayments}
                            pagination={pagination}
                            onChange={handleTableChange}
                          />
                        </>
                      )
                    }
                  </>
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentPagesDetails: state.paymentPagesDetails,
  paymentPagesDetailsStatus: state.paymentPagesDetails.paymentPagesDetailsStatus,
  payments: state.paymentPagesDetails.payments.content,
  orders: state.paymentPagesDetails.orders.content,
  totalOrderElements: state.paymentPagesDetails.payments ? state.paymentPagesDetails.orders.totalElements : '',
  paymentSlug: state.paymentPagesDetails.paymentSlug,
  accountSlug: state.accountSlug.content,
  totalElements: state.paymentPagesDetails.payments ? state.paymentPagesDetails.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  role: (state.user.internalRole ? state.user.internalRole : state.user.internalRole),
  offlinePayments: state.paymentPagesDetails.offlinePayments.content,
  totalofflineElements: state.paymentPagesDetails.payments ? state.paymentPagesDetails.offlinePayments.totalElements : '',
  paymentReports: state.paymentPagesDetails.paymentReports.content,
  paymentPagesEntries: state.paymentPagesDetails.paymentPagesEntries.content,
  totalReportsElements: state.paymentPagesDetails.payments ? state.paymentPagesDetails.paymentReports.totalElements : '',
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentPagesDetails: gid => dispatch(paymentPagesDetailsActions.fetchPaymentPagesDetails({
    payload: gid,
  })),
  fetchPaymentsOrders: param => dispatch(paymentPagesDetailsActions.fetchPaymentsOrders({
    payload: param,
  })),
  fetchPayments: param => dispatch(paymentPagesDetailsActions.fetchPayments({
    payload: param,
  })),
  fetchPaymentPageSlug: param => dispatch(paymentPagesDetailsActions.fetchPaymentPageSlug({
    payload: param,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
  DeactivePaymentPage: param => dispatch(paymentPagesDetailsActions.DeactivePaymentPage({
    payload: param,
  })),
  soldOutPaymentPage: param => dispatch(paymentPagesDetailsActions.soldOutPaymentPage({
    payload: param,
  })),
  resumeSalePaymentPage: param => dispatch(paymentPagesDetailsActions.resumeSalePaymentPage({
    payload: param,
  })),
  updateCashPayment: param => dispatch(paymentPagesDetailsActions.updateCashPayment({
    payload: param,
  })),
  notifyPaymentPage: param => dispatch(paymentPagesDetailsActions.notifyPaymentPage({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchOfflinePayments: param => dispatch(paymentPagesDetailsActions.fetchOfflinePayments({
    payload: param,
  })),
  fetchPaymentReports: param => dispatch(paymentPagesDetailsActions.fetchPaymentReports({
    payload: param,
  })),
  fetchPaymentPagesEntries: param => dispatch(paymentPagesDetailsActions.fetchPaymentPagesEntries({
    payload: param,
  })),
  deleteOfflinePayments: param => dispatch(paymentPagesDetailsActions.deleteOfflinePayments({
    payload: param,
  })),
  notifyPaymentPageDetails: gid => dispatch(paymentDetailsAction.notifyPaymentPageDetails({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPagesDetails);
