import axios from 'axios';
import {
  TOKENS,
  PAGES,
} from 'appconstants';

export const ROOT_URL = process.env.REACT_APP_API_ENDPOINT;
export const ROOT_LOGIN_URL = process.env.REACT_APP_LOGIN_API_ENDPOINT;
export const ROOT_URL2 = process.env.REACT_APP_API2_ENDPOINT;
export const ROOT_QUICKBOOKS_URL = process.env.REACT_APP_QUICKBOOKS_API_ENDPOINT;
export const ROOT_QUICKBOOKS_BASE_URL = process.env.REACT_APP_QUICKBOOKS_BASE_ENDPOINT;
export const ROOT_SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_API_ENDPOINT;
export const ROOT_SALESFORCE_BASE_URL = process.env.REACT_APP_SALESFORCE_BASE_ENDPOINT;
export const ROOT_SUPEREST_URL = process.env.REACT_APP_SUPEREST_ENDPOINT;
export const ROOT_SUPEREST_DOMAIN = process.env.REACT_APP_SUPEREST_DOMAIN;
export const ROOT_SUPEREST_DOMAIN_NO_EXPORT = process.env.REACT_APP_SUPEREST_NO_EXPORT_DOMAIN;

export const axiosSuperestInstance = axios.create({
  baseURL: ROOT_SUPEREST_URL,
});

export const axiosAuthInstance = axios.create({
  baseURL: ROOT_URL,
});

export const axiosAuthInstance2 = axios.create({
  baseURL: ROOT_URL2,
});

export const axiosLoginInstance = axios.create({
  baseURL: ROOT_LOGIN_URL,
});

export const axiosLoginAuthInstance = axios.create({
  baseURL: ROOT_LOGIN_URL,
});

export const axiosNoAuthInstance = axios.create({
  baseURL: ROOT_URL,
});

export const axiosEmptyInstance = axios.create({
  baseURL: '',
});

export const axiosQuickBooksAuthInstance = axios.create({
  baseURL: ROOT_QUICKBOOKS_URL,
});

export const axiosSalesForceAuthInstance = axios.create({
  baseURL: ROOT_SALESFORCE_URL,
});

export const API_END_POINTS = {
  AUTHENTICATE: '/auth/authenticate',
  ADD_MEMBERSHIP: '/membership',
  FORGOT_PASSWORD: '/auth/forgotPassword',
  RESEND_PASSWORD: '/auth/resendPassword',
  GET_MEMBERSHIPS: '/membership',
  GET_PAYMENTS: '/payment-session',
  RESPOND_CHALLENGE: '/auth/respondChallenge',
  RESET_PASSWORD: '/auth/confirmForgotPassword',
  USER_REGISTER: '/auth/addUser',
  USER_ACCOUNT: 'account',
  INTERNAL_USER_ACCOUNT: '/internal/account',
  UPDATE_MEMBERSHIPS: '/membership',
  ROLES: '/role',
  JWE: '/jwe/account',
  USER_PROFILE: 'profile',
  GET_CUSTOMERS: '/customer',
  ADD_CUSTOMERS: '/customer',
  UPDATE_CUSTOMERS: '/customer',
  GET_BUSINESS: '/business',
  GET_BUSINESS_CATEGORIES: '/category',
  GET_ADDRESS: '/address',
  UPDATE_LOGO: '/storage/upload/logo',
  UPLOAD_REGISTRATION: '/storage/upload/kyc/india/registration',
  UPLOAD_PAN: '/storage/upload/kyc/india/company-pan',
  UPLOAD_STORE_FRONT: '/storage/upload/kyc/india/pos-front',
  UPLOAD_STORE_INSIDE: '/storage/upload/kyc/india/pos-inside',
  UPLOAD_BANK_STATEMENT: '/storage/upload/kyc/india/bank',
  UPLOAD_REP_ADDRESS: '/storage/upload/kyc/india/business-rep-address',
  UPLOAD_BUSINESS_ADDRESS: '/storage/upload/business-current-address',
  UPLOAD_BOARD_RESOLUTION: '/storage/upload/board-resolution',
  UPLOAD_GST_CERTIFICATE: '/storage/upload/gst-certificate',
  UPLOAD_EIGHTY_TWELVE: '/storage/upload/eighty-g-and-twelve-a',
  UPLOAD_REP_PAN: '/storage/upload/business-rep-pan',
  US_BUSINESS_VOID_CHECK: '/storage/upload/us-business-void-check',
  US_BUSINESS_DRIVER_LICENSE: '/storage/upload/us-business-driver-license',
  US_BUSINESS_GOVERNMENT_ID: '/storage/upload/us-business-government-id',
  GET_PAYOUT: '/payout',
  GET_INTERNAL_PAYOUT: '/internal/payout',
  GET_TRANSACTION: '/transaction',
  SUPPORT: '/support/email',
  GET_REPORTS: '/report',
  GET_ADMIN_REPORTS: '/internal/admin-report',
  GET_TERMINALS: '/terminal',
  GET_LOCATIONS: '/terminal-location',
  GET_DISPUTES: '/dispute',
  GET_LOCATION_TERMINALS: '/terminal?terminalLocation.gid.EQ',
  GET_CUSTOMER_CARDS: '/card?customer.gid.EQ',
  PUBLICKEYS: '/public-key',
  SECRETKEYS: '/secret-key',
  SYMMETRICKEYS: '/symmetric-key',
  GET_PAYOUT_TRANSACTIONS: '/payout-line-item?payout.gid.EQ',
  TOKEN_REFRESH: '/auth/refresh',
  GET_SUPPORT: '/support',
  GET_PAYMENTS_TIMELINE: '/payment-session-timeline',
  USER_DETAILS: '/user',
  GET_RECEIPTS: '/receipt',
  SUBMIT_EVIDENCE: '/payment-session',
  UPLOAD_EVIDENCE: '/storage/upload/dispute-evidence/key',
  ACCOUNT_SUMMARY: '/payment-session/summary',
  DAILY_SUMMARY: '/payment-session/summary/daily',
  HOURLY_SUMMARY: '/payment-session/summary/hourly',
  STATUS_SUMMARY: '/payment-session/summary/status',
  CUSTOMERS_SUMMARY: '/payment-session/summary/customer',
  TOP_PAYMENTS: '/payment-session',
  TRANSFERS_ACCOUNT_SUMMARY: '/transfer-session/summary',
  TRANSFERS_DAILY_SUMMARY: '/transfer-session/summary/daily',
  TRANSFERS_HOURLY_SUMMARY: '/transfer-session/summary/hourly',
  TRANSFERS_STATUS_SUMMARY: '/transfer-session/summary/status',
  TRANSFERS_CUSTOMERS_SUMMARY: '/transfer-session/summary/vendor',
  TRANSFERS_TOP_PAYMENTS: '/transfer-session',
  CUSTOMERS: '/customer',
  PAYMENTLINK: '/payment-link',
  SUBSCRIPTION: '/subscription',
  PLAN: '/plan',
  PRODUCT: '/product',
  INVOICE_API: '/invoice',
  PRODUCT_API: '/product',
  PAYMENTPAGES_API: '/payment-page-meta',
  SEATPAGES_API: '/seat-page-meta',
  BENEFICIARY: '/beneficiary',
  PAYMENTPAGE_SLUG_API: '/account-slug/payment-page-meta',
  UPLOAD_PAYMENTPAGE: '/storage/upload/payment-page',
  UPLOAD_PAYMENTPAGE_WAIVER: '/storage/upload/waiver-document',
  REMINDER_API: '/reminder',
  PAYMENTSBUTTON: '/payment-button',
  SUBSCRIPTION_BUTTON: '/subscription-button',
  WEBHOOK_API: '/webhook',
  WEBHOOK_EVENT: '/webhook-event',
  INTEGRATIONS: '/integrations',
  QUICKBOOKS_ACCOUNT: '/qbInstance/account',
  QUICKBOOKS_DETAILS: '/quickbooks-instance',
  QUICKBOOKS_OAUTH2: '/oauth2redirect',
  REFERRAL: '/referral',
  RATES: '/account-rate',
  ACQUIRER: '/acquirer',
  CATEGORY: '/internal/category',
  PAYMENT_TYPE: '/internal/payment-type',
  TRANSFER_TYPE: '/internal/transfer-type',
  SETTLEMENTS: '/settlements',
  UPLOAD_SETTLEMENT: '/internal/storage/upload/settlement',
  IMS_SETTLEMENTS: '/internal/batch/ims-settlement',
  STRIPE_SETTLEMENTS: '/internal/batch/stripe-settlement',
  AFFINIPAY_SETTLEMENTS: '/internal/batch/affinipay-settlement',
  TAXRATES: '/tax-rates',
  TAXES: '/tax',
  INTERNAL_RATES: '/internal/rate',
  DOWNLOAD_KYC: '/internal/storage',
  COUPONS: '/coupon',
  OFFERS: '/offer',
  PROMOTIONAL_CODES: '/promotional-codes',
  REDEMPTIONS: '/promotional-codes-redemption/coupon',
  OFFER_REDEMPTIONS: '/offer-redemption',
  COUPONS_SHOP: '/coupon/shop',
  COUPONS_CATEGORY: '/coupon/category',
  COUPONS_MENU: '/coupon/menu',
  AFFILIATE_ACCOUNT: '/affiliate-account',
  AFFILIATE_TRANSACTIONS: '/affiliate-transaction',
  AFFILIATE_EARNINGS: '/partner-earnings-invoice',
  SALESFORCE_API: '/credentials',
  SPOBJECT: '/sp-object/list',
  CREDENTIALS: '/credentials',
  SPFIELD: '/sp-field/list',
  SALESFORCE_MAPPING: '/mappings/list',
  SALESFORCE_DETAILS: '/mappings/details',
  SALESFORCE_MAPPING_DELETE: '/mappings',
  SCHEDULE_REPORTS: '/scheduled-report',
  ADMIN_SCHEDULE_REPORTS: '/internal/admin-scheduled-report',
  ADMIN_TERMINAL: '/internal/terminal',
  DOWNLOAD_SCHEDULE_REPORTS: '/scheduled-report-attempt',
  CURRENCY: '/internal/currency',
  SP_FIELD: '/sp-field',
  SP_OBJECT: '/sp-object',
  SP_OBJECT_LIST: '/sp-object/list',
  POSKEY: '/internal/pos-key',
  GLOBAL_RESTRICTED_KEY: '/internal/global-restricted-key',
  PAYMENTWIDGET: '/payment-widget',
  PAYMENTWIDGET_PAYMENT: '/payment-widget/payment-session',
  ISSUER: '/internal/issuer',
  ISSUER_RATE: '/internal/issuer-account-rate',
  ISSUER_RATE_FILTER: '/internal/issuer-rate',
  SETTLEMENT_RUN: 'internal/settlement-run',
  PAYMENTWIDGET_SUBSCRIPTION: '/payment-widget/subscription',
  GET_CASH_TRANSACTION: '/cash-transaction',
  GET_CHEQUE_TRANSACTION: '/check-transaction',
  CASH_RECEIPTS: '/cash-receipt',
  CHEQUE_RECEIPTS: '/check-receipt',
  NEW_PAPER_ROLL: '/service/paper-roll',
  FIRS: '/service/firs-request',
  TERMINAL_ISSUE: '/service/terminal-query',
  ADD_TERMINAL: '/service/new-terminal',
  INTERNAL_MEMBERSHIP: '/internal/membership',
  INTERNAL_ROLE: '/internal/role',
  GET_SERVICES: '/service',
  INTERNAL_SERVICE: '/internal/service',
  INTERNAL_FIELDAGENT: '/internal/field-agent',
  UPLOAD_CUSTOM_DOCUMENT: '/internal/storage/upload/custom-document',
  CUSTOM_DOCUMENT: '/custom-document',
  ADD_CUSTOM_DOCUMENT: '/internal/custom-document',
  GET_TRANSFERS: '/transfer-session',
  GET_INTERNALTRANSFERS: '/internal/transfer-session',
  GET_CONTACTS: '/contact',
  ADMIN_BIN: 'internal/bin',
  DOWNLOAD_CHECK: '/storage/download/check-proof',
  UPLOAD_TERMINAL_PROOF: '/internal/storage/upload/terminal/proof',
  PAGE_RECEIPTS: '/payment-page-receipt',
  DOWLLA_CUSTOMER: '/dwolla/customer',
  BENIFICIAL_OWNER: '/beneficial-owner',
  UPLOAD_DWOLLA_DOCUMENT: '/storage/upload/kyc/dwolla-beneficial-owner',
  GET_FUNDINGSOURCE: '/funding-source',
  FUNDINGSOURCE_LINK: '/funding-source-link',
  TRANSFERLINKS: '/transfer-links',
  BULKTRANSFER: '/bulk-transfer',
  MULTI_TRANSFER: '/multi-transfer',
  UPLOAD_BULKTRANSFER: 'storage/upload/bulk-transfer',
  ICIC_CUSTOMER: '/icici/customer',
  ICIC_FUNDING: '/icici/funding-source',
  TRANSFER_RECEIPTS: '/transfer-receipt',
  PAYMENTCHECKOUT: '/checkout-page',
  PAYMENTCHECKOUT_ALL: '/checkout-page',
  ACCOUNT_OTP: '/transfer-session/account-otp',
  BULKTRANSFER_OTP: '/bulk-transfer/otp',
  TRANSFER_OTP: '/transfer-session/otp',
  CUSTOM_DOCUMENT_REQUEST: '/custom-document-request',
  ADD_CUSTOM_DOCUMENT_REQUEST: '/internal/custom-document-request',
  UPLOAD_CUSTOM_DOCUMENT_REQUEST: '/internal/storage/upload/custom-document-request',
  ADD_CUSTOM_DOCUMENT_RESPONSE: '/custom-document-request',
  UPLOAD_CUSTOM_DOCUMENT_RESPONSE: '/storage/upload/custom-document-request-response',
  UPDATE_TRANSACTION: '/integration/updateTransaction',
  ACCOUNT_SETTLEMENT: '/account-settlement',
  ADD_ACCOUNT_SETTLEMENT: '/internal/account-settlement',
  UPLOAD_ACCOUNT_SETTLEMENT: '/internal/storage/upload/account-settlement',
  PARTNER_SETTLEMENT: '/partner-settlement',
  ADD_PARTNER_SETTLEMENT: '/internal/partner-settlement',
  UPLOAD_PARTNER_SETTLEMENT: '/internal/storage/upload/partner-settlement',
  GET_OCR_REQUEST: '/ocr',
  UPLOAD_OCR: '/ocr/upload/ocr',
  GET_BANK_STATEMENT: '/bank-account-statement',
  ACCOUNT_RECEIVABLES: '/bank-account-receivables',
  ACCOUNT_PAYABLES: '/bank-account-payables',
  GET_ACCOUNTSLUG: '/account-slug',
  GET_ACCOUNTSLUG_DETAILS: '/account-slug/public',
  GET_WALLET: '/wallet',
  WALLET_TRANSACTION: '/wallet-history-log',
  SET_UP_WALLET: '/wallet-alias-setup',
  WALLET_HISTORY_LOG: '/wallet-history-log',
  WALLET_BANK: '/wallet-bank',
  WALLET_LOAD: '/wallet-load-log',
  WALLET_CARD: '/wallet-card',
  WALLET_PAYOUT: '/wallet-payout-log',
  WALLET_PIN: '/wallet-pin-setup',
  WALLET_ALIAS: '/wallet-alias',
  TRANSFER_SETTING: '/transfer-setting',
  DASHBOARD_DAILY: '/payments/history/daily',
  DASHBOARD_HOURLY: '/payments/history/hourly',
  DASHBOARD_MONTHLY: '/payments/history/monthly',
  TRANSFERS_DAILY: '/transfers/history/daily',
  TRANSFERS_HOURLY: '/transfers/history/hourly',
  TRANSFERS_MONTHLY: '/transfers/history/monthly',
  REWARD_SETTINGS: '/wallet-reward-settings',
  STATICQR: '/internal/static-qr',
  STATICQRACCOUNT: '/internal/static-qr-account',
  WALLET_TRANSACTION_DETAILS: '/wallet-history-log',
  APPLY_CASHBACK: '/wallet-transaction-log',
  GET_CUSTOMREPORT_TEMPLATE: '/custom-report-template',
  ADD_CUSTOMREPORT_TEMPLATE: '/internal/custom-report-template',
  CUSTOMREPORT: '/custom-report',
  GET_RECAPTCHA: 'google-recaptcha?platformType=WEB',
  USONBOARDING: '/internal/us-boarding-link',
  VENDORINVOICE_API: '/vendor-invoice',
  UPLOAD_VENDORINVOICE: 'storage/upload/vendor-invoice',
  WALLETBENEFICIARY: '/wallet-beneficiary',
  WALLETINVOICE_API: '/wallet-vendor-invoice',
  GET_PAYMENT_ORDERS: '/payment-page-order',
  CASH_PAYMENT: '/payment-page-link',
  GET_OFFLINE: '/payment-page',
  GET_NOTIFY: '/seat-page',
  WOOCOMMERCE_API: '/woocommerce-instance',
  GET_WOOCOMMERCE: '/woocommerce-order',
  CLOVER_RECEIPT: '/clover-payment/receipt-details',
  ORDER_RECEIPT: '/inventory/order/receipt-details',
  GET_CATALOAG: '/catalog',
  GET_CATALOAG_ORDER: '/catalog-order',
  GET_INTERNAL_CATALOAG: '/internal/catalog',
  GET_INTERNAL_ORDER: '/internal/catalog-order',
  UPDATE_CATALOG_LOGO: '/storage/upload/catalog-image',
  GET_INVENTORY_ORDER: '/inventory/order',
  GET_INVENTORY_ITEM: '/inventory/item',
  GET_INVENTORY_CATEGORY: '/inventory/category',
  GET_INVENTORY_MODIFIER: '/inventory/modifier-group',
  ADD_INVENTORY_MODIFIER: '/inventory/modifier',
  GET_EVENTS: '/seat-page-meta',
  GET_EVENTS_CARDS: '/seat-page-meta/paymentSession.seatPageMetaGid.EQ',
  UPLOAD_ITEM_IMAGE: '/storage/upload/item-image',
  SEATED_VENUE: '/seated-venue',
  ADD_SEATED_VENUE: '/seat-page-meta',
  GET_INVENTORY_ORDERTYPE: '/inventory/order-type',
  OFFLINE_SEATED_VENUE: '/seat-page-link',
  SEATPAGE_SLUG_API: '/account-slug/seat-page-meta',
  EVENT_RECEIPTS: '/seat-page-receipt',
  GET_INVENTORY_TAG: '/inventory/tag',
  GET_INVENTORY_SHOPS: '/shop',
  SHOP_SLUG_API: '/account-slug/shop-page',
  GET_TIPS: '/shop-tip',
  SHOP_LOGO: '/storage/upload/banner',
  CLOVER_WEBHOOK: '/clover-webhook/credentials',
  INTERNAL_CLOVER_WEBHOOK: '/internal/clover-webhook',
  CLOVER_IMPORT: '/clover-import',
  GUEST_TOKEN: '/guest-token',
  SUPERSET_DASHBOARD: '/superset-dashboard',
  GET_INVENTORY_QUICKSUPPORT: '/quick-support',
  QUICKSUPPORT_LOGO: '/storage/upload/quick-support',
  GET_SHOP_SECTION: '/shop-section',
  GET_SHOP_TABLE: '/shop-table',
  SUPERSET_DASHBOARD_LIST: '/superset-dashboard/list',
  CLOVER_SYNC: '/clover-sync-setting',
  SHOP_RESERVATION: '/shop-table-reservation',
  SHOP_PREORDERS: '/pre-order',
  TABLE_USER: '/shop-table/user',
  GET_ALL_SHOPS: '/shop',
  GET_ALL_SUPPORTS: '/shop/quick-support',
  GET_SUPPORTS: '/quick-support',
  SERVICEFEE: '/service-fee',
  GET_ALL_SUPPORTSUMMARY: '/shop/quick-support/summary',
  GET_INVENTORY_PAYMENTS: '/inventory/payment',
  GET_KDS_ORDERS: '/inventory/order/kds',
  ITEM_BULK_PATCH: '/inventory/item/bulk-patch',
  GET_SPLIT_UP: '/inventory/order/inventory-order-split-up',
  CATEGORY_BULK_PATCH: '/inventory/category/bulk-patch',
  GET_MENUS: '/inventory/menu',
  GET_MENUS_OPERATING_HOURS: '/operating-hours-master',
  GET_CATEGORY_WITH_ITEMS: '/inventory/category/category-with-item-statistics',
  PREP_TIME: '/account-inventory-config',
  DELAY_TIME: '/inventory/order/delay',
  COUPON_SHOP: '/coupon/shop',
  DOCUSIGN: '/account',
  GET_SEQUENCE_GENERATORS: '/internal/account-sequence',
  GET_INVOICE_SEQUENCE: 'account-sequence-config',
  GET_CURRENT_INVOICE_SEQUENCE: 'account-sequence-config/current-sequence',
  GET_INVENTORY_FEEDBACK: '/shop-feedback-question',
  GET_ORDER_INVENTORY_FEEDBACK: '/order-feedback',
  GET_ADVANCE_FEES: '/advanced-fee',
  SEQUENCE_GENERATORS: '/account-sequence',
  PAYMENTWIDGET_SLUG_API: '/account-slug/payment-widget',
  CUSTOM_SUGESSTIONS: '/custom-suggestions',
  DOWNLOAD_FUNDING_SOURCE_TEMPLATE: '/funding-source-link/template',
  BULK_CONTACT_GET: '/storage/upload/bulk-contact',
  BULK_CONTACT_STORE: '/storage/upload/bulk-contact',
  BULK_CONTACT_ADD: '/funding-source-link/bulkAdd',
  SET_DEFAULT_CONTACT: '/contact/primary',
  ACCOUNT_SESSION: '/transfer-session/contact',
  MULTI_FUNDINGSOURCE_LINK: '/funding-source-link/multi-add',
  DONATIONPAGES_API: '/donation-page-meta',
  UPLOAD_DONATIONPAGE: '/storage/upload/donation-page',
  DONATIONPAGE_SLUG_API: '/account-slug/donation-page',
  DONATIONPAGE_LINK: '/donation-page-link',
  DONATIONPAGE_NOTIFY: '/donation-page-entry',
  DONATION_RECEIPTS: '/donation-page-receipt',
};

axiosAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosAuthInstance2.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosQuickBooksAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosSalesForceAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

// Add a response interceptor
axiosAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          localStorage.removeItem('fcmToken');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

axiosAuthInstance2.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          localStorage.removeItem('fcmToken');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

axiosLoginAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

// Add a response interceptor
axiosQuickBooksAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          localStorage.removeItem('fcmToken');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

// Add a response interceptor
axiosSalesForceAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          localStorage.removeItem('fcmToken');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});
