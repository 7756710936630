/* eslint-disable */
import React from 'react';

const settings = ({ fill = "#B3B3B3", width = "25", height = "24" }) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M15.7825 0C16.7922 0.279612 17.7553 0.683495 18.6408 1.18058C18.2058 2.51651 18.5165 4.05437 19.5728 5.11068C20.6447 6.16699 22.167 6.47767 23.5029 6.04272C24 
      6.94369 24.4039 7.89126 24.6835 8.90097C23.4408 9.53786 22.5709 10.8427 22.5709 12.3495C22.5709 13.8408 23.4408 15.1456 24.6835 15.7825C24.4039 16.7922 24 17.7553 
      23.5029 18.6408C22.2913 18.2369 20.9087 18.4699 19.8835 19.3087V10.8272C19.8835 10.3922 19.7126 10.0039 19.4019 9.6932L15.9379 6.22913C15.6427 5.91845 15.2388 5.76311 
      14.8194 5.76311H6.89709C5.74757 5.76311 4.81553 6.69515 4.81553 7.84466V19.3087C3.77476 18.4699 2.39223 18.2369 1.18058 18.6408C0.683495 17.7553 0.279612 16.7922 
      0 15.7825C1.25825 15.1456 2.11262 13.8408 2.11262 12.3495C2.11262 10.8427 1.25825 9.53786 0 8.90097C0.279612 7.89126 0.683495 6.94369 1.18058 6.04272C2.5165 6.47767 
      4.05437 6.16699 5.11068 5.11068C6.16699 4.05437 6.47767 2.51651 6.04272 1.18058C6.94369 0.683495 7.89126 0.279612 8.90097 0C9.53786 1.25825 10.8427 2.11262 12.3495 
      2.11262C13.8408 2.11262 15.1456 1.25825 15.7825 0ZM6.89709 6.52427H14.8194C15.0369 6.52427 15.2388 6.60194 15.3942 6.77282L18.8582 10.2369C19.0291 10.3922 19.1068 
      10.5942 19.1068 10.8272V22.6796C19.1068 23.4097 18.5165 24 17.7864 24H6.89709C6.18252 24 5.5767 23.4097 5.5767 22.6796V7.84466C5.5767 7.11456 6.16699 6.52427 6.89709 
      6.52427ZM16.0621 14.1981C16.3107 14.1981 16.5126 14.4 16.5126 14.6485C16.5126 14.8971 16.3107 15.0835 16.0621 15.0835H8.62136C8.37282 15.0835 8.17087 14.8971 8.17087 
      14.6485C8.17087 14.4 8.37282 14.1981 8.62136 14.1981H16.0621ZM14.6485 18.2369C14.8816 18.2369 15.0835 18.4388 15.0835 18.6874C15.0835 18.9359 14.8816 19.1379 14.6485 
      19.1379H8.62136C8.37282 19.1379 8.17087 18.9359 8.17087 18.6874C8.17087 18.4388 8.37282 18.2369 8.62136 18.2369H14.6485ZM16.0621 16.2175C16.3107 16.2175 16.5126 16.4194 
      16.5126 16.668C16.5126 16.9165 16.3107 17.1184 16.0621 17.1184H8.62136C8.37282 17.1184 8.17087 16.9165 8.17087 16.668C8.17087 16.4194 8.37282 16.2175 8.62136 16.2175H16.0621ZM11.666 
      11.3864C11.9146 11.3864 12.1165 11.5883 12.1165 11.8369C12.1165 12.0854 11.9146 12.2874 11.666 12.2874H8.62136C8.37282 12.2874 8.17087 12.0854 8.17087 11.8369C8.17087 
      11.5883 8.37282 11.3864 8.62136 11.3864H11.666ZM18.0039 10.9825H15.7981C15.1612 10.9825 14.6485 10.4699 14.6485 9.83301V7.62718H6.89709C6.78835 7.62718 6.67961 7.72039
       6.67961 7.84466V22.6796C6.67961 22.8039 6.77281 22.8971 6.89709 22.8971H17.7864C17.9107 22.8971 18.0039 22.8039 18.0039 22.6796V10.9825Z" 
      fill={fill}
    />
  </svg>
);
export default settings;