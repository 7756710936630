// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';

import getPaymentWidgetBackground from 'utils/getPaymentWidgetBackground';
import getPaymentWidgetStatus from 'utils/getPaymentWidgetStatus';
import getSubscriptionBackground from 'utils/getSubscriptionBackground';
import getSubscriptionStatus from 'utils/getSubscriptionStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Tooltip,
  Select,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import Loading from 'components/Loading';
import {
  paymentWidgetsDetailActions,
  notificationActions,
  accountSlugActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  PAYMENTWIDGET_STATUSES,
  ACQUIRER_TYPES,
} from 'appconstants';
import SimpleReactValidator from 'simple-react-validator';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import CONSTANTS from 'store/constants';

import DeleteSelectedPaymentWidget from './components/deleteSelectedPaymentWidget';
import CopyPaymentWidget from './components/copyPaymentWidget';

const { Option } = Select;

const payButton = 'https://s3.ap-south-1.amazonaws.com/assets.swirepay.com/img/pay-button.png';

type Props = {
  fetchPaymentWidgetDetails: Function,
  deactivePaymentWidget: Function,
  setNotification: Function,
  paymentWidgetsDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  subscriptions: Array<Object>,
  totalElements: number,
  paymentWidgetDetailsStatus: string,
  validator: boolean,
  fetchPayments: Function,
  fetchSubscriptions: Function,
  accountSlug: Array<Object>,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  paymentSlug: Array,
  fetchAccountSlug: Function,
  fetchPaymentWidgetSlug: Function,
}

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const PaymentWidgetDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchPaymentWidgetDetails,
    deactivePaymentWidget,
    setNotification,
    paymentWidgetsDetails,
    loading,
    submitting,
    history,
    payments,
    totalElements,
    selectedAccount,
    paymentWidgetDetailsStatus,
    validator,
    fetchPayments,
    fetchSubscriptions,
    subscriptions,
    accountSlug,
    linkAccountSlug,
    unLinkAccountSlug,
    paymentSlug,
    fetchAccountSlug,
    fetchPaymentWidgetSlug,
  } = props;
  const { gid } = props.match.params;
  const [pagination, setPagination] = useState({});
  const [deletePaymentWidgetModal, setDeletePaymentWidgetModal] = useState(false);
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  // eslint-disable-next-line
  const [Description, setDescription] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [copyPaymentWidgetModal, setCopyPaymentWidgetModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const [enableUnlink, setEnableUnlink] = useState(false);
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();

  simpleValidator.current.purgeFields();

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const sortBy = parsed.sortBy || 'createdAt';
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const params = `&sortBy=${sortBy}&direction=${direction}`;
    const size = 1000;
    fetchPaymentWidgetDetails(gid);
    fetchPayments(gid);
    fetchSubscriptions(gid);
    fetchAccountSlug({ currentPage, params, size });
    fetchPaymentWidgetSlug(gid);
  };

  const copyCode = (record) => {
    const el = record.paymentWidgetLinks[0].link;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSWIDGETS.SUCCESS,
    });
  };

  const copyHtml = () => {
    const paymentWidgetCode = `
        <a
          href=${paymentWidgetsDetails.paymentWidgetLinks[0].link}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src=${payButton} alt="payButton" width="150" height="35" />
        </a>`;
    navigator.clipboard.writeText(paymentWidgetCode);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSWIDGETS.BUTTON_SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.paymentSession.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  const subscriptionRecord = (record) => {
    const searchParams = record.subscription ? record.subscription.gid : null;
    if (searchParams) {
      history.push(`${PAGES.SUBSCRIPTION}/${searchParams}`);
    }
  };

  useEffect(() => {
    if (paymentWidgetsDetails.gid && paymentWidgetsDetails.description) {
      setDescription(paymentWidgetsDetails.description);
    }
  }, [paymentWidgetsDetails]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTWIDGETS);
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      setDeletePaymentWidgetModal(false);
      fetchPaymentWidgetSlug(gid);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  const showDeletePaymentWidgetModal = () => {
    setDeletePaymentWidgetModal(true);
  };

  const deleteSelectedPaymentWidget = async (paymentWidgetGid: string) => {
    deactivePaymentWidget(paymentWidgetGid);
    setRedirect(true);
  };

  const downloadReport = async () => {
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.PAYMENTWIDGET}/${gid}/download?reportType=SUBSCRIPTION`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: ((e.response && e.response.data && e.response.data.messageCode) === '5002')
          ? MESSAGES.REPORT.NODATA : MESSAGES.REPORT.DOWNLOAD_ERROR,
      });
    }
  };

  useEffect(() => {
    if (paymentSlug && paymentSlug.length > 0) {
      const linkedSlug = paymentSlug.filter((item) => item.status === 'LINKED');
      if (linkedSlug && linkedSlug.length > 0) {
        setEnableUnlink(true);
        setSlugLink(linkedSlug[0].slugName);
        setSlugGid(linkedSlug[0].gid);
      } else {
        setEnableUnlink(false);
        setSlugLink('');
        setSlugGid('');
      }
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [paymentSlug]);

  const linkSlug = () => {
    const params = {
      type: 'PAYMENT_WIDGET',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };

  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  const columns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.paymentSession.net) {
          const { net } = record.paymentSession;
          const total = (net / 100).toFixed(record.paymentSession.currency.toFixed);
          return (
            <Tooltip placement="top" title="Click here to view details">
              {`${record.paymentSession.currency.prefix}${formatNumber(total, currencyType)}`}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">&#8211;</Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      render: (text, record) => {
        const { status } = record.paymentSession;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.employeeEmail) {
          const email = record.employeeEmail;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {email}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            &#8211;
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'fullName',
      width: '25%',
      align: 'left',
      render: (text, record) => {
        if (record.employeeFirstName && record.employeeLastName) {
          const fullName = `${record.employeeFirstName} ${record.employeeLastName}`;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        } if (record.employeeFirstName && !record.employeeLastName) {
          const fullName = record.employeeFirstName;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        } if (!record.employeeFirstName && record.employeeLastName) {
          const fullName = record.employeeLastName;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            &#8211;
          </Tooltip>
        );
      },
    },
  ];

  const terminalColumns = [
    {
      title: 'NET',
      dataIndex: 'net',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.paymentSession.net) {
          const { net } = record.paymentSession;
          const total = (net / 100).toFixed(record.paymentSession.currency.toFixed);
          return (
            <Tooltip placement="top" title="Click here to view details">
              {`${record.paymentSession.currency.prefix}${formatNumber(total, currencyType)}`}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">&#8211;</Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        const { status } = record.paymentSession;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </StatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.employeeEmail) {
          const email = record.employeeEmail;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {email}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            &#8211;
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'fullName',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        if (record.employeeFirstName && record.employeeLastName) {
          const fullName = `${record.employeeFirstName} ${record.employeeLastName}`;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        } if (record.employeeFirstName && !record.employeeLastName) {
          const fullName = record.employeeFirstName;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        } if (!record.employeeFirstName && record.employeeLastName) {
          const fullName = record.employeeLastName;
          return (
            <Tooltip placement="top" title="Click here to view details">
              {fullName}
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            &#8211;
          </Tooltip>
        );
      },
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '20%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const subscriptionColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'total',
      width: '13%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const total = record.subscription ? (record.subscription.total / 100).toFixed(2)
          : record.subscription2 ? (record.subscription2.amount / 100).toFixed(2) : 0;
        return (
          <Tooltip placement="top">
            {`${prefix}${formatNumber(total, currencyType)}`}
          </Tooltip>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record.subscription ? record.subscription.customer.name
          : record.subscription2 ? record.subscription2.customer.name : null;
        return (
          <Tooltip placement="top">
            {name || <>&#8211;</>}
          </Tooltip>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const email = record.subscription ? record.subscription.customer.email
          : record.subscription2 ? record.subscription2.customer.email : null;
        return (
          <Tooltip placement="top">
            {email || <>&#8211;</>}
          </Tooltip>
        );
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phoneNumber',
      width: '14%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const phoneNumber = record.subscription ? record.subscription.customer.phoneNumber
          : record.subscription2 ? record.subscription2.customer.phoneNumber : null;
        return (
          <Tooltip placement="top">
            {phoneNumber || <>&#8211;</>}
          </Tooltip>
        );
      },
    },
    {
      title: 'PLAN',
      dataIndex: 'planName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const planName = record.subscription ? record.subscription.plan.name
          : record.subscription2 ? record.subscription2.plan2.name : null;
        return (
          <Tooltip placement="top">
            {planName || <>&#8211;</>}
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const status = record.subscription ? record.subscription.status
          : record.subscription2 ? record.subscription2.status : null;
        return (
          <Tooltip placement="top">
            <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(status) }}>
              {getSubscriptionStatus(status)}
            </SpStatusTag>
          </Tooltip>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record.subscription && record.subscription.createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.subscription.createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (
            <Tooltip placement="top">
              <span>{tzDate}</span>
            </Tooltip>
          );
        }
        if (record.subscription2 && record.subscription2.createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.subscription2.createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (
            <Tooltip placement="top">
              <span>{tzDate}</span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top">&#8211;</Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentWidgetsDetails.gid && paymentWidgetDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  {paymentWidgetsDetails.title
                    ? (
                      <>
                        <SpText className="text-uppercase mr-4" fontSize="20px">
                          {paymentWidgetsDetails.title}
                        </SpText>
                        <StatusTag style={{ backgroundColor: getPaymentWidgetBackground(paymentWidgetsDetails.status) }}>
                          {getPaymentWidgetStatus(paymentWidgetsDetails.status)}
                        </StatusTag>
                      </>
                    )
                    : ''}
                </Col>
                {
                  paymentWidgetsDetails.status !== PAYMENTWIDGET_STATUSES.DEACTIVE && (
                    <>
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <Col>
                          <SpButton
                            type="secondary"
                            className="mr-4"
                            shape="round"
                            ghost
                            onClick={() => history.push(`${PAGES.ADDPAYMENTWIDGET}?WIDGET.ID=${gid}`)}
                          >
                            Edit
                          </SpButton>
                          <SpButton
                            type="danger"
                            shape="round"
                            onClick={showDeletePaymentWidgetModal}
                          >
                            Deactivate
                          </SpButton>
                        </Col>
                      </RBAC>
                    </>
                  )
                }
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Created On</span>
                  </Col>
                  <Col span={6}>
                    {paymentWidgetsDetails.createdAt && selectedAccount && selectedAccount.timezone
                      ? <SpH5>{moment.utc(paymentWidgetsDetails.createdAt).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a')}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Link</span>
                  </Col>
                  <Col span={21}>
                    {paymentWidgetsDetails.paymentWidgetLinks[0] && paymentWidgetsDetails.paymentWidgetLinks[0].link
                      && paymentWidgetsDetails.status !== PAYMENTWIDGET_STATUSES.DEACTIVE
                      ? (
                        <>
                          <SpH5>{paymentWidgetsDetails.paymentWidgetLinks[0].link}</SpH5>
                          <SpButton
                            type="secondary"
                            className="ml-3"
                            borderRadius="50px"
                            onClick={() => copyCode(paymentWidgetsDetails)}
                          >
                            <Clipboard />
                          </SpButton>
                          <SpButton
                            type="secondary"
                            className="ml-3"
                            borderRadius="50px"
                            onClick={() => setCopyPaymentWidgetModal(true)}
                          >
                            <Icon type="code" style={{ color: 'rgba(0,0,0,.5)' }} />
                          </SpButton>
                        </>
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Total Sales</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>{paymentWidgetsDetails.totalSales}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Total Revenue</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {
                        // eslint-disable-next-line
                        `${paymentWidgetsDetails.currency.prefix} ${formatNumber((paymentWidgetsDetails.totalRevenue / 100).toFixed(2), currencyType)}`
                      }
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                (paymentWidgetsDetails && paymentWidgetsDetails.serviceFeeEnabled) === true ? (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Service Fee</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {
                              // eslint-disable-next-line
                              `${paymentWidgetsDetails.currency.prefix} ${formatNumber((paymentWidgetsDetails.serviceFee / 100).toFixed(2), currencyType)}`
                            }
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Service Rate</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {
                              // eslint-disable-next-line
                              `${formatNumber((paymentWidgetsDetails.serviceFeeRate / 100).toFixed(2), currencyType)} %`
                            }
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                ) : null
              }
              <SpForm>
                {
                  (accountSlug.length > 0) && (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={6}>
                          <SpText className="mr-3" fontSize="14px" fontWeight="600">
                            Link Account Slug
                          </SpText>
                          <Select
                            className="mt-2 w-100"
                            showSearch
                            disabled={enableUnlink}
                            value={slugLink || 'Select slug'}
                            onChange={(value) => {
                              setSlugGid(value[0]);
                              setSlugLink(value[1]);
                            }}
                          >
                            {
                              accountSlug.map(slug => (
                                <Option
                                  key={1}
                                  value={[slug.gid, slug.slugName]}
                                >
                                  {slug.slugName}
                                </Option>
                              ))
                            }
                          </Select>
                        </Col>
                      </Row>
                      <Row className="mt-4" type="flex" justify="start" align="middle">
                        <Col span={3}>
                          {
                            enableUnlink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={unlinkSlug}
                              >
                                Unlink
                              </SpButton>
                            ) : slugLink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={linkSlug}
                              >
                                Link
                              </SpButton>
                            ) : (
                              <SpButton
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Link
                              </SpButton>
                            )
                          }

                        </Col>
                      </Row>
                    </>
                  )
                }
              </SpForm>
              {/* DELETE LOCATION MODAL */}
              {deletePaymentWidgetModal && (
                <DeleteSelectedPaymentWidget
                  visible={deletePaymentWidgetModal}
                  paymentWidgetsDetails={paymentWidgetsDetails}
                  submitting={submitting}
                  close={() => setDeletePaymentWidgetModal(false)}
                  submit={deleteSelectedPaymentWidget}
                />
              )}
              {/* COPY PAYMENT MODAL */}
              {copyPaymentWidgetModal && (
                <CopyPaymentWidget
                  visible={copyPaymentWidgetModal}
                  paymentWidgetsDetails={paymentWidgetsDetails}
                  submitting={submitting}
                  close={() => setCopyPaymentWidgetModal(false)}
                  copyHtml={copyHtml}
                  payButton={payButton}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Payment for this payment widget</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={
                  (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                }
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
              />
            </Card>
            <Card className="pl-2 mt-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">Subscriptions for this payment widget</SpText>
                </Col>
                <div className="d-flex justify-content-end">
                  <Col>
                    <SpButton
                      type="secondary"
                      shape="round"
                      onClick={() => downloadReport()}
                      ghost
                    >
                      Download Report
                    </SpButton>
                  </Col>
                </div>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    subscriptionRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={subscriptionColumns}
                rowKey={(record) => record.gid}
                dataSource={subscriptions}
                pagination={pagination}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentWidgetsDetails: state.paymentWidgetsDetails,
  paymentWidgetDetailsStatus: state.paymentWidgetsDetails.paymentWidgetDetailsStatus,
  payments: state.paymentWidgetsDetails.payments ? state.paymentWidgetsDetails.payments.content : [],
  subscriptions: state.paymentWidgetsDetails.subscriptions ? state.paymentWidgetsDetails.subscriptions.content : [],
  totalElements: 0,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  accountSlug: state.accountSlug.content,
  paymentSlug: state.paymentWidgetsDetails.paymentSlug,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentWidgetDetails: gid => dispatch(paymentWidgetsDetailActions.fetchPaymentWidgetDetails({
    payload: gid,
  })),
  fetchPayments: gid => dispatch(paymentWidgetsDetailActions.fetchPayments({
    payload: gid,
  })),
  fetchSubscriptions: gid => dispatch(paymentWidgetsDetailActions.fetchSubscriptions({
    payload: gid,
  })),
  deactivePaymentWidget: gid => dispatch(paymentWidgetsDetailActions.deactivePaymentWidget({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchPaymentWidgetSlug: gid => dispatch(paymentWidgetsDetailActions.fetchPaymentWidgetSlug({
    payload: gid,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentWidgetDetails);
