import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Card,
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';

import {
  manageAccountActions,
  notificationActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  COUNTRIES,
} from 'appconstants';
import {
  SpButton,
  SpForm,
  SpText,
  SpH5,
} from 'components/DesignKit';
import AddAccont from './components/AddAccont';
import DeleteAccount from './components/DeleteAccount';


type Props = {
  fetchSelfAccountById: Function,
  deleteSelefAccount: Function,
  loading: boolean,
  test: boolean,
  history: {
    push: Function,
  };
  selectedAccount: Object,
  selectedAccountDetails: Object,
  submitting: Boolean,
};

const ManageAccountDetails = (props: Props) => {
  const location = useLocation();
  const {
    loading,
    test,
    history,
    selectedAccount,
    deleteSelefAccount,
    fetchSelfAccountById,
    selectedAccountDetails,
    submitting,
  } = props;
  const [addAccontModal, setAddAccountModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const { gid } = props.match.params;
  const [redierct, setRedirect] = useState(false);

  const getData = () => {
    fetchSelfAccountById(gid);
  };

  useEffect(() => {
    getData();
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!submitting && redierct) {
      setShowDeleteModal(false);
      history.push(PAGES.MANAGE_ACCOUNTS);
    }
  }, [submitting]);


  const addNewBankAccount = (data) => {
    console.log(data);
  };

  const deleteSelectedAccount = (data) => {
    deleteSelefAccount({ data });
    setRedirect(true);
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
      selectedAccountDetails && selectedAccountDetails.gid && (
        <Card className="mb-3">
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase mr-4" fontSize="20px">Contact Details</SpText>
            </Col>
            <Col>
              {/* <SpButton
            type="secondary"
            shape="round"
            className="mr-3"
            onClick={() => setAddAccountModal(true)}
          >
            Edit
          </SpButton> */}
              <SpButton
                type="danger"
                shape="round"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </SpButton>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Name</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.name}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Created On</span>
              </Col>
              <Col span={6}>
                <SpH5>{moment
                  .utc(selectedAccountDetails.createdAt)
                  .tz(selectedAccount.timezone)
                  .format('MMM DD, YYYY')}
                </SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Phone</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.phoneNumber}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Email</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.email}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Address Line</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.address.street}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row>
              <Col span={4}>
                <span>City</span>
              </Col>
              <Col>
                <SpH5>{selectedAccountDetails.contact.address.city}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row>
              <Col span={4}>
                <span>Postal Code</span>
              </Col>
              <Col>
                <SpH5>{selectedAccountDetails.contact.address.postalCode}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>State</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.address.state}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Country</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.address.country.name}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase mr-4" fontSize="20px">Account Details</SpText>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Customer Type</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.contact.contactType || ''}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Holder Name</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.issuerBank.accountHolderName}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Accont Type</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.issuerBank.accountType}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account No.</span>
              </Col>
              <Col span={6}>
                <SpH5>**** **** **** {selectedAccountDetails.issuerBank.lastFour}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Routing No.</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.issuerBank.routingNumber}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Bank Name</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.issuerBank.bankName}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Nick Name</span>
              </Col>
              <Col span={6}>
                <SpH5>{selectedAccountDetails.nickName}</SpH5>
              </Col>
            </Row>
          </SpForm>
          <AddAccont
            close={() => setAddAccountModal(false)}
            submit={addNewBankAccount}
            visible={addAccontModal}
            selectedAccount={selectedAccount}
            country={selectedAccount.country.name}
            cntryId={selectedAccount.country.id}
            isIndia={isIndia}
          />
          <DeleteAccount
            close={() => setShowDeleteModal(false)}
            submit={deleteSelectedAccount}
            visible={showDeleteModal}
            selectedRecordAccount={selectedAccountDetails}
          />
        </Card>
      )
    }
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAccountDetails: state.manageAccounts.selectedAccountDetails,
  loading: state.loading.loading,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelfAccountById: param => dispatch(manageAccountActions.fetchSelfAccountById({
    payload: param,
  })),
  deleteSelefAccount: param => dispatch(manageAccountActions.deleteSelefAccount({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccountDetails);
