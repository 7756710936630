// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import OtpInput from 'react-otp-input';
import Multiselect from 'multiselect-react-dropdown';
import {
  Card,
  Table,
  Row,
  Col,
  Icon,
  Input,
  Radio,
  Steps,
  DatePicker,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpButton,
  SpStatusTag,
  SpText,
  SpError,
  SpH5,
  SpForm,
} from 'components/DesignKit';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import getBulkTransferBackground from 'utils/getBulkTransferBackground';
import getBulkTransferStatus from 'utils/getBulkTransferStatus';
import formatAmount from 'utils/formatAmount';

import Loading from 'components/Loading';

import {
  bulkTransferActions,
  transferSettingsActions,
  filterParamsActions,
  manageAccountActions,
  notificationActions,
} from 'store/actions';
import { FILTERS_SPECS, NOTIFICATION } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  MESSAGES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import RBAC from 'components/rbac';
import Lock from 'assets/imgs/lock.svg';
import VerifiedUser from 'assets/imgs/Verified_User.svg';
import { color } from 'echarts/lib/export';
import UploadBulk from './components/UploadBulk';

const { Step } = Steps;
const { Option } = Select;

const dateFormat = 'MMM DD, YYYY';
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

type Props = {
  bulkTransfer: Array<Object>,
  fetchBulkTransfer: Function,
  fetchTransferSetting: Function,
  transferSettings: object,
  addBulkTransfer: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  isMobileView: Boolean,
  fetchSelfAccounts: Function,
  manageAccountsList: Array<Object>,
  setNotification: Function,
  fetchBulkTransferSearch: Function,
  bulkTransferSearch: Array<object>,
};

const BulkTransferList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    bulkTransfer,
    loading,
    addBulkTransfer,
    fetchBulkTransfer,
    fetchTransferSetting,
    transferSettings,
    totalElements,
    submitting,
    validator,
    test,
    history,
    isMobileView,
    fetchSelfAccounts,
    manageAccountsList,
    setNotification,
    fetchBulkTransferSearch,
    bulkTransferSearch,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }
  const [, forceUpdate] = useState();
  const [pagination, setPagination] = useState({});
  const [lastFour, setLastFour] = useState('');
  const [otpRequires, setOtpRequires] = useState('');
  const [supportsSchedule, setSupportsSchedule] = useState('');
  const [searchText, setSearchText] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const [transferTypeLIst, setTransferTypeList] = useState(selectedAccount?.supportedTransferTypes);
  const [transferMode, setTransferMode] = useState(selectedAccount?.supportedTransferTypes?.[0]);
  const [sumAmount, setSumAmount] = useState(0);
  const [TransferAmount, setTransferAmount] = useState();
  const [allBulkTransfers, setAllBulkTransfers] = useState([]);
  const [currentStep, setCurrent] = useState(0);
  const [transferCurrent, setTransferCurrent] = useState(1);
  const [primaryAccount, setPrimaryAccount] = useState([]);
  const [transferDate, setTransferDate] = useState();
  const [transferApiDate, setTransferApiDate] = useState();
  const [remarks, setRemarks] = useState('');
  const [otp, setOtp] = useState('');
  const [otpConfirm, setOtpConfirm] = useState(false);
  const [UniqueId, setUniqueId] = useState('');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [updates, setUpdates] = useState([]);

  const debouncedFetchBulkTransfer = debounce(fetchBulkTransfer, 2000, { leading: true });
  const debouncedFetchSelfAccounts = debounce(fetchSelfAccounts, 2000, { leading: true });
  const debouncedFetchBulkTransferSearch = debounce(fetchBulkTransferSearch, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = filterParam;
    debouncedFetchBulkTransfer({ currentPage, sortParams, filterParams });
    fetchTransferSetting({ sortParams });
    debouncedFetchSelfAccounts({ currentPage, sortParams, filterParam });
    debouncedFetchBulkTransferSearch({ searchParams: '' });
  };

  const simpleValidator = useRef(new SimpleReactValidator());

  const handleSearch = (val) => {
    setSearchText(val);
    const searchParams = val;
    debouncedFetchBulkTransferSearch({ searchParams });
  };

  const handleChange = (data) => {
    setAllBulkTransfers(data);
    setTransferAmount();
    setSumAmount();
    setUpdates(data);
  };

  const handleRemove = (data) => {
    if (data && data.length > 0) {
      setAllBulkTransfers(data);
      setTransferAmount();
      setSumAmount();
    } else {
      getData();
    }
    setUpdates(data);
  };

  useEffect(() => {
    // This call is happennig for test mode and if it was live we should have issuer.
    if (test) {
      getData(location.search);
    } else if (selectedAccount && selectedAccount.issuer) {
      getData(location.search);
    }
    if (selectedAccount && selectedAccount.contactNumber) {
      setLastFour((selectedAccount.contactNumber).slice(-4));
    }
    if (selectedAccount && selectedAccount.issuer) {
      setOtpRequires(selectedAccount.issuer.requiresOtp);
      setSupportsSchedule(selectedAccount.issuer.supportsSchedule);
    }
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (manageAccountsList && manageAccountsList.length > 0) {
      const prime = manageAccountsList.filter((item) => item?.contact?.primary);
      setPrimaryAccount(prime);
    }
  }, [manageAccountsList]);

  useEffect(() => {
    if (!validator) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [validator]);

  useEffect(() => {
    if (bulkTransferSearch?.content && bulkTransferSearch?.content?.length > 0) {
      for (let i = 0; i < bulkTransferSearch?.content.length; i += 1) {
        bulkTransferSearch.content[i].key = `${bulkTransferSearch?.content[i]?.contact?.name} | ${bulkTransferSearch?.content[i]?.issuerBank?.bankName}****${bulkTransferSearch?.content[i]?.issuerBank?.lastFour}`;
      }
      setSelectedRecords(bulkTransferSearch?.content);
    }
  }, [bulkTransferSearch]);

  useEffect(() => {
    setAllBulkTransfers(bulkTransfer);
  }, [bulkTransfer]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [bulkTransfer]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    setTransferAmount();
    setSumAmount(0);
    setUpdates([]);
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.BULKTRANSFER,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.BULKTRANSFER,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const bulkTransferGid = record.gid;
    history.push({
      pathname: `${PAGES.BULKTRANSFER}/${bulkTransferGid}`,
      state: location.search,
    });
  };

  const applyAmount = () => {
    if (TransferAmount && Number(TransferAmount.replace(/[^\d]/g, '') / 100) > 0) {
      for (let i = 0; i < allBulkTransfers.length; i += 1) {
        allBulkTransfers[i].amount = formatAmount(Number(TransferAmount.replace(/[^\d]/g, '') / 100), currencyType);
      }
      const tempAmount = Number(TransferAmount.replace(/[^\d]/g, '') / 100) * allBulkTransfers.length;
      setSumAmount(tempAmount);
    }
  };

  const onDateChange = (date, dateString) => {
    setTransferDate(dateString);
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(Date, selectedAccount.timezone);
    const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    setTransferApiDate(Date);
  };

  const sendAmount = () => {
    setCurrent(currentStep + 1);
  };

  const getOtp = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueId(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  const addBulkTransfers = () => {
    const transferInfo = [];
    for (let i = 0; i < allBulkTransfers.length; i += 1) {
      let tempObject = {};
      if (allBulkTransfers?.[i]?.amount) {
        const amount = (allBulkTransfers?.[i]?.amount).replace(/[^\d]/g, '');
        tempObject.amount = Number(amount);
        tempObject.contactGid = allBulkTransfers[i]?.contact?.gid;
        tempObject.currencyCode = selectedAccount?.currency?.name;
        tempObject.fundingSourceGid = allBulkTransfers[i]?.gid;
        tempObject.transferTypeCode = transferMode;
        transferInfo.push(tempObject);
        tempObject = {};
      }
    }
    const payload = {
      transferSessions: transferInfo,
      payerFundingSourceGid: primaryAccount?.[0]?.gid,
      description: remarks,
      transferTypeCode: transferMode,
      transferDate: transferApiDate,
      amount: Number(sumAmount * 100),
    };
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(sumAmount * 100) > approveLimt) && !selectedAccount.issuer.requiresOtp) {
      setCurrent(currentStep + 1);
      simpleValidator.current.purgeFields();
      getOtp();
    } else {
      addBulkTransfer(payload);
      setOtpConfirm(true);
    }
  };

  const addBulkTransfersOtp = () => {
    const transferInfo = [];
    for (let i = 0; i < allBulkTransfers.length; i += 1) {
      let tempObject = {};
      if (allBulkTransfers?.[i]?.amount) {
        const amount = (allBulkTransfers?.[i]?.amount).replace(/[^\d]/g, '');
        tempObject.amount = Number(amount);
        tempObject.contactGid = allBulkTransfers[i]?.contact?.gid;
        tempObject.currencyCode = selectedAccount?.currency?.name;
        tempObject.fundingSourceGid = allBulkTransfers[i]?.gid;
        tempObject.transferTypeCode = transferMode;
        transferInfo.push(tempObject);
        tempObject = {};
      }
    }
    const payload = {
      transferSessions: transferInfo,
      payerFundingSourceGid: primaryAccount?.[0]?.gid,
      description: remarks,
      transferTypeCode: transferMode,
      transferDate: transferApiDate,
      amount: Number(sumAmount * 100),
      confirmationEmail: transferSettings.approvalEmail,
      confirmationSms: selectedAccount.contactNumber,
      swirepayOtpGid: UniqueId,
      otpCode: otp,
    };
    addBulkTransfer(payload);
    setOtpConfirm(true);
  };

  const transferFunds = (event) => {
    if (transferCurrent === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setTransferCurrent(2);
    } else {
      addBulkTransfers();
    }
  };

  const otpTransferFunds = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    addBulkTransfersOtp();
  };


  const setTransectionAmountFormat = (val, rec) => {
    const tempArray = [...allBulkTransfers];
    const index = tempArray.findIndex((item) => item.gid === rec.gid);
    if (index >= 0) {
      tempArray[index].amount = val;
    }
    setAllBulkTransfers(tempArray);
    setTransferAmount();
    const total = tempArray.reduce((n, { amount }) => n + (amount ? Number(amount.replace(/[^\d]/g, '')) : 0), 0);
    setSumAmount(total / 100);
  };

  const setTransectionAmount = (val, rec) => {
    const tempArray = [...allBulkTransfers];
    const index = tempArray.findIndex((item) => item.gid === rec.gid);
    if (index >= 0) {
      tempArray[index].amount = val;
    }
    setAllBulkTransfers(tempArray);
  };


  const columns = [
    {
      title: 'CONTACTS',
      dataIndex: 'contact',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const contact = record?.contact?.name;
        return (
          <span style={{ cursor: 'pointer' }}>
            {contact || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'CONTACT TYPE',
      dataIndex: 'type',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const type = record?.contact?.contactType;
        return (
          <span style={{ cursor: 'pointer' }}>
            {type || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'transferCount',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record?.issuerBank?.bankName;
        const bankName = record?.issuerBank?.lastFour;
        const verified = record?.issuerBank?.isVerified;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name} ****{bankName} {verified ? <><img src={VerifiedUser} alt="verified" /></> : ''}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const status = record?.issuerBank?.status;
        return (
          <SpStatusTag style={{ backgroundColor: status ? getBulkTransferBackground(status) : '' }}>
            {status ? getBulkTransferStatus(status) : <>&#8211;</>}
          </SpStatusTag>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const amount = record?.amount;
        return (
          <Input
            value={amount}
            prefix={prefix}
            placeholder="0.00"
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setTransectionAmount(e.currentTarget.value, record);
              } else if (!regex.test(value)) {
                setTransectionAmount(0.00, record);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setTransectionAmountFormat(value, record);
              }
            }}
          />
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        ((!test) && (selectedAccount && (!selectedAccount.issuer)))
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Please go to settings and provide additional business information to enable live transfers</h5>
              </div>
            </Card>
          )
          : (
            <>
              {
              currentStep === 0 && (
                <Card>
                  <Row className="mt-4 mb-4">
                    <Col>
                      <Multiselect
                        onSearch={handleSearch}
                        onSelect={handleChange}
                        onRemove={handleRemove}
                        displayValue="key"
                        options={selectedRecords}
                        showCheckbox
                        selectionLimit={10}
                        selectedValues={updates}
                      />
                    </Col>
                  </Row>
                  <Card style={{ backgroundColor: '#fafafa', borderRadius: '10px' }} className="mb-3">
                    <Row className="my-2" type="flex" justify="start">
                      <Col span={8} style={{ borderRight: '3px solid #aaa' }} className="mr-3">
                        <div>
                          <SpText fontSize="16px" fontWeight="600" color="#000000">Apply amount to all contacts</SpText>
                        </div>
                        <div className="d-flex justify-content-start mt-3">
                          <Input
                            value={TransferAmount}
                            prefix={prefix}
                            placeholder="0.00"
                            onChange={(e) => {
                              const regex = /^\d*\.?\d*$/;
                              const { value } = e.currentTarget;
                              if (regex.test(value) || value === '') {
                                setTransferAmount(e.currentTarget.value);
                              } else if (!regex.test(value)) {
                                setTransferAmount(0.00);
                              }
                            }}
                            onKeyPress={e => {
                              const keyCode = e.charCode || e.which;
                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={e => {
                              if (e.currentTarget.value) {
                                const value = formatAmount(e.currentTarget.value, currencyType);
                                setTransferAmount(value);
                              }
                            }}
                          />
                          <SpButton
                            type="secondary"
                            className="mr-3 ml-3"
                            style={{ width: '50%' }}
                            // disabled={!(TransferAmount > 0)}
                            onClick={() => applyAmount()}
                          >
                            Apply
                          </SpButton>
                        </div>
                      </Col>
                      <Col span={8} style={{ borderRight: '3px solid #aaa' }} className="mr-3">
                        <div>
                          <SpText fontSize="16px" fontWeight="600" color="#000000">Select Transfer Mode</SpText>
                        </div>
                        <div className="d-flex justify-content-start mt-3">
                          <Radio.Group onChange={(e) => setTransferMode(e.target.value)} value={transferMode}>
                            {
                          transferTypeLIst.map((item) => (
                            <Radio value={item}>{item === 'RTP' ? 'INSTANT PAYMENT' : item}</Radio>
                          ))
                        }
                            <Radio disabled>eCheck</Radio>
                          </Radio.Group>
                        </div>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('transfer mode', transferMode, 'required')}
                          </SpError>
                        </div>
                      </Col>
                      <Col span={7}>
                        <div>
                          <SpText fontSize="16px" fontWeight="600" color="#000000">Sum Amount</SpText>
                        </div>
                        <div className="d-flex justify-content-space-between mt-3">
                          <div>
                            <SpText fontSize="28px" fontWeight="600" color="#000000">{formatAmount(sumAmount, currencyType)}</SpText>
                          </div>
                          <SpButton
                            type="primary"
                            className="mr-3 ml-3"
                            style={{ width: '30%' }}
                            disabled={!(sumAmount > 0)}
                            onClick={(e) => sendAmount(e)}
                          >
                            Transfer
                          </SpButton>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <RBAC
                    fallback={(
                      <Table
                        // onRow={(record) => ({
                        //   onClick: () => {
                        //     selectedRecord(record);
                        //   },
                        // })}
                        columns={columns}
                        rowKey={(record) => record.gid}
                        dataSource={allBulkTransfers}
                        pagination={pagination}
                        onChange={handleTableChange}
                      />
                )}
                    permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
                  >
                    <Table
                      // onRow={(record) => ({
                      //   onClick: () => {
                      //     selectedRecord(record);
                      //   },
                      // })}
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={columns}
                      rowKey={(record) => record.gid}
                      dataSource={allBulkTransfers}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  </RBAC>
                </Card>
              )
            }
              {
              currentStep === 1 && (
                <div
                  className="mx-auto mt-5 py-3 px-3"
                  style={{
                    width: 800, height: 'auto', backgroundColor: '#FFF', padding: '15px',
                  }}
                  bodyStyle={{ height: '100%' }}
                >
                  <Row type="flex" justify="space-between" className="my-2">
                    <Col span={12}>
                      <SpText fontSize="18px" fontWeight="600">Transfer</SpText>
                    </Col>
                    <Col>
                      <SpText fontSize="18px" fontWeight="600" style={{ cursor: 'pointer' }} onClick={() => setCurrent(0)}>X</SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" className="my-2">
                    <Col>
                      <Steps current={transferCurrent}>
                        <Step key="1" title="Payment" />
                        <Step key="2" title="Review" />
                      </Steps>
                    </Col>
                  </Row>
                  {
                    transferCurrent === 1 ? (
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <label htmlFor=""><SpH5>From</SpH5></label>
                            <Input
                              type="text"
                              value={primaryAccount.length > 0 ? `${primaryAccount[0]?.issuerBank?.bankName} **** ${primaryAccount[0]?.issuerBank?.lastFour}` : ''}
                              readOnly
                              style={{ height: '40px' }}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor=""><SpH5>Transfer Date</SpH5></label>
                            <div>
                              <DatePicker
                                value={transferDate ? moment(transferDate) : ''}
                                format={dateFormat}
                                allowClear={false}
                                onChange={onDateChange}
                                style={{ height: '40px' }}
                                disabledDate={current => current && current < moment().subtract(1, 'days')}
                              />
                              <SpError>
                                {simpleValidator.current.message('Transfer Date', transferDate, 'required')}
                              </SpError>
                            </div>
                          </div>
                          <div className="pb-3">
                            <div className="d-flex">
                              <div className="w-50 mr-3">
                                <label className="d-block"><SpH5>Sum Amount</SpH5></label>
                                <div>
                                  <Input
                                    prefix={prefix}
                                    type="text"
                                    value={formatAmount(sumAmount, currencyType)}
                                    readOnly
                                    style={{ height: '40px' }}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="w-50">
                                <label className="d-block"><SpH5>Memo</SpH5></label>
                                <Input
                                  placeholder="Memo"
                                  onChange={(e) => setRemarks(e.currentTarget.value)}
                                  value={remarks}
                                  style={{ height: '40px' }}
                                />
                                <SpError>
                                  {simpleValidator.current.message('remarks', remarks, 'required')}
                                </SpError>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={12}>
                                <SpText fontSize="18px" fontWeight="600">PAYMENT DETAILS</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={6}>
                                <SpH5>From</SpH5>
                              </Col>
                              <Col span={6}>
                                <SpText>{primaryAccount.length > 0 ? `${primaryAccount[0]?.issuerBank?.bankName} **** ${primaryAccount[0]?.issuerBank?.lastFour}` : ''}</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={6}>
                                <SpH5>Transfer Mode</SpH5>
                              </Col>
                              <Col span={6}>
                                <SpText>{transferMode === 'RTP' ? 'INSTANT PAYMENT' : transferMode}</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={6}>
                                <SpH5>Transfer Date</SpH5>
                              </Col>
                              <Col span={6}>
                                <SpText>{transferDate}</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={6}>
                                <SpH5>Sum Amount</SpH5>
                              </Col>
                              <Col span={6}>
                                <SpText>{prefix} {formatAmount(sumAmount, currencyType)}</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={6}>
                                <SpH5>Memo</SpH5>
                              </Col>
                              <Col span={6}>
                                <SpText>{remarks}</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={12}>
                                <SpText fontSize="18px" fontWeight="600">CONTACT DETAILS</SpText>
                              </Col>
                            </Row>
                          </SpForm>
                          {
                            allBulkTransfers.length > 0 && allBulkTransfers.map((item) => (
                              <>
                                {
                                  item?.amount && (
                                    <SpForm>
                                      <Row type="flex" justify="start" align="middle">
                                        <Col span={6}>
                                          <SpH5>{item?.contact?.name}</SpH5>
                                        </Col>
                                        <Col span={6}>
                                          <SpText>{prefix} {formatAmount(item?.amount, currencyType)}</SpText>
                                        </Col>
                                      </Row>
                                    </SpForm>
                                  )
                                }
                              </>
                            ))
                          }
                        </Col>
                      </Row>
                    )
                  }
                  <Row type="flex" justify="end" className="my-2">
                    <Col>
                      <SpButton
                        type="secondary"
                        className="mr-2"
                        disabled={transferCurrent === 1}
                        onClick={() => setTransferCurrent(1)}
                      >
                        Previous
                      </SpButton>
                      <SpButton
                        type="primary"
                        onClick={(e) => transferFunds(e)}
                      >
                        {transferCurrent === 1 ? 'Next' : 'Confirm'}
                      </SpButton>
                    </Col>
                  </Row>
                </div>
              )
            }
              {
              currentStep === 2 && (
                <div
                  className="mx-auto mt-5 py-3 px-3"
                  style={{
                    width: 800, height: 'auto', backgroundColor: '#FFF', padding: '15px',
                  }}
                  bodyStyle={{ height: '100%' }}
                >
                  <Row type="flex" justify="center" className="my-2">
                    <Col>
                      <img src={Lock} alt="Lock" />
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" className="my-2">
                    <Col>
                      <SpText fontSize="18px" fontWeight="600">Enter code</SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" className="my-2">
                    <Col>
                      <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" className="my-2 mt-3">
                    <Col>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span style={{ width: '8px' }} />}
                        inputType="password"
                        inputStyle={{
                          border: '1px solid',
                          borderRadius: '8px',
                          width: '54px',
                          height: '54px',
                          fontSize: '12px',
                          color: '#000',
                          fontWeight: '400',
                          caretColor: 'blue',
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" className="my-2 mt-3">
                    <Col>
                      <SpButton
                        type="primary"
                        onClick={(e) => otpTransferFunds(e)}
                      >
                        Verify
                      </SpButton>
                    </Col>
                  </Row>
                </div>
              )
            }
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  bulkTransfer: state.bulkTransfer.content,
  bulkTransferSearch: state.bulkTransfer.searchResults,
  transferSettings: state.transferSettings.content,
  totalElements: state.bulkTransfer.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
  manageAccountsList: state.manageAccounts.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBulkTransfer: param => dispatch(bulkTransferActions.fetchBulkTransfer({
    payload: param,
  })),
  fetchTransferSetting: param => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: param,
  })),
  addBulkTransfer: param => dispatch(bulkTransferActions.addBulkTransfer({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  fetchSelfAccounts: param => dispatch(manageAccountActions.fetchSelfAccounts({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchBulkTransferSearch: param => dispatch(bulkTransferActions.fetchBulkTransferSearch({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(BulkTransferList);
