// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Input,
  Select,
  Modal,
  Divider,
  Switch,
  Radio,
} from 'antd';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import getStateList from 'utils/stateList';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import {
  COUNTRY_CODES,
  COUNTRY_PHONE_CODE,
  NOTIFICATION_TYPES,
} from 'appconstants';
import { vendorsActions } from 'store/actions';

const { Option } = Select;
const RadioGroup = Radio.Group;

type Props = {
  close: Function,
  phoneCode: string,
  country: String,
  statesInCountry: Array<Object>,
  zipTag: String,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  cntryId: Number,
  fetchCustomTypes: Function,
  deleteSingleCustomType: Function,
  deleteAllCustomTypes: Function,
  customSuggestions: Array<Object>,
};

const AddVendor = (props: Props) => {
  const {
    visible,
    close,
    phoneCode,
    submit,
    submitting,
    country,
    statesInCountry,
    zipTag,
    cntryId,
    fetchCustomTypes,
    deleteSingleCustomType,
    deleteAllCustomTypes,
    customSuggestions,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contactType, setContactType] = useState('CUSTOMER');
  const [customType, setCustomType] = useState();
  const [phoneFormate, setPhoneFormate] = useState();
  const [phoneNumberCode, setPhoneCode] = useState(phoneCode);
  const [countryCode, setCountryCode] = useState(getCountryAlpha2(cntryId));
  const [disableButton, setDisableButton] = useState(false);
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryName, setCountryName] = useState('');
  const [stateList, setSateList] = useState(statesInCountry);
  const [postalCodeRegex, setPostalCodeRegex] = useState(country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/);
  const [zipText, setZipText] = useState(zipTag);
  const [location, setLocation] = useState();
  const [notificationType, setNotificationType] = useState(NOTIFICATION_TYPES[2].value);
  const [enableNotification, setEnableNotification] = useState(true)
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (country && country === 'India') {
      setCountryName('INDIA');
    } else if (country && country === 'United States of America') {
      setCountryName('USA');
    }
    fetchCustomTypes({ type: 'CONTACT_TYPE' });
  }, []);

  const updateCountry = (value) => {
    setCountryCode(value);
    if (value === 'IN') {
      setSateList(getStateList(2));
      setZipText('Pin code');
      setPostalCodeRegex(/^[1-9][0-9]{5}$/);
    } else if (value === 'US') {
      setZipText('Zip code');
      setSateList(getStateList(1));
    } else if (value === 'CA') {
      setZipText('Zip code');
      setSateList(getStateList(value));
      setPostalCodeRegex(/^[0-9][0-9]{4}$/);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${phone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const updateBillingAddress = (address) => {
    setLocation(address);
    if (address.value && address.value.terms && address.value.terms.length >= 2) {
      if (
        (address.value.terms[address.value.terms.length - 1].value === 'USA')
        || (address.value.terms[address.value.terms.length - 1].value === 'India')
      ) {
        setStreetName(address.value.structured_formatting.main_text);
        setCityName(address.value.terms[address.value.terms.length - 3]?.value);
        if (address.value.terms[address.value.terms.length - 1].value === 'India') {
          setCountryCode('IN');
          setCountryName('INDIA');
          setSateList(getStateList(2));
          const statesInIndia = getStateList(2);
          const slectedState = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 2].value);
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInIndia.filter(state => state.name === address.value.terms[address.value.terms.length - 3].value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
          setZipText('Pin code');
          setPostalCodeRegex(/^[1-9][0-9]{5}$/);
        }
        if (address.value.terms[address.value.terms.length - 1].value === 'USA') {
          setCountryCode('US');
          setCountryName('USA');
          const statesInUs = getStateList(1);
          const stateLength = address.value.terms[address.value.terms.length - 2].value.length;
          const slectedState = statesInUs.filter(state => (stateLength === 2
            ? state.id === address.value.terms[address.value.terms.length - 2].value
            : state.name === address.value.terms[address.value.terms.length - 2].value));
          if (slectedState && slectedState.length > 0) {
            setStateName(slectedState[0].id);
          }
          if (slectedState && slectedState.length === 0) {
            const slectedState1 = statesInUs.filter(state => state.name === address.value.terms[address.value.terms.length - 3]?.value);
            if (slectedState1 && slectedState1.length > 0) {
              setStateName(slectedState1[0].id);
            }
          }
          setZipText('Zip code');
          setPostalCodeRegex(/^[1-9][0-9]{4}$/);
          setSateList(getStateList(1));
        }
      }
    }
  };

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      email,
      phone,
      countryCode,
      phoneNumberCode,
      contactType,
      customType,
      streetName,
      cityName,
      stateName,
      zipCode,
      enableNotification,
      notificationType,
    });
  };
  const onRadioChange = (e) => {
    setNotificationType(e.target.value);
  };
  const isNotificationEnabled = (value) => {
    setEnableNotification(value);
  };
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add Contact</SpButton>,
      ]}
      title="Add Contact"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Contact Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <SpError>
            {simpleValidator.current.message('contact name', name, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Contact email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('contact email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '15%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                defaultValue={phoneNumberCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '85%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phone, `required|phoneNumValidation:${phoneNumberCode}${phone}`)}
              </SpError>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={contactType}
            onChange={(e) => setContactType(e)}
          >
            <Option key={1} value="CUSTOMER">Customer</Option>
            <Option key={2} value="EMPLOYEE">Employee</Option>
            <Option key={3} value="VENDOR">Vendor</Option>
            <Option key={4} value="OTHER">Other</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('contact Type', contactType, 'required')}
          </SpError>
        </div>
        {contactType && contactType === 'OTHER' && (
          <div className="mb-3">
            <label htmlFor="">
              <SpH5>Custom Type</SpH5>
            </label>
            {customSuggestions.length > 0 ? (
              <>
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    showSearch
                    placeholder="(Optional)"
                    className="w-100 mb-2"
                    value={customType}
                    onChange={(value) => setCustomType(value)}
                    onSearch={(value) => setCustomType(value)} // Handle typing a custom type
                    mode="combobox" // Allows users to input a value not in the list
                    dropdownRender={menu => (
                      <div>
                        <div style={{ maxHeight: '140px', overflowY: customSuggestions.length > 4 ? 'scroll' : 'auto' }}>
                          {menu}
                        </div>
                        {customSuggestions.length > 1 && (
                          <>
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                              <span
                                style={{
                                  flex: 'none',
                                  border: 'none',
                                  background: 'none',
                                  width: '100%',
                                  display: 'block',
                                  cursor: 'pointer',
                                  color: 'red',
                                  padding: '8px',
                                  textAlign: 'center',
                                }}
                                onClick={() => {
                                  deleteAllCustomTypes({ type: 'CONTACT_TYPE' });
                                }}
                              >
                                Delete All
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  >
                    {customSuggestions.map((word, index) => (
                      <Select.Option key={index} value={word}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{word}</span>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteSingleCustomType({ customType: word, type: 'CONTACT_TYPE' });
                            }}
                            // eslint-disable-next-line object-curly-newline
                            style={{ border: 'none', background: 'none', cursor: 'pointer', color: 'red' }}
                          >
                            Delete
                          </button>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : (
              <Input
                placeholder="(Optional)"
                value={customType || ''}
                onChange={(e) => setCustomType(e.target.value)}
              />
            )}
          </div>
        )}
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Address</SpH5></label>
          <div>
            <GooglePlacesAutocomplete
              apiKey={GOOGLE_MAPS_API_KEY}
              selectProps={{
                location,
                onChange: updateBillingAddress,
                defaultValue: streetName,
                placeholder: 'Enter an address',
              }}
              defaultValue={streetName}
              inputStyle={{
                width: '100%',
                height: '32px',
                padding: '4px 11px',
                color: '#272727',
                fontSize: '14px',
                lineHeight: 1.5,
                backgroundColor: '#fff',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
            />
          </div>
          {/* <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          /> */}
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                value={stateName}
                optionFilterProp="children"
                onChange={(e) => setStateName(e)}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipText}</SpH5></label>
              <Input
                placeholder={zipText}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                value={countryName}
                style={{ width: '100%' }}
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div>
          <label>
            <SpH5>Would you like to send funding source link?
              <Switch
                className="mr-2 ml-2"
                checked={enableNotification}
                onChange={isNotificationEnabled}
              />
            </SpH5>
          </label>

          <RadioGroup value={notificationType} onChange={onRadioChange}>
            {NOTIFICATION_TYPES.map((option) => (
              <Radio key={option.id} value={option.value} disabled={!enableNotification}>
                {option.value === 'ALL' ? 'BOTH' : option.value}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  customSuggestions: state.vendors.customSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomTypes: param => dispatch(vendorsActions.fetchCustomTypes({
    payload: param,
  })),
  deleteSingleCustomType: param => dispatch(vendorsActions.deleteSingleCustomType({
    payload: param,
  })),
  deleteAllCustomTypes: param => dispatch(vendorsActions.deleteAllCustomTypes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
