import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  ACCOUNT_SLUG,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAccountSlug = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.size) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG}?size=1000${sorting}`);
  }
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_ACCOUNTSLUG}${query}${sorting}`);
};

const postAccountSlug = async payload => axiosAuthInstance.post(API_END_POINTS.GET_ACCOUNTSLUG, payload);

const postLinkSlug = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_ACCOUNTSLUG}/${payload.slugGid}/link`, payload.params);

const postUnLinkSlug = async gid => axiosAuthInstance.post(`${API_END_POINTS.GET_ACCOUNTSLUG}/${gid}/unlink`);

export function* getAccountSlug(action) {
  try {
    const { data: { entity } } = yield call(fetchAccountSlug, action.payload);
    yield put({ type: ACCOUNT_SLUG.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: ACCOUNT_SLUG.FAILED, payload: e.response.data });
  }
}

export function* addAccountSlug(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postAccountSlug, action.payload);
    const { data: { entity } } = yield call(fetchAccountSlug, action.payload);
    yield put({ type: ACCOUNT_SLUG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNTSLUG.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* linkAccountSlug(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postLinkSlug, action.payload);
    const { data: { entity } } = yield call(fetchAccountSlug, action.payload);
    yield put({ type: ACCOUNT_SLUG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNTSLUG.LINK_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* unLinkAccountSlug(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postUnLinkSlug, action.payload);
    const { data: { entity } } = yield call(fetchAccountSlug, action.payload);
    yield put({ type: ACCOUNT_SLUG.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.ACCOUNTSLUG.UNLINK_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
