// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Row,
  Col,
  Icon,
  Upload,
  message,
  Select,
  Checkbox,
  Switch,
  Radio,
} from 'antd';

import { SketchPicker } from 'react-color';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  MESSAGES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

import formatAmount from 'utils/formatAmount';
import { NOTIFICATION } from 'store/actionTypes';
import {
  notificationActions,
} from 'store/actions';
import {
  validators,
  validationRegex,
  countryCodeValidation,
} from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';

import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpText,
} from 'components/DesignKit';
import {
  SpColor,
  SpCover,
  SpPopover,
  SpWatch,
} from 'components/PaymentWidgetDesignKit';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import vertical from 'imgs/vertical.svg';
import horizantal from 'imgs/horizantal.svg';

const { Option } = Select;

type Props = {
  pageData: Object,
  selectedAccount: Object,
  setNotification: Function,
  isIndia: boolean,
};

const PageDetails = forwardRef((props: Props, ref) => {
  const {
    pageData,
    selectedAccount,
    setNotification,
    isIndia,
  } = props;
  const [, forceUpdate] = useState();
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const currencyType = selectedAccount && selectedAccount.currency;
  const [title, setTitle] = useState(pageData.title);
  const [content, setContent] = useState(pageData.content);
  const [logo, setLogo] = useState(pageData.logo);
  const [preview, setPreview] = useState(pageData.preview);
  const [fileName, setFileName] = useState(pageData.fileName);
  const [loader, setLoading] = useState(false);
  const [termsAndConditions, setTerms] = useState(pageData.termsAndConditions);
  const [contactUsEmail, setEmail] = useState(pageData.contactUsEmail);
  const [contactPhone, setContactPhone] = useState(pageData.contactPhone);
  const [phoneNumberCode, setPhoneCode] = useState(pageData.phoneNumberCode
    ? pageData.phoneNumberCode
    : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [phoneFormate, setPhoneFormate] = useState(pageData.phoneFormate ? pageData.phoneFormate : '');
  const [venueAddress, setAddress] = useState(pageData.venueAddress);
  const [receiptNote, setReceiptNote] = useState(pageData.receiptNote);
  const [coordinate, setCoordinates] = useState(pageData.coordinate);
  const [youtubeUrl, setYoutubeUrl] = useState(pageData.youtubeUrl);
  const [ytVideoHeading, setYoutubeHeading] = useState(pageData.ytVideoHeading);
  const [ytVideoDescription, setYoutubeDescription] = useState(pageData.ytVideoDescription);
  const [location, setLocation] = useState(pageData.location);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [primaryColor, setColorCode] = useState(pageData.primaryColor);
  const [redirectUrl, setRedirectUrl] = useState(pageData.redirectUrl);
  const [paymentHeading, setPaymentHeading] = useState(pageData.paymentHeading);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(pageData.serviceFeeEnabled);
  const [serviceFeeRate, setServiceFeeRate] = useState(pageData.serviceFeeRate ? formatAmount((pageData.serviceFeeRate) / 100, currencyType) : null);
  const [taxPercentage, setTaxPercentage] = useState(pageData.taxPercentage ? formatAmount((pageData.taxPercentage) / 100, currencyType) : null);
  const [serviceFee, setServiceFee] = useState(pageData.serviceFee ? formatAmount((pageData.serviceFee) / 100, currencyType) : null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(pageData.supportOfflinePayment);
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const [imageSupport, setImageSupport] = useState(pageData?.imageSupport ? pageData?.imageSupport : 'VERTICAL');
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      feeAmount: {
        message: 'fee should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      rateAmount: {
        message: 'rate should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      taxAmount: {
        message: 'tax should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) > 0,
      },
      youtubeValidation: {
        message: 'please enter a valid youtube url',
        rule: val => (youtubeRegex.test(val)),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      noEmojiValidation: {
        message: 'Emojis are not allowed in the page description.',
        rule: val => {
          const emojiRegex = /[\uD800-\uDFFF]/;
          return !emojiRegex.test(val);
        },
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const getPlaceDetails = async (place) => {
    const locations = place.value.description;
    setAddress(place.value.description);
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locations)}&key=${GOOGLE_MAPS_API_KEY}`)
      .then(response => response.json())
      .then(data => {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({
          x: lat,
          y: lng,
        });
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    if (location) {
      getPlaceDetails(location);
    }
  }, [location]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (e) => {
    setColorCode(e.hex);
  };

  const updateColor = (e) => {
    setColorCode(e.currentTarget.value);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      title,
      content,
      logo,
      preview,
      fileName,
      contactUsEmail,
      contactPhone,
      phoneFormate,
      phoneNumberCode,
      termsAndConditions,
      primaryColor,
      location,
      coordinate,
      venueAddress,
      receiptNote,
      youtubeUrl,
      ytVideoHeading,
      ytVideoDescription,
      redirectUrl,
      paymentHeading,
      serviceFeeEnabled,
      serviceFeeRate,
      serviceFee,
      taxPercentage,
      supportOfflinePayment,
      imageSupport,
    }),
  }));

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension：.pdf .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE) || file.type.match(PDF);
    if (!pattern) {
      message.error('File is not image or pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const isFeeChange = (value) => {
    const { target: { checked } } = value;
    if (checked === true) {
      setServiceFeeRate('2.90');
      setServiceFee('0.30');
    }
    setServiceFeeEnabled(checked);
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);

      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_DONATIONPAGE}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_DONATIONPAGE}`, { fileUrl: entity });
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'align',
  ];
  return (
    <>
      <div>
        <Row>
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Page Title</SpH5></label>
            <Input
              placeholder="Enter the page title"
              value={title}
              maxlength="100"
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page title', title, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Page Description</SpH5></label>
            <ReactQuill
              theme="snow"
              rows={10}
              formats={formats}
              placeholder="Add the page description you want your customer to see"
              value={content}
              maxlength="9999"
              onChange={setContent}
              className="richTextFormatting"
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 9999 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page description', content, 'required|noEmojiValidation')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Email</SpH5></label>
            <Input
              placeholder="Enter the email"
              value={contactUsEmail}
              maxlength="100"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('email', contactUsEmail, 'required|email')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Phone Number</SpH5></label>
            <div className="d-flex">
              <div
                style={{ width: '20%' }}
                onKeyPress={e => countryCodeValidation(e)}
              >
                <Select
                  className="w-100"
                  showSearch
                  value={phoneNumberCode}
                  onChange={onCountryCodeChange}
                >
                  {COUNTRY_PHONE_CODE.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: '80%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setContactPhone(e.currentTarget.value);
                      const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Add Image</SpH5></label>
            <Upload
              name="avatar"
              listType="picture-card upload-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={fileUpload}
            >
              {fileName ? preview : uploadButton}
            </Upload>
            <SpError>
              {// simpleValidator.current.message('logo', logo, 'required')
              }
            </SpError>
          </Col>
        </Row>
        {/* <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Image Supports</SpH5></label>
            <div className="mb-3">
              <Radio.Group
                onChange={e => setImageSupport(e.target.value)}
                value={imageSupport}
              >
                <Radio value="VERTICAL" className="ml-2">Vertical &nbsp;&nbsp;<img src={vertical} alt="vertical" /></Radio>
                <Radio value="HORIZONTAL">Horizontal &nbsp;&nbsp;<img src={horizantal} alt="vertical" /></Radio>
              </Radio.Group>
            </div>
            <SpText color="#676767">
              Note: Please select your preferred layout thoughtfully, as the page will be displayed according to your choice.
            </SpText>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Primary Color</SpH5></label>
            <div className="d-flex ml-1">
              <div>
                <SpWatch onClick={handleClick}>
                  <SpColor className="color" style={{ backgroundColor: `${primaryColor}` }} />
                </SpWatch>
                {displayColorPicker ? (
                  <>
                    <SpPopover>
                      <SpCover onClick={handleClose} />
                      <SketchPicker color={primaryColor} onChange={handleChange} />
                    </SpPopover>
                  </>
                ) : null}
              </div>
              <Input
                style={{ width: '111px' }}
                className="ml-3"
                value={primaryColor}
                onChange={updateColor}
              />
            </div>
            <SpError>
              {simpleValidator.current.message('color code', primaryColor, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Venue</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <div>
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_MAPS_API_KEY}
                selectProps={{
                  location,
                  onChange: setLocation,
                  defaultValue: location,
                  placeholder: 'Enter an address',
                }}
                defaultValue={location}
                inputStyle={{
                  width: '100%',
                  height: '32px',
                  padding: '4px 11px',
                  color: '#272727',
                  fontSize: '14px',
                  lineHeight: 1.5,
                  backgroundColor: '#fff',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Youtube Url</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter link"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('youtube url', youtubeUrl, 'youtubeValidation')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Video Heading</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter heading"
              value={ytVideoHeading}
              onChange={(e) => setYoutubeHeading(e.currentTarget.value)}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Video Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter description"
              value={ytVideoDescription}
              onChange={(e) => setYoutubeDescription(e.currentTarget.value)}
            />
          </Col>
        </Row>
        {/* <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Payment Heading</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Place Order"
              value={paymentHeading}
              onChange={(e) => setPaymentHeading(e.currentTarget.value)}
            />
          </Col>
        </Row> */}
        {/* <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Redirect Url</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="http://www.example.com"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('redirectUrl', redirectUrl, 'url')}
            </SpError>
          </Col>
        </Row> */}
        {/* <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Receipt Note</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter the receipt note"
              value={receiptNote}
              onChange={(e) => setReceiptNote(e.currentTarget.value)}
            />
          </Col>
        </Row> */}
        {/* {
          !isIndia && (
            <>
              <Row className="mt-4">
                <Col span={16} push={4}>
                  <Checkbox
                    className="mr-2"
                    checked={serviceFeeEnabled}
                    onChange={isFeeChange}
                  />
                  <SpH5>Apply Service Rate & Fees</SpH5>
                </Col>
              </Row>
              {
                serviceFeeEnabled && (
                  <>
                    <Row className="mt-4">
                      <Col span={16} push={4}>
                        <div className="d-flex">
                          <SpH5>Rate</SpH5>
                          <div style={{ width: '20%' }} />
                          <SpH5>Fees</SpH5>
                          <div style={{ width: '20%' }} />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16} push={4}>
                        <div className="d-flex">
                          <div style={{ width: '20%' }}>
                            <Input
                              value={serviceFeeRate}
                              placeholder="0.0"
                              suffix="%"
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setServiceFeeRate(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setServiceFeeRate(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, currencyType);
                                  setServiceFeeRate(value);
                                }
                              }}
                            />
                            {
                              serviceFee ? (
                                <SpError>
                                  {simpleValidator.current.message('rate', serviceFeeRate, 'rateAmount')}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('rate', serviceFeeRate, 'required|rateAmount')}
                                </SpError>
                              )
                            }
                          </div>
                          <div style={{ width: '60%' }} className="ml-4">
                            <div className="d-flex">
                              <div style={{ width: '35%' }}>
                                <Input
                                  placeholder="0.00"
                                  prefix={prefix}
                                  value={serviceFee}
                                  onChange={(e) => {
                                    const regex = /^\d*\.?\d*$/;
                                    const { value } = e.currentTarget;
                                    if (regex.test(value) || value === '') {
                                      setServiceFee(e.currentTarget.value);
                                    } else if (!regex.test(value)) {
                                      setServiceFee(0.00);
                                    }
                                  }}
                                  onKeyPress={e => {
                                    const keyCode = e.charCode || e.which;
                                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={e => {
                                    if (e.currentTarget.value) {
                                      const value = formatAmount(e.currentTarget.value, currencyType);
                                      setServiceFee(value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {
                              serviceFeeRate ? (
                                <SpError>
                                  {simpleValidator.current.message('fee', serviceFee, 'feeAmount')}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('fee', serviceFee, 'required|feeAmount')}
                                </SpError>
                              )
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )
              }
            </>
          )
        } */}
        {/* <Row className="mt-4">
          <Col span={5} push={4}>
            <label htmlFor=""><SpH5>Sales Tax</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              value={taxPercentage}
              placeholder="0.0"
              suffix="%"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setTaxPercentage(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setTaxPercentage(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setTaxPercentage(value);
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('tax', taxPercentage, 'taxAmount')}
            </SpError>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <Col span={16} push={4}>
            <Switch
              defaultChecked={supportOfflinePayment}
              onChange={val => setSupportOfflinePayment(val)}
            /> <SpH5> Support Offline Payment</SpH5>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Terms & Conditions</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter terms and conditions"
              value={termsAndConditions}
              onChange={(e) => setTerms(e.currentTarget.value)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(PageDetails);
