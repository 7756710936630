// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Badge,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import {
  PAGES,
  MESSAGES,
  ROLES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import { NOTIFICATION, FILTERS_SPECS } from 'store/actionTypes';
import ExportIcon from 'components/NavIcons/exportIcon';
import RBAC from 'components/rbac';
import {
  SpButton,
  SpStatusTag,
  SpText,
  FilterWrapper,
} from 'components/DesignKit';

import getPaymentPageBackground from 'utils/getPaymentPageBackground';
import getPaymentPageStatus from 'utils/getPaymentPageStatus';
import Clipboard from 'components/NavIcons/copyclipboard';
import FilterIcon from 'components/NavIcons/filterIcon';
import { notificationActions, donationPagesActions } from 'store/actions';
import moment from 'moment-timezone';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const PAYMENT_PAGES_FILTERS_TYPES = FILTERS_TYPES.PAYMENT_PAGES;
const PaymentStatuses = FILTER_STATUSES.PAYMENT_PAGES;

type Props = {
  fetchDonationPages: Function,
  donationPages: Array<Object>,
  setNotification: Function,
  loading: boolean,
  test: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
};

const DonationPagesList = (props: Props) => {
  const {
    loading,
    fetchDonationPages,
    donationPages,
    setNotification,
    totalElements,
    test,
    history,
    selectedAccount,
  } = props;

  const location = useLocation();
  const [pagination, setPagination] = useState({});
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(PAYMENT_PAGES_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPaymentPages, setfilterPaymentPages] = useState([{
    filterType: '',
    filterName: '',
    filterOperator: '',
    filterValue: '',
    filterNameDisplay: '',
    filterValueFrom: '',
    filterValueTo: '',
    checked: true,
  }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [nameSortOrder, setNameSortOrder] = useState(null);
  const [defaultSortOrder, setDefaultSortOrder] = useState('descend');

  const exportData = donationPages ? donationPages.map((values) => ({
    title: values.title,
    totalSales: values.totalSales,
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
    status: getPaymentPageStatus(values.status),
  })) : '';

  const exportHeaders = [
    { label: 'TITLE', key: 'title' },
    { label: 'TOTAL ONLINE SALES', key: 'totalSales' },
    { label: 'CREATED ON', key: 'createdAt' },
    { label: 'STATUS', key: 'status' },
  ];

  const debouncedFetchDonationPages = debounce(fetchDonationPages, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = parsed.filterParams || '';
    debouncedFetchDonationPages({ currentPage, sortParams, filterParams });
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [test, selectedAccount, location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [donationPages]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addPaymentPage = () => {
    history.push(PAGES.ADDDONATIONPAGE);
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.DONATIONPAGES,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.DONATIONPAGES,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterPaymentPages];
    if (filterPaymentPages.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENT_PAGES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setfilterPaymentPages(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterPaymentPages];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENT_PAGES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setfilterPaymentPages(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPaymentPages];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setfilterPaymentPages(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setfilterPaymentPages(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPaymentPages];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setfilterPaymentPages(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setfilterPaymentPages(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPaymentPages[index].filterName === 'createdAt') {
      if (filterPaymentPages[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPaymentPages[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPaymentPages[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPaymentPages[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPaymentPages];
    list[index].filterValue = selectedValue;
    setfilterPaymentPages(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPaymentPages];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPaymentPages[index].filterValue;
        handleValue(value, index);
      }
    }
    setfilterPaymentPages(list);
  };

  const handleAddClick = () => {
    const list = [...filterPaymentPages];
    setfilterPaymentPages([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setfilterPaymentPages([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(PAYMENT_PAGES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.DONATIONPAGES);
  };

  const applyFilters = () => {
    const paymentPageFilters = filterPaymentPages.filter((item) => item.checked);
    for (let i = 0; i < paymentPageFilters.length; i += 1) {
      if (!paymentPageFilters[i].filterName || !paymentPageFilters[i].filterOperator || !paymentPageFilters[i].filterValue) {
        return;
      }
    }
    if (paymentPageFilters[0].filterValue || (paymentPageFilters[0].filterValueFrom && paymentPageFilters[0].filterValueTo)) {
      setCount(paymentPageFilters && paymentPageFilters.length);
      for (let i = 0; i < paymentPageFilters.length; i += 1) {
        if (paymentPageFilters[i].checked) {
          if (paymentPageFilters[i].filterName === 'createdAt') {
            paymentPageFilters[i] = paymentPageFilters[i].filterValue;
          } else if (paymentPageFilters[i].filterName === 'amount' && paymentPageFilters[i].filterOperator === 'BT') {
            const name = paymentPageFilters[i].filterName;
            const from = paymentPageFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentPageFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentPageFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentPageFilters[i].filterName === 'amount' && paymentPageFilters[i].filterOperator !== 'BT') {
            const value = paymentPageFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentPageFilters[i] = `${paymentPageFilters[i].filterName}.${paymentPageFilters[i].filterOperator}=${value}`;
          } else {
            const name = paymentPageFilters[i].filterName;
            paymentPageFilters[i] = `${name}.${paymentPageFilters[i].filterOperator}=${paymentPageFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentPageFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        history.push({
          pathname: PAGES.DONATIONPAGES,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        history.push({
          pathname: PAGES.DONATIONPAGES,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGES.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const paymentPageGid = record.gid;
    history.push({
      pathname: `${PAGES.DONATIONPAGES}/${paymentPageGid}`,
      state: location.search,
    });
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: nameSortOrder,
      onHeaderCell: () => ({
        onClick: () => {
          const newSortOrder = nameSortOrder === 'ascend' ? 'descend' : nameSortOrder === 'descend' ? 'ascend' : 'ascend';
          setNameSortOrder(newSortOrder);
          setDefaultSortOrder(null);
        },
      }),
      render: (title) => (
        <span style={{ cursor: 'pointer' }}>
          {title || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'TOTAL ONLINE DONATIONS',
      dataIndex: 'totalNoOfDonations',
      width: '20%',
      align: 'center',
      render: (totalNoOfDonations) => (
        <span style={{ cursor: 'pointer' }}>
          {totalNoOfDonations}
        </span>
      ),
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: defaultSortOrder,
      onHeaderCell: () => ({
        onClick: () => {
          const newSortOrder = defaultSortOrder === 'ascend' ? 'descend' : defaultSortOrder === 'descend' ? 'ascend' : 'descend';
          setDefaultSortOrder(newSortOrder);
          setNameSortOrder(null);
        },
      }),
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (
            <span>{tzDate}</span>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <SpStatusTag style={{ backgroundColor: getPaymentPageBackground(status) }}>
          {getPaymentPageStatus(status)}
        </SpStatusTag>
      ),
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'center',
    render: (paymentPageLinks, record) => {
      const recordLink = (record.donationPageLinks).length > 0 ? record.donationPageLinks[0].link : '';
      const { status } = record;
      if (recordLink && status === 'ACTIVE') {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); copyCode(record.donationPageLinks[0].link); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      } if (recordLink && status === 'SOLD_OUT') {
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); copyCode(record.donationPageLinks[0].link); }}
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      }
      return (
        <span>
          <SpButton
            type="secondary"
            borderRadius="50px"
            disabled
          >
            <Clipboard />
          </SpButton>
        </span>
      );
    },
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
            <Col>
              <SpButton
                type="secondary"
                className="mr-3"
                shape="round"
                onClick={() => addPaymentPage()}
                ghost
                style={{
                  cursor: 'pointer',
                }}
              >
                +&nbsp;Add Donation Page
              </SpButton>
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Payment Pages">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Payment Pages Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Payment Pages">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '-76px' }}
                  >
                    <Filters
                      filtersGrid={filterPaymentPages}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                      stuses={PaymentStatuses}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPaymentPages.length < PAYMENT_PAGES_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>

      <Table
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        rowClassName={() => 'ant-table-clickable-row'}
        columns={[...columns, actionColumn]}
        rowKey={(record) => record.gid}
        dataSource={donationPages}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  donationPages: state.donationPages.content,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.donationPages.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDonationPages: param => dispatch(donationPagesActions.fetchDonationPages({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(DonationPagesList);
