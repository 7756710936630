/* eslint-disable */
import React from 'react';

const viewPdfIcon = () => (
<svg width="38" height="50" viewBox="0 0 38 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0H23L38 15V43C38 46.866 34.866 50 31 50H7C3.13401 50 0 46.866 0 43V7Z" fill="#E5252A"/>
<path d="M23 15H38L23 0V15Z" fill="#ED676A"/>
<path d="M16.1426 31.1582C16.1426 32.1152 15.8594 32.8555 15.293 33.3789C14.7305 33.8984 13.9297 34.1582 12.8906 34.1582H12.2402V37H9.92578V28.4336H12.8906C13.9727 28.4336 14.7852 28.6699 15.3281 29.1426C15.8711 29.6152 16.1426 30.2871 16.1426 31.1582ZM12.2402 32.2715H12.6621C13.0098 32.2715 13.2852 32.1738 13.4883 31.9785C13.6953 31.7832 13.7988 31.5137 13.7988 31.1699C13.7988 30.5918 13.4785 30.3027 12.8379 30.3027H12.2402V32.2715ZM24.1793 32.5352C24.1793 33.9648 23.7848 35.0664 22.9957 35.8398C22.2105 36.6133 21.1051 37 19.6793 37H16.9078V28.4336H19.8727C21.2477 28.4336 22.3082 28.7852 23.0543 29.4883C23.8043 30.1914 24.1793 31.207 24.1793 32.5352ZM21.777 32.6172C21.777 31.832 21.6207 31.25 21.3082 30.8711C20.9996 30.4922 20.5289 30.3027 19.8961 30.3027H19.2223V35.1016H19.7379C20.441 35.1016 20.9566 34.8984 21.2848 34.4922C21.6129 34.082 21.777 33.457 21.777 32.6172ZM27.3996 37H25.1203V28.4336H30.1828V30.291H27.3996V31.9258H29.966V33.7832H27.3996V37Z" fill="white"/>
</svg>



);

export default viewPdfIcon;

