// @flow
import React from 'react';
import { Col, Hidden } from 'react-awesome-styled-grid';
// import PaymentPage from '@zetametrics/gateway-secure';
import getPaymentMetaData from 'utils/getPaymentMetaData';
import getPaymentCloneData from 'utils/getPaymentCloneData';
import PaymentPage from 'routes/paymentpage';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import {
  SpWidgetButton,
  SpAmountWidget,
  SpAmountWidgetItem,
  SpMessageText,
  SpTermsAndServices,
} from 'components/PaymentWidgetDesignKit';
import { Input, Checkbox } from 'antd';
import formatAmount from 'utils/formatAmount';
import { h2FontSize, submitFontSize, submitHeight } from '../../../utils/reseponsive';
import SmallScreenView from '../../../components/smallscreen';
import Account from '../../paymentpage/components/Account';
import CardIconList from '../../paymentpage/components/CardIconList';
import ProductDetails from '../../paymentpage/components/ProductDetails';
import PaymentPageSupport from '../../paymentpage/components/PaymentPageSupport';
import { DescriptionText, SubmitButton } from '../../../design';

const { TextArea } = Input;

type Props = {
  pageData: Object,
  account: Object,
  addParent: Array,
  productDetailsArray: Array,
  customerDetailsArray: Array,
  enableTip: Boolean,
  isTipOptional: Boolean,
  firstOption: String,
  secondOption: String,
  thirdOption: String,
  cloneId: String,
  addCustomAmount: Boolean,
  minAmount: Number,
  maxAmount: Number,
  donationPageIntervals: Array<Objects>,
  currencyType: Object,
  amountList: Array<Objects>,
};

const ReviewPaymentPage = (props: Props) => {
  const {
    pageData,
    account,
    addParent,
    productDetailsArray,
    customerDetailsArray,
    enableTip,
    isTipOptional,
    firstOption,
    secondOption,
    thirdOption,
    cloneId,
    addCustomAmount,
    minAmount,
    maxAmount,
    donationPageIntervals,
    currencyType,
    amountList,
  } = props;

  return (
    <>
      <div className="mb-3">
        <div id="payment-page">
          <Col
            md={5}
            sm={8}
            lg={5}
            className="mt-md-5 mb-2 p-4 payment-page-right"
            style={{
              position: 'absolute', marginLeft: '55%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 15px 0px', backgroundColor: '#fff',
            }}
          >
            <SmallScreenView>
              {account
          && (
          <Account
            primaryColor={pageData?.primaryColor}
            isPreview
            isAdaptive
            name={account.name}
            logo={account.largeLogo}
          />
          )}
              {pageData && (
              <ProductDetails
                isPreview
                isAdaptive
                primaryColor={pageData?.primaryColor}
                content={pageData?.content}
                imageUrl={pageData?.logo}
                title={pageData?.title}
                venueAddress={pageData?.venueAddress}
                coordinate={pageData?.coordinate}
                ytVideoId={pageData?.ytVideoId}
                ytVideoDescription={pageData?.ytVideoDescription}
                ytVideoHeading={pageData?.ytVideoHeading}
                imageSupport={pageData?.imageSupport}
              />
              )}
            </SmallScreenView>
            <div className="pt-3 s">
              <DescriptionText fontSize={h2FontSize(true, true)} color="#474747">{pageData?.paymentHeading || 'DONATION AMOUNT'}</DescriptionText>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="payment-page-form-elements py-2 pr-2">
                <div className="mt-3">
                  {
                    donationPageIntervals && donationPageIntervals.length !== 0 && (
                      <div style={{ backgroundColor: '#f2f2f6', padding: '2%', borderRadius: '15px' }}>
                        {
                          donationPageIntervals.map((item) => (
                            <SpText
                              fontSize="12px"
                              className="mr-3"
                              borderRadius={item?.default ? '13px' : '0px'}
                              padding={item?.default ? '4px 14px' : '0px'}
                              color={item?.default ? '#007bff' : '#000'}
                            >
                              {
                                item?.interval === 'ONETIME' && (
                                  <span>One-time</span>
                                )
                              }
                              {
                                item?.interval === 'WEEK' && (
                                  <span>Weekly</span>
                                )
                              }
                              {
                                item?.interval === 'MONTH' && (
                                  <span>Monthly</span>
                                )
                              }
                              {
                                item?.interval === 'YEAR' && (
                                  <span>Yearly</span>
                                )
                              }
                            </SpText>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    addCustomAmount && (
                      <div className="mt-4">
                        <Input type="text" placeholder="0.00" prefix={currencyType?.prefix} suffix={currencyType?.name} />
                      </div>
                    )
                  }
                  <div className="mt-4 mb-4">
                    {
                        amountList.map((item) => (
                          <span
                            className="mr-3 mb-3"
                            style={{
                              minWidth: '25%',
                              maxWidth: '50%',
                              backgroundColor: '#f2f2f6',
                              borderRadius: '10px',
                              padding: '5px',
                              marginBottom: '5px',
                            }}
                          >
                            {currencyType?.prefix} {formatAmount((item?.amount), currencyType)}
                          </span>
                        ))
                      }
                  </div>
                  <div className="mb-4">
                    <SpText fontSize="12px" fontWeight="500">
                      Message to organizer
                    </SpText>
                    <TextArea
                      rows={3}
                      placeholder="Leave message to..."
                    />
                  </div>
                  <div>
                    <SpText fontSize="12px" fontWeight="500">
                      Share your details with this charity
                    </SpText>
                    <div>
                      <span className="mr-2"><Checkbox /> &nbsp; Name</span>
                      <span className="mr-2"><Checkbox /> &nbsp; Email</span>
                      <span className="mr-2"><Checkbox /> &nbsp; Phone</span>
                      <span className="mr-2"><Checkbox /> &nbsp; Address</span>
                    </div>
                  </div>
                </div>
                {
            pageData && (
              <div className="pt-4 text-center">
                <SubmitButton
                  backgroundColor={pageData?.primaryColor ? pageData?.primaryColor : '#40A9FF'}
                  hoverBackgroundColor={pageData?.primaryColor ? pageData?.primaryColor : '#40A9FF'}
                  disabled
                  height={submitHeight(42, true, true)}
                  className="m-0"
                  fontSize={submitFontSize(true, true)}
                  type="submit"
                >
                  Donate Now
                </SubmitButton>
                <CardIconList />
              </div>
            )
          }

              </div>
            </form>
            <SmallScreenView>
              {pageData
          && (
          <PaymentPageSupport
            primaryColor={pageData?.primaryColor}
            email={pageData?.contactUsEmail}
            termsAndCondition={pageData?.termsAndConditions}
            phone={pageData?.contactUsPhone}
            isPreview
            isAdaptive
          />
          )}
            </SmallScreenView>
          </Col>
          <Hidden xs sm>
            <Col
              md={6}
              className="p-4 bg-white"
              style={{
                boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', borderRadius: '10px', minHeight: '100%', height: '100%', overflow: 'hidden auto',
              }}
            >
              <div className="p-3 col-12 col-lg-9" style={{ minHeight: '100%' }}>
                {account
            && (
            <Account
              primaryColor={pageData?.primaryColor}
              isPreview
              isAdaptive
              name={account.name}
              logo={account.largeLogo}
            />
            )}
                {pageData && (
                <ProductDetails
                  isPreview
                  isAdaptive
                  content={pageData?.content}
                  imageUrl={pageData?.logo}
                  title={pageData?.title}
                  primaryColor={pageData?.primaryColor}
                  venueAddress={pageData?.venueAddress}
                  coordinate={pageData?.coordinate}
                  ytVideoId={pageData?.ytVideoId}
                  ytVideoDescription={pageData?.ytVideoDescription}
                  ytVideoHeading={pageData?.ytVideoHeading}
                  imageSupport={pageData?.imageSupport}
                />
                )}
                {pageData && (
                <PaymentPageSupport
                  isPreview
                  isAdaptive
                  email={pageData?.contactUsEmail}
                  phone={pageData?.contactUsPhone}
                  termsAndCondition={pageData?.termsAndConditions}
                  primaryColor={pageData?.primaryColor}
                  agregatorName={account && account.aggregatorName}
                />
                )}
              </div>
            </Col>
          </Hidden>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default ReviewPaymentPage;
