import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';

import type { ContextRouter } from 'react-router-dom';

import {
  notificationActions,
  accountSlugActions,
  paymentPagesDetailsActions,
} from 'store/actions';


import { NOTIFICATION } from 'store/actionTypes';

import {
  MESSAGES,
  PAGES,
  COUNTRY_CODES,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';

import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  message,
  Icon,
  Select, Upload,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';


import CONSTANTS from 'store/constants';
import imageOnError from 'utils/imageOnError';
import Loading from 'components/Loading';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import Previewicon from 'components/NavIcons/previewIcon';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';

import formatPaymentNumber from 'utils/formatNumber';
import formatAmount from 'utils/formatAmount';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';
import getPlaceholder from 'utils/getPaymentPagePlaceholder';

import Uploadicon from 'components/NavIcons/uploadIcon';
import PageDetails from './components/PageDetails';
import AccontSlug from './components/AccontSlug';
import UpdateReviewPaymentPage from './components/UpdateReviewPaymentPage';

const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;

type Props = ContextRouter & {
  selectedAccount: Object,
  updatePaymentPages: Function,
  setNotification:Function,
  fetchPaymentPagesDetails: Function,
  paymentPagesDetails: Object,
  paymentPagesDetailsStatus: string,
  validator: boolean,
  loading: boolean,
  submitting: boolean,
  accountSlug: Array<Object>,
  fetchAccountSlug: Function,
  fetchPaymentPageSlug: Function,
  paymentSlug: Array,
  match: {
    params: Object,
  },
  history: {
    replace: Function,
  },
};

const UpdatePaymentPage = (props: Props) => {
  const {
    selectedAccount,
    history,
    updatePaymentPages,
    setNotification,
    fetchPaymentPagesDetails,
    paymentPagesDetails,
    paymentPagesDetailsStatus,
    validator,
    loading,
    submitting,
    accountSlug,
    fetchAccountSlug,
    fetchPaymentPageSlug,
    paymentSlug,
  } = props;
  const { gid } = props.match.params;
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [paymentPageMetafields, setPaymentPageMetafields] = useState();
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minLimit: {
        message: 'Min limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => val && Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
    },
  }));

  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [logo, setLogo] = useState();
  const [termsAndConditions, setPageTerms] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  const [venueAddress, setAddress] = useState('');
  const [receiptNote, setReceiptNote] = useState('');
  const [coordinate, setCoordinates] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [ytVideoHeading, setYoutubeHeading] = useState('');
  const [ytVideoDescription, setYoutubeDescription] = useState('');
  const [ytVideoId, setytVideoId] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setColorCode] = useState('#007bff');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [paymentHeading, setPaymentHeading] = useState(null);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  const [isPageDataUpdated, setIsPageDataUpdated] = useState(false);
  const [enableTip, setEnableTip] = useState(false);
  const [isTipOptional, setIsTipOptional] = useState(false);
  const [firstOption, setFirstOption] = useState(null);
  const [secondOption, setSecondOption] = useState(null);
  const [thirdOption, setThirdOption] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [location, setLocation] = useState(null);
  const [tipGid, setTipGid] = useState();
  const [tipMetafield, setTipMetafield] = useState([]);
  const [tipChanges, setTipChanges] = useState(false);
  const [showOfflinePayment, setShowOfflinePayment] = useState(false);
  const [addParent, setAddParent] = useState([
    {
      label: null,
      value: null,
      children: [
        {
          label: null,
          value: null,
        },
      ],
    },
  ]);
  const currencyName = (selectedAccount && (selectedAccount.currency) && (selectedAccount.currency.name));
  const {
    name,
    largeLogo,
  } = selectedAccount;
  const currencyType = selectedAccount && selectedAccount.currency;

  const account = { name: name || null, largeLogo: largeLogo || null };
  const [slugGid, setSlugGid] = useState(null);
  const [slugLink, setSlugLink] = useState('');

  useEffect(() => {
    if (paymentSlug && paymentSlug.length > 0) {
      setSlugGid(paymentSlug[0].gid);
      setSlugLink(paymentSlug[0].slugName);
    }
  }, [paymentSlug]);

  const pageData = {
    title,
    content,
    logo,
    preview,
    fileName,
    contactUsEmail,
    contactPhone,
    phoneNumberCode,
    termsAndConditions,
    primaryColor,
    venueAddress,
    receiptNote,
    coordinate,
    redirectUrl,
    paymentHeading,
    ytVideoId,
    youtubeUrl,
    phoneFormate,
    ytVideoHeading,
    ytVideoDescription,
    serviceFeeEnabled,
    serviceFeeRate,
    serviceFee,
    location,
    taxPercentage,
    isPageDataUpdated,
    supportOfflinePayment,
    slugGid,
    slugLink,
  };

  const newSlug = () => {
    const currentPage = 1;
    const params = '&sortBy=createdAt&direction=DESC';
    const size = 1000;
    fetchAccountSlug({ currentPage, params, size });
  };

  simpleValidator.current.purgeFields();
  useEffect(() => {
    fetchPaymentPagesDetails(gid);
    fetchPaymentPageSlug(gid);
    newSlug();
  }, []);

  const [productDetailsArray, setProductDetailsArray] = useState([]);
  const [customerDetailsArray, setCustomerDetailsArray] = useState([]);
  const [loader, setLoading] = useState(false);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const getPlaceDetails = async (place) => {
    if (place) {
      const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${GOOGLE_MAPS_API_KEY}`)
        .then(response => response.json())
        .catch(error => console.error(error));
      // eslint-disable-next-line
      const { place_id } = data.results[0];
      setDefaultLocation({
        label: place,
        value: {
          description: place,
          place_id,
          reference: place_id,
        },
      });
    }
  };

  useEffect(() => {
    setTitle(paymentPagesDetails.title);
    setContent(paymentPagesDetails.content);
    setLogo(paymentPagesDetails.logo);
    setFileName(paymentPagesDetails.logo);
    setEmail(paymentPagesDetails.contactUsEmail);
    const phoneResult = (paymentPagesDetails && (paymentPagesDetails.contactUsPhone))
      ? findPhoneNumbersInText(paymentPagesDetails && paymentPagesDetails.contactUsPhone) : null;
    if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
      setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
      setContactPhone(phoneResult[0].number.nationalNumber);
      const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
      const formater = data.substr(data.indexOf(' ') + 1);
      setPhoneFormate(formater);
    }
    setPreview(
      <img
        src={`${paymentPagesDetails.logo}?time=${new Date().getTime()}`}
        alt=""
        onError={event => imageOnError(event)}
        style={{ width: '100%' }}
      />,
    );
    setPageTerms(paymentPagesDetails.termsAndConditions);
    setColorCode(paymentPagesDetails.primaryColor || '#007bff');
    setAddress(paymentPagesDetails.venueAddress);
    getPlaceDetails(paymentPagesDetails.venueAddress);
    setReceiptNote(paymentPagesDetails.receiptNote);
    setCoordinates(paymentPagesDetails.coordinate);
    setYoutubeUrl(paymentPagesDetails.ytVideoId && `https://www.youtube.com/watch?v=${paymentPagesDetails.ytVideoId}`);
    setYoutubeHeading(paymentPagesDetails.ytVideoHeading);
    setYoutubeDescription(paymentPagesDetails.ytVideoDescription);
    setytVideoId(paymentPagesDetails.ytVideoId);
    setRedirectUrl(paymentPagesDetails.redirectUrl);
    setPaymentHeading(paymentPagesDetails.paymentHeading);
    setServiceFeeEnabled(paymentPagesDetails.serviceFeeEnabled);
    setSupportOfflinePayment(paymentPagesDetails.supportOfflinePayment);
    setShowOfflinePayment(paymentPagesDetails.supportOfflinePayment);
    setServiceFeeRate(paymentPagesDetails.serviceFeeRate
      ? ((paymentPagesDetails.serviceFeeRate)).toFixed(2) : null);
    setServiceFee(paymentPagesDetails.serviceFee
      ? ((paymentPagesDetails.serviceFee).toFixed(2)) : null);
    setTaxPercentage(paymentPagesDetails.taxPercentage
      ? (paymentPagesDetails.taxPercentage).toFixed(2) : null);
    const metafields = paymentPagesDetails.paymentPageMetaFields ? ((paymentPagesDetails.paymentPageMetaFields)) : '';
    setPaymentPageMetafields(JSON.parse(JSON.stringify(metafields)));
    const tipDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type === 'TIP') : '';
    if (tipDetails && tipDetails.length > 0) {
      setTipMetafield(tipDetails);
      setEnableTip(true);
      setTipGid(tipDetails[0].gid);
      setIsTipOptional((tipDetails[0].isRequired));
      setFirstOption((tipDetails[0].tipPercentages[0] / 100).toFixed(2));
      setSecondOption((tipDetails[0].tipPercentages[1] / 100).toFixed(2));
      setThirdOption((tipDetails[0].tipPercentages[2] / 100).toFixed(2));
    }
    // eslint-disable-next-line
    const productDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type === 'PRICE_AMOUNT_FIXED' || res.type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' || res.type === 'PRICE_AMOUNT_QUANTITY_BASED' || res.type === 'COMBO_BOX') : '';
    // eslint-disable-next-line
    const customerDetails = paymentPagesDetails.paymentPageMetaFields ? paymentPagesDetails.paymentPageMetaFields.filter(res => res.type !== 'PRICE_AMOUNT_FIXED' && res.type !== 'PRICE_AMOUNT_CUSTOMER_DECIDED' && res.type !== 'PRICE_AMOUNT_QUANTITY_BASED' && res.type !== 'COMBO_BOX' && res.type !== 'TIP') : '';
    const productResult = productDetails ? productDetails.map((result) => {
      let currencyName1;
      if (result.currency) {
        currencyName1 = result.currency.name;
      }
      let isRequired;
      if (result.isRequired) {
        isRequired = 'checked';
      } else {
        isRequired = '';
      }
      let enableCombo = false;
      if ((result.comboBox) && (result.comboBox.length > 0)) {
        enableCombo = true;
      }
      // eslint-disable-next-line
      let temp = Object.assign({ "currencyCode": currencyName1, "checked": isRequired, "enableCombo": enableCombo, }, result);
      if (temp.amount) {
        const total = (temp.amount / 100).toFixed(2);
        temp.amount = formatPaymentNumber(total, currencyType);
      }
      if (temp.minPrice) {
        const min = (temp.minPrice / 100).toFixed(2);
        temp.minPrice = formatPaymentNumber(min, currencyType);
      }
      if (temp.maxPrice) {
        const max = (temp.maxPrice / 100).toFixed(2);
        temp.maxPrice = formatPaymentNumber(max, currencyType);
      }
      if (temp.minPrice === '0.00') {
        temp.minPrice = Number(temp.minPrice);
      }
      if (temp.comboBox) {
        setAddParent(temp.comboBox);
      }
      // eslint-disable-next-line
      delete temp.currency;
      return temp;
    }) : '';
    setProductDetailsArray(productResult);
    console.log('check');
    console.log(customerDetails);
    setCustomerDetailsArray(customerDetails);
  }, [paymentPagesDetailsStatus]);


  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileUpload = async (info, j) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      const keyType = 'WAIVER_DOCUMENT';
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_PAYMENTPAGE_WAIVER}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        console.log('77');
        console.log(entity);
        const abc = entity + keyType;
        console.log(abc);
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_PAYMENTPAGE_WAIVER}`, { fileUrl: entity, keyType });
        // setFileupload(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);

        // setFileName(info.file.name);

        const list = [...customerDetailsArray];

        list[j].documentUrl = fileUrl;

        setCustomerDetailsArray(list);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };


  const YouTubeGetID = (url) => {
    const url1 = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // eslint-disable-next-line no-useless-escape
    return (url1[2] !== undefined) ? url1[2].split(/[^0-9a-z_\-]/i)[0] : url1[0];
  };

  const addNewParent = (event) => {
    event.preventDefault();
    setAddParent([
      ...addParent,
      {
        label: null,
        value: null,
        children: [
          {
            label: null,
            value: null,
          },
        ],
      },
    ]);
  };

  const addNewChild = (event, i) => {
    event.preventDefault();
    const list = [...addParent];
    // eslint-disable-next-line
    const info = list[i].children.push({
      label: null,
      value: null,
    });
    setAddParent(list);
  };

  const onRemoveParent = (i) => {
    const list = [...addParent];
    list.splice(i, 1);
    setAddParent(list);
  };

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension：.pdf .doc .docx</SpText></div>
    </div>
  );

  const onRemoveChild = (q, r) => {
    const list = [...addParent];
    list[q].children.splice(r, 1);
    setAddParent(list);
  };

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.PAYMENTPAGES);
    }
  }, [validator]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Page Details',
    },
    {
      title: 'Product Details',
    },
    {
      title: 'Customer Details',
    },
    {
      title: 'Account Slug',
    },
    {
      title: 'Review',
    },
  ];

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setTitle(valuesArray[0].title);
      setContent(valuesArray[0].content);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setPageTerms(valuesArray[0].termsAndConditions ? valuesArray[0].termsAndConditions : null);
      setAddress(valuesArray[0].venueAddress ? valuesArray[0].venueAddress : null);
      setReceiptNote(valuesArray[0].receiptNote ? valuesArray[0].receiptNote : null);
      setCoordinates(valuesArray[0].coordinate ? valuesArray[0].coordinate : null);
      setYoutubeUrl(valuesArray[0].youtubeUrl ? valuesArray[0].youtubeUrl : null);
      setYoutubeHeading(valuesArray[0].ytVideoHeading ? valuesArray[0].ytVideoHeading : null);
      setRedirectUrl(valuesArray[0].redirectUrl ? valuesArray[0].redirectUrl : null);
      setPaymentHeading(valuesArray[0].paymentHeading ? valuesArray[0].paymentHeading : null);
      setLocation(valuesArray[0].location ? valuesArray[0].location : null);
      setColorCode(valuesArray[0].primaryColor);
      setYoutubeDescription(valuesArray[0].ytVideoDescription ? valuesArray[0].ytVideoDescription : null);
      setServiceFeeEnabled(valuesArray[0].serviceFeeEnabled);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      console.log('1234', validationArray);
      console.log(validationArray);
      if (valuesArray[0].serviceFeeRate) {
        const feerate = Number((valuesArray[0].serviceFeeRate).replace(/[^\d]/g, ''));
        setServiceFeeRate(feerate);
      }
      if (valuesArray[0].serviceFee) {
        const fee = Number((valuesArray[0].serviceFee).replace(/[. ,:-]+/g, ''));
        setServiceFee(fee);
      }
      if (valuesArray[0].taxPercentage) {
        const taxrate = Number((valuesArray[0].taxPercentage).replace(/[^\d]/g, ''));
        setTaxPercentage(taxrate);
      } else {
        setTaxPercentage(null);
      }
      let ytCode;
      if (valuesArray[0].youtubeUrl) {
        ytCode = YouTubeGetID(valuesArray[0].youtubeUrl);
      }
      setytVideoId(ytCode || null);
      setIsPageDataUpdated(valuesArray[0].isPageDataUpdated);
      if (valuesArray[0].isPageDataUpdated) {
        setDisableButton(false);
      }
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      let tipData;
      if (enableTip) {
        tipData = [
          {
            currencyCode: currencyName || null,
            type: 'TIP',
            label: 'Tip',
            isRequired: isTipOptional,
            gid: tipGid,
            tipPercentages: [
              Number((firstOption).replace(/[^\d]/g, '')),
              Number((secondOption).replace(/[^\d]/g, '')),
              Number((thirdOption).replace(/[^\d]/g, '')),
            ],
          },
        ];
      }
      const paymentMetaFields = [...productDetailsArray, ...customerDetailsArray];
      // alert(JSON.stringify(customerDetailsArray))
      // console.log("customerDetailsArray")
      // console.log(customerDetailsArray)
      const filterPaymentMetaFields = paymentMetaFields.map(obj => ({
        type: obj.type,
        label: obj.label,
        gid: obj.gid,
        description: obj.description,
        documentUrl: obj.documentUrl ? obj.documentUrl : '',
        isRequired: obj.isRequired,
        currencyCode: obj.currencyCode || null,
        comboBox: obj.type === 'COMBO_BOX' ? addParent : (obj.comboBox || null),
        quantityMinLimit: obj.quantityMinLimit ? Number(obj.quantityMinLimit) : (obj.quantityMinLimit === '') ? null : null,
        quantityMaxLimit: obj.quantityMaxLimit ? Number(obj.quantityMaxLimit) : (obj.quantityMaxLimit === '') ? null : null,
        minPrice: (obj.minPrice === '0.00') ? Number(obj.minPrice) : (obj.minPrice) ? Number((obj.minPrice).replace(/[. ,:-]+/g, '')) : null,
        maxPrice: obj.maxPrice ? Number((obj.maxPrice).replace(/[. ,:-]+/g, '')) : (obj.maxPrice === '') ? null : null,
      }));
      const params = filterPaymentMetaFields.filter(obj1 => {
        const match = paymentPageMetafields.find(obj2 => (obj2.label === obj1.label) && (obj2.isRequired === obj1.isRequired)
          && (obj2.description === obj1.description) && (obj2.documentURL === obj1.documentURL)
          && (obj2.minPrice === obj1.minPrice) && (obj2.maxPrice === obj1.maxPrice) && (obj2.comboBox === obj1.comboBox)
          && (obj2.quantityMinLimit === obj1.quantityMinLimit) && (obj2.quantityMaxLimit === obj1.quantityMaxLimit));
        return !match;
      });
      if ((isPageDataUpdated) || (params.length > 0)) {
        setDisableButton(false);
      } else if (enableTip) {
        if ((JSON.stringify(tipMetafield[0].tipPercentages) !== JSON.stringify(tipData[0].tipPercentages))
          || (tipMetafield[0].isRequired !== tipData[0].isRequired)) {
          setTipChanges(true);
          setDisableButton(false);
        }
      }
      if (enableTip) {
        if ((JSON.stringify(tipMetafield[0].tipPercentages) !== JSON.stringify(tipData[0].tipPercentages))
          || (tipMetafield[0].isRequired !== tipData[0].isRequired)) {
          setTipChanges(true);
        }
      }
      setCurrent(current + 1);
    } else if (current === 3) {
      valuesArray.push(refArray[0].current.getValues());
      setSlugGid(valuesArray[0].slugGid);
      setSlugLink(valuesArray[0].slugLink);
      setCurrent(current + 1);
    } else if (current === 4) {
      // setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      const contactList = { contactUsPhone: contactNumber, status: 'ACTIVE' };
      if (serviceFeeEnabled && (serviceFeeRate === null)) {
        pageData.serviceFeeRate = 0;
      }
      if (serviceFeeEnabled && (serviceFee === null)) {
        pageData.serviceFee = 0;
      }
      const { preview: _, ...noPreviewData } = pageData;
      const fieldInfo = Object.assign(noPreviewData, contactList);
      let tipData;
      if (enableTip) {
        tipData = [
          {
            currencyCode: currencyName || null,
            type: 'TIP',
            label: 'Tip',
            isRequired: isTipOptional,
            gid: tipGid,
            tipPercentages: [
              Number((firstOption).replace(/[^\d]/g, '')),
              Number((secondOption).replace(/[^\d]/g, '')),
              Number((thirdOption).replace(/[^\d]/g, '')),
            ],
          },
        ];
      }
      const paymentMetaFields = [...productDetailsArray, ...customerDetailsArray];
      const filterPaymentMetaFields = paymentMetaFields.map(obj => ({
        type: obj.type,
        label: obj.label,
        gid: obj.gid,
        description: obj.description,
        isRequired: obj.isRequired,
        currencyCode: obj.currencyCode || null,
        documentUrl: obj.documentUrl ? obj.documentUrl : '',
        comboBox: obj.type === 'COMBO_BOX' ? addParent : (obj.comboBox || null),
        quantityMinLimit: obj.quantityMinLimit ? Number(obj.quantityMinLimit) : (obj.quantityMinLimit === '') ? null : null,
        quantityMaxLimit: obj.quantityMaxLimit ? Number(obj.quantityMaxLimit) : (obj.quantityMaxLimit === '') ? null : null,
        minPrice: (obj.minPrice === '0.00') ? Number(obj.minPrice) : (obj.minPrice) ? Number((obj.minPrice).replace(/[. ,:-]+/g, '')) : null,
        maxPrice: obj.maxPrice ? Number((obj.maxPrice).replace(/[. ,:-]+/g, '')) : (obj.maxPrice === '') ? null : null,
      }));
      const param = filterPaymentMetaFields.filter(obj1 => {
        const match = paymentPageMetafields.find(obj2 => (obj2.label === obj1.label) && (obj2.isRequired === obj1.isRequired)
          && (obj2.description === obj1.description)
          && (obj2.minPrice === obj1.minPrice) && (obj2.maxPrice === obj1.maxPrice) && (obj2.comboBox === obj1.comboBox)
          && (obj2.quantityMinLimit === obj1.quantityMinLimit) && (obj2.quantityMaxLimit === obj1.quantityMaxLimit));
        return !match;
      });
      let params;
      if (tipChanges === true) {
        params = [...param, ...tipData];
      } else {
        params = param;
      }
      params.accountSlugGid = slugGid;
      console.log('submit');
      console.log(params);
      updatePaymentPages({
        gid,
        isPageDataUpdated,
        fieldInfo,
        params,
      });
      setRedirect(true);
    }
  };

  const withoutSlug = () => {
    next();
  };


  const beforeUpload = (file) => {
    // const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const DOCS = 'Doc/*';
    const pattern = file.type.match(DOCS) || file.type.match(PDF);
    if (!pattern) {
      message.error('File is not Doc or pdf');
      return false;
    }

    return true;
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setCurrent(current - 1);
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentPagesDetails.gid && paymentPagesDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Edit Payment Page
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(`${PAGES.PAYMENTPAGES}/${gid}`)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      showOfflinePayment={showOfflinePayment}
                      defaultLocation={defaultLocation}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      {
                        productDetailsArray.map((item, i) => (
                          <div>
                            <Row>
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                <div>
                                  <Select
                                    style={{ width: '50%' }}
                                    value={item.type}
                                    disabled
                                    onChange={(e) => {
                                      const list = [...productDetailsArray];
                                      list[i].type = e;
                                      setProductDetailsArray(list);
                                    }}
                                  >
                                    <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                    <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                    <Option key={1} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                  </Select>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                <Input
                                  placeholder="Enter the field label"
                                  value={item.label}
                                  maxlength="100"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].label = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                <SpError>
                                  {simpleValidator.current.message(`type${i + 1}`, productDetailsArray[i].label, 'required',
                                    { messages: { required: 'The label field is required' } })}
                                </SpError>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Product Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                <TextArea
                                  rows={5}
                                  placeholder="Add the page description you want your customer to see"
                                  value={item.description}
                                  maxlength="300"
                                  onChange={(e) => {
                                    const list = [...productDetailsArray];
                                    list[i].description = e.currentTarget.value;
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                              </Col>
                            </Row>
                            {
                              productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                                    <div className="d-flex">
                                      <div style={{ width: '15%' }}>
                                        <Select
                                          className="w-100"
                                          disabled
                                          defaultValue={item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            if (value === 1) {
                                              list[i].currencyCode = 'USD';
                                            } else if (value === 2) {
                                              list[i].currencyCode = 'INR';
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {COUNTRY_CODES.map((id) => (
                                            <Option key={id.ID} value={id.ID}>
                                              {id.CURRENCY}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '35%' }}>
                                        <Input
                                          placeholder="0.00"
                                          value={item.amount}
                                          disabled
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const amount = value.replace(/\s/g, '');
                                            if (regex.test(amount) || amount === '') {
                                              list[i].amount = amount;
                                            } else if (!regex.test(amount)) {
                                              list[i].amount = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required',
                                            { messages: { required: 'The amount field is required' } })}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_CUSTOMER_DECIDED' ? (
                                <Row className="mt-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Amount Limits</SpH5></label>
                                    <div className="d-flex">
                                      <SpH5 className="mt-2 mr-2">Min</SpH5>
                                      <div style={{ width: '15%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currency ? item.currency.name : item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            if (value === 1) {
                                              list[i].currencyCode = 'USD';
                                            } else if (value === 2) {
                                              list[i].currencyCode = 'INR';
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {COUNTRY_CODES.map((id) => (
                                            <Option key={id.ID} value={id.ID}>
                                              {id.CURRENCY}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.minPrice}
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].minPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].minPrice = 0.00;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].minPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {/* eslint-disable-next-line max-len */}
                                          {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].minPrice, 'required|amount|minAmount',
                                            { messages: { required: 'The min amount field is required' } })}
                                        </SpError>
                                      </div>
                                      <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                      <div style={{ width: '15%' }}>
                                        <Select
                                          className="w-100"
                                          defaultValue={item.currency ? item.currency.name : item.currencyCode}
                                          onChange={(value) => {
                                            const list = [...productDetailsArray];
                                            if (value === 1) {
                                              list[i].currencyCode = 'USD';
                                            } else if (value === 2) {
                                              list[i].currencyCode = 'INR';
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                        >
                                          {COUNTRY_CODES.map((id) => (
                                            <Option key={id.ID} value={id.ID}>
                                              {id.CURRENCY}
                                            </Option>
                                          ))}
                                        </Select>
                                      </div>
                                      <div style={{ width: '17%' }}>
                                        <Input
                                          value={item.maxPrice}
                                          placeholder="No Limit"
                                          onChange={(e) => {
                                            const list = [...productDetailsArray];
                                            const regex = /^\d*\.?\d*$/;
                                            const { value } = e.currentTarget;
                                            const price = value.replace(/\s/g, '');
                                            if (regex.test(price) || price === '') {
                                              list[i].maxPrice = price;
                                            } else if (!regex.test(price)) {
                                              list[i].maxPrice = null;
                                            }
                                            setProductDetailsArray(list);
                                          }}
                                          onKeyPress={e => {
                                            const keyCode = e.charCode || e.which;
                                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onBlur={e => {
                                            if (e.currentTarget.value) {
                                              const list = [...productDetailsArray];
                                              list[i].maxPrice = formatAmount(e.currentTarget.value, currencyType);
                                              setProductDetailsArray(list);
                                            }
                                          }}
                                        />
                                        <SpError>
                                          {simpleValidator.current.message(`maxAmount${i + 1}`,
                                            productDetailsArray[i].maxPrice, `minAmount|maxAmount: ${productDetailsArray[i].minPrice}`)}
                                        </SpError>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' ? (
                                <>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Amount</SpH5></label>
                                      <div className="d-flex">
                                        <div style={{ width: '15%' }}>
                                          <Select
                                            className="w-100"
                                            disabled
                                            defaultValue={item.currency ? item.currency.name : item.currencyCode}
                                            onChange={(value) => {
                                              const list = [...productDetailsArray];
                                              if (value === 1) {
                                                list[i].currencyCode = 'USD';
                                              } else if (value === 2) {
                                                list[i].currencyCode = 'INR';
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                          >
                                            {COUNTRY_CODES.map((id) => (
                                              <Option key={id.ID} value={id.ID}>
                                                {id.CURRENCY}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div style={{ width: '35%' }}>
                                          <Input
                                            placeholder="0.00"
                                            disabled
                                            value={item.amount}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^\d*\.?\d*$/;
                                              const { value } = e.currentTarget;
                                              const amount = value.replace(/\s/g, '');
                                              if (regex.test(amount) || amount === '') {
                                                list[i].amount = amount;
                                              } else if (!regex.test(amount)) {
                                                list[i].amount = 0.00;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onBlur={e => {
                                              if (e.currentTarget.value) {
                                                const list = [...productDetailsArray];
                                                list[i].amount = formatAmount(e.currentTarget.value, currencyType);
                                                setProductDetailsArray(list);
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].amount, 'required',
                                              { messages: { required: 'The amount field is required' } })}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Product Quantity Limits</SpH5></label>
                                      <div className="d-flex">
                                        <SpH5 className="mt-2 mr-2">Min</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMinLimit}
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMinLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMinLimit = 1;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {/* eslint-disable-next-line max-len */}
                                            {simpleValidator.current.message(`min limit${i + 1}`, productDetailsArray[i].quantityMinLimit, productDetailsArray[i].isRequired ? 'required|minLimit' : 'required',
                                              { messages: { required: 'The min limit field is required' } })}
                                          </SpError>
                                        </div>
                                        <SpH5 className="ml-3 mt-2 mr-2">Max</SpH5>
                                        <div style={{ width: '17%' }}>
                                          <Input
                                            value={item.quantityMaxLimit}
                                            placeholder="No Limit"
                                            onChange={(e) => {
                                              const list = [...productDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].quantityMaxLimit = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].quantityMaxLimit = null;
                                              }
                                              setProductDetailsArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`max limit${i + 1}`,
                                              // eslint-disable-next-line max-len
                                              productDetailsArray[i].quantityMaxLimit, `minValidAmount|maxAmount: ${productDetailsArray[i].quantityMinLimit}`)}
                                          </SpError>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : productDetailsArray[i].type === 'COMBO_BOX' ? (
                                <>
                                  {addParent.map((items, q) => (
                                    <>
                                      <Row className="mt-4">
                                        <Col span={8} push={4}>
                                          <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                          <Input
                                            value={items.label}
                                            onChange={(e) => {
                                              const list = [...addParent];
                                              list[q].label = e.currentTarget.value;
                                              list[q].value = e.currentTarget.value;
                                              setAddParent(list);
                                            }}
                                            key={`Parent${q}`}
                                            placeholder="Enter parent value"
                                          />
                                          <SpError className="mb-4">
                                            {simpleValidator.current.message('parent', items.label, 'required')}
                                          </SpError>
                                          <Row className="mt-1">
                                            {
                                              q > 0 ? (
                                                <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={10}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParent}
                                                  >
                                                    Add Parent &nbsp;
                                                  </SpText>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={() => onRemoveParent(q)}
                                                  >
                                                    &nbsp; Remove
                                                  </SpText>
                                                </Col>
                                              ) : (
                                                <Col span={8} push={16}>
                                                  <SpText
                                                    fontWeight="600"
                                                    color="#279dfe"
                                                    onClick={addNewParent}
                                                    style={{ cursor: 'pointer' }}
                                                  >
                                                    Add Parent
                                                  </SpText>
                                                </Col>
                                              )
                                            }
                                          </Row>
                                        </Col>
                                        <Col span={8} push={4}>
                                          {items.children.map((child, r) => (
                                            <>
                                              <Row className="ml-4">
                                                <Col>
                                                  <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                  <Input
                                                    value={child.label}
                                                    key={`Child${r}`}
                                                    onChange={(e) => {
                                                      const list = [...addParent];
                                                      list[q].children[r].label = e.currentTarget.value;
                                                      list[q].children[r].value = e.currentTarget.value;
                                                      setAddParent(list);
                                                    }}

                                                    placeholder="Enter child value"
                                                  />
                                                  <SpError className="mb-4">
                                                    {simpleValidator.current.message('child', child.label, 'required')}
                                                  </SpError>
                                                </Col>
                                              </Row>
                                              <Row className="mt-1">
                                                {
                                                  r > 0 ? (
                                                    <Col style={{ cursor: 'pointer', width: 'auto' }} type="flex" span={8} push={11}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChild(event, q, r)}
                                                      >
                                                        Add Child &nbsp;
                                                      </SpText>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={() => onRemoveChild(q, r)}
                                                      >
                                                        &nbsp; Remove
                                                      </SpText>
                                                    </Col>
                                                  ) : (
                                                    <Col span={8} push={18}>
                                                      <SpText
                                                        fontWeight="600"
                                                        color="#279dfe"
                                                        onClick={(event) => addNewChild(event, q, r)}
                                                        style={{ cursor: 'pointer' }}
                                                      >
                                                        Add Child
                                                      </SpText>
                                                    </Col>
                                                  )
                                                }
                                              </Row>
                                            </>
                                          ))}
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                </>
                              ) : ''
                            }
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <Checkbox
                                  className="mr-2"
                                  checked={(item.enableCombo)}
                                  disabled
                                  onChange={({ target: { checked } }) => {
                                    const list = [...productDetailsArray];
                                    list[i].enableCombo = checked;
                                    if (checked) {
                                      list[i].enableCombo = 'checked';
                                    } else if (!checked) {
                                      list[i].enableCombo = '';
                                    }
                                    setProductDetailsArray(list);
                                  }}
                                />
                                <SpH5>Enable Combo Box</SpH5>
                              </Col>
                            </Row>
                            {
                              item.enableCombo && (
                                <>
                                  {item.comboBox.map((items, q) => (
                                    <>
                                      {
                                        productDetailsArray[i].type === 'PRICE_AMOUNT_FIXED' && (
                                          <Row className="mt-4">
                                            <Col span={8} push={4}>
                                              <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                              <Input
                                                value={items.label}
                                                disabled
                                                onChange={(e) => {
                                                  const list = [...productDetailsArray];
                                                  list[i].comboBox[q].label = e.currentTarget.value;
                                                  list[i].comboBox[q].value = e.currentTarget.value;
                                                  setProductDetailsArray(list);
                                                }}
                                                key={`Parent${q}`}
                                                placeholder="Enter parent value"
                                              />
                                              <SpError className="mb-4">
                                                {simpleValidator.current.message('parent', items.label, 'required')}
                                              </SpError>
                                            </Col>
                                            <Col span={8} push={4}>
                                              {items.children.map((child, r) => (
                                                <>
                                                  <Row className="ml-4">
                                                    <Col>
                                                      <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                      <Input
                                                        value={child.label}
                                                        disabled
                                                        key={`Child${r}`}
                                                        onChange={(e) => {
                                                          const list = [...productDetailsArray];
                                                          list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                          list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                          setProductDetailsArray(list);
                                                        }}

                                                        placeholder="Enter child value"
                                                      />
                                                    </Col>
                                                  </Row>
                                                </>
                                              ))}
                                            </Col>
                                          </Row>
                                        )
                                      }
                                      {
                                        productDetailsArray[i].type === 'PRICE_AMOUNT_QUANTITY_BASED' && (
                                          <Row className="mt-4">
                                            <Col span={6} push={4}>
                                              <label htmlFor=""><SpH5>{`Parent ${q + 1}`}</SpH5></label>
                                              <Input
                                                value={items.label}
                                                disabled
                                                onChange={(e) => {
                                                  const list = [...productDetailsArray];
                                                  list[i].comboBox[q].label = e.currentTarget.value;
                                                  list[i].comboBox[q].value = e.currentTarget.value;
                                                  setProductDetailsArray(list);
                                                }}
                                                key={`Parent${q}`}
                                                placeholder="Enter parent value"
                                              />
                                            </Col>
                                            <Col span={14} push={4}>
                                              {items.children.map((child, r) => (
                                                <>
                                                  <Row className="ml-2">
                                                    <Col span={7}>
                                                      <label htmlFor=""><SpH5>{`Child ${r + 1}`}</SpH5></label>
                                                      <Input
                                                        value={child.label}
                                                        key={`Child${r}`}
                                                        disabled
                                                        onChange={(e) => {
                                                          const list = [...productDetailsArray];
                                                          list[i].comboBox[q].children[r].label = e.currentTarget.value;
                                                          list[i].comboBox[q].children[r].value = e.currentTarget.value;
                                                          setProductDetailsArray(list);
                                                        }}

                                                        placeholder="Enter child"
                                                      />
                                                    </Col>
                                                    <Col span={7} className="ml-2">
                                                      <label htmlFor=""><SpH5>{`Price ${r + 1}`}</SpH5></label>
                                                      <Input
                                                        value={child.additionalPrice}
                                                        key={`Price${r}`}
                                                        disabled
                                                        onChange={(e) => {
                                                          const list = [...productDetailsArray];
                                                          const regex = /^\d*\.?\d*$/;
                                                          const { value } = e.currentTarget;
                                                          const amount = value.replace(/\s/g, '');
                                                          if (regex.test(amount) || amount === '') {
                                                            list[i].comboBox[q].children[r].additionalPrice = amount;
                                                          } else if (!regex.test(amount)) {
                                                            list[i].comboBox[q].children[r].additionalPrice = 0.00;
                                                          }
                                                          setProductDetailsArray(list);
                                                        }}
                                                        onKeyPress={e => {
                                                          const keyCode = e.charCode || e.which;
                                                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                            e.preventDefault();
                                                          }
                                                        }}
                                                        onBlur={e => {
                                                          if (e.currentTarget.value) {
                                                            const list = [...productDetailsArray];
                                                            /* eslint-disable-next-line max-len */
                                                            list[i].comboBox[q].children[r].additionalPrice = formatAmount(e.currentTarget.value, currencyType);
                                                            setProductDetailsArray(list);
                                                          }
                                                        }}
                                                        placeholder="Enter price"
                                                      />
                                                    </Col>
                                                  </Row>
                                                </>

                                              ))}
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    </>
                                  ))}
                                  <Row className="mt-4 mb-4">
                                    <Col span={16} push={4}>
                                      <label htmlFor=""><SpH5>Is ComboBox Required</SpH5></label>
                                      <Checkbox
                                        className="ml-4"
                                        disabled
                                        checked={(item.isComboBoxRequired)}
                                        onChange={({ target: { checked } }) => {
                                          const list = [...productDetailsArray];
                                          list[i].isComboBoxRequired = checked;
                                          if (checked) {
                                            list[i].isComboBoxRequired = true;
                                          } else if (!checked) {
                                            list[i].isComboBoxRequired = false;
                                          }
                                          setProductDetailsArray(list);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )
                            }
                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!item.isRequired}
                                  onChange={({ target: { checked } }) => {
                                    const list = [...productDetailsArray];
                                    list[i].isRequired = !checked;
                                    if (checked) {
                                      list[i].checked = '';
                                    } else if (!checked) {
                                      list[i].checked = 'checked';
                                    }
                                    setProductDetailsArray(list);
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))
                      }
                      <Row className="mt-4">
                        <Col span={16} push={4}>
                          <Checkbox
                            className="mr-2"
                            checked={enableTip}
                            disabled
                            onChange={({ target: { checked } }) => {
                              setEnableTip(checked);
                            }}
                          />
                          <SpH5>Enable Tip</SpH5>
                        </Col>
                      </Row>
                      {
                        enableTip && (
                          <>
                            <Row className="mt-4">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <SpH5>1st Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>2nd Option</SpH5>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <SpH5>3rd Option</SpH5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-1">
                              <Col span={16} push={4}>
                                <div className="d-flex">
                                  <div style={{ width: '25%' }}>
                                    <Input
                                      value={firstOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setFirstOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setFirstOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setFirstOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message('1st option', firstOption, 'required|tipAmount')}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={secondOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setSecondOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setSecondOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setSecondOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message(
                                        '2nd option', secondOption, `required|tipAmount|tipValidation1:${firstOption}`,
                                      )}
                                    </SpError>
                                  </div>
                                  <div style={{ width: '25%' }} className="ml-4">
                                    <Input
                                      value={thirdOption}
                                      placeholder="0.0 %"
                                      suffix="%"
                                      onChange={(e) => {
                                        const regex = /^[0-9]+$/;
                                        const { value } = e.currentTarget;
                                        if (regex.test(value) || value === '') {
                                          setThirdOption(e.currentTarget.value);
                                        } else if (!regex.test(value)) {
                                          setThirdOption(0.00);
                                        }
                                      }}
                                      onKeyPress={e => {
                                        const keyCode = e.charCode || e.which;
                                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={e => {
                                        if (e.currentTarget.value) {
                                          const value = formatAmount(e.currentTarget.value, currencyType);
                                          setThirdOption(value);
                                        }
                                      }}
                                    />
                                    <SpError>
                                      {simpleValidator.current.message(
                                        '3rd option', thirdOption, `required|tipAmount|tipValidation:${firstOption}|tipValidation1:${secondOption}`,
                                      )}
                                    </SpError>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                <Checkbox
                                  className="ml-4"
                                  checked={!isTipOptional}
                                  onChange={({ target: { checked } }) => {
                                    const ischeck = !checked;
                                    setIsTipOptional(ischeck);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      {
                        customerDetailsArray.map((item, j) => (
                          <div>
                            <Row>
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                <div>
                                  <Select
                                    style={{ width: '50%' }}
                                    disabled
                                    placeholder="Select field type"
                                    value={item.type || undefined}
                                    onChange={(e) => {
                                      const list = [...customerDetailsArray];
                                      list[j].type = e;
                                      setCustomerDetailsArray(list);
                                    }}
                                  >
                                    <Option key={1} value="INPUT_EMAIL">Email</Option>
                                    <Option key={2} value="INPUT_PHONE">Phone</Option>
                                    <Option key={3} value="INPUT_NUMBER">Number</Option>
                                    <Option key={4} value="INPUT_ALPHA">Alpha</Option>
                                    <Option key={5} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                    <Option key={6} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                    <Option key={7} value="INPUT_LARGE_TEXT">Large Text</Option>
                                    <Option key={8} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                    <Option key={10} value="CONSENT_FORM">Consent Form</Option>
                                    <Option key={11} value="FILE_UPLOAD">File Upload</Option>
                                  </Select>
                                </div>
                                <SpError>
                                  {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                    { messages: { required: 'Please select field type' } })}
                                </SpError>
                              </Col>
                            </Row>
                            <Row className="mt-4 mb-4">
                              <Col span={16} push={4}>
                                <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                <Input
                                  placeholder={getPlaceholder(item.type || '')}
                                  value={item.label}
                                  maxlength="100"
                                  onChange={(e) => {
                                    const list = [...customerDetailsArray];
                                    list[j].label = e.currentTarget.value;
                                    setCustomerDetailsArray(list);
                                  }}
                                />
                                <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                <SpError>
                                  {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                    { messages: { required: 'The label field is required' } })}
                                </SpError>
                              </Col>
                            </Row>
                            {item.type === 'CONSENT_FORM'

                              ? (
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Waiver Description (optional)</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                    <TextArea
                                      rows={5}
                                      placeholder="Add the page description you want your customer to see"
                                      value={item.description}
                                      maxlength="300"
                                  // onChange={handleDescriptionChange}
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].description = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                  </Col>
                                </Row>
                              )


                              : null}

                            { item.type === 'CONSENT_FORM'
                              ? (
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Add Waiver Document </SpH5></label>
                                    <Upload
                                      name="avatar"
                                      listType="picture-card upload-card"
                                      className="avatar-uploader"
                                      showUploadList={false}
                                      beforeUpload={beforeUpload}
                                      onChange={(info) => fileUpload(info, j)}
                                    >{console.log(`123${item.documentUrl}`)}
                                      { item.documentUrl ? item.documentUrl : uploadButton}
                                    </Upload>
                                    <SpError>
                                      {
                                simpleValidator.current.message('FileName', item.documentUrl, 'required')
                                }
                                    </SpError>
                                  </Col>
                                </Row>
                              )
                              : null}

                            {item.type === 'FILE_UPLOAD' ? (
                              <>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Description</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(optional)</SpText></label>
                                    <TextArea
                                      rows={5}
                                      placeholder="Add the page description you want your customer to see"
                                      value={item.description}
                                      maxlength="300"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].description = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 300 Characters</SpText>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Document Upload Limits</SpH5></label>
                                    <div className="d-flex gap-3">
                                      <Input
                                        placeholder="Min"
                                        min={0}
                                        type="number"
                                        value={item.quantityMinLimit}
                                        maxlength="100"
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].quantityMinLimit = e.currentTarget.value;
                                          setCustomerDetailsArray(list);
                                        }}
                                      />
                                      <SpError className="ml-2">
                                        {simpleValidator.current.message(
                                          `uploadLimit${j + 1}`,
                                          customerDetailsArray[j].quantityMinLimit,
                                          `required|uploadLimit: ${customerDetailsArray[j].quantityMinLimit}`,
                                          {
                                            messages: {
                                              required: 'The min upload limit is required',
                                            },
                                          },
                                        )}
                                      </SpError>
                                      <Input
                                        placeholder="Max"
                                        max={100}
                                        type="number"
                                        value={item.quantityMaxLimit}
                                        className="ml-3"
                                        maxlength="100"
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].quantityMaxLimit = e.currentTarget.value;
                                          setCustomerDetailsArray(list);
                                        }}
                                      />
                                      <SpError className="ml-2">
                                        {simpleValidator.current.message(
                                          `uploadLimit${j + 1}`,
                                          customerDetailsArray[j].quantityMaxLimit,
                                          `required|uploadLimit: ${customerDetailsArray[j].quantityMaxLimit}`,
                                          {
                                            messages: {
                                              required: 'The max upload limit is required',
                                            },
                                          },
                                        )}
                                      </SpError>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ) : null}

                            {
                              (j >= 2) && (
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={(j < 2) || false}
                                      checked={!(item.isRequired)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].isRequired = !checked;
                                        if (checked) {
                                          list[j].checked = '';
                                        } else if (!checked) {
                                          list[j].checked = 'checked';
                                        }
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )
                            }
                          </div>
                        ))
                      }
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {current === 4 && (
                    <UpdateReviewPaymentPage
                      pageData={pageData}
                      addParent={addParent}
                      submitting={submitting}
                      productDetailsArray={productDetailsArray}
                      customerDetailsArray={customerDetailsArray}
                      account={account}
                      enableTip={enableTip}
                      isTipOptional={isTipOptional}
                      firstOption={firstOption}
                      secondOption={secondOption}
                      thirdOption={thirdOption}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Update
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentPagesDetails: state.paymentPagesDetails,
  paymentPagesDetailsStatus: state.paymentPagesDetails.paymentPagesDetailsStatus,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  accountSlug: state.accountSlug.content,
  paymentSlug: state.paymentPagesDetails.paymentSlug,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentPages: param => dispatch(paymentPagesDetailsActions.updatePaymentPages({
    payload: param,
  })),
  fetchPaymentPagesDetails: gid => dispatch(paymentPagesDetailsActions.fetchPaymentPagesDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  fetchPaymentPageSlug: param => dispatch(paymentPagesDetailsActions.fetchPaymentPageSlug({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentPage);
