import { DONATION_DETAILS_PAGES } from '../actionTypes';

export const fetchDonationPageDetails = action => ({
  type: DONATION_DETAILS_PAGES.FETCH,
  payload: action.payload,
});

export const fetchDonationPageSlug = (action) => ({
  type: DONATION_DETAILS_PAGES.SLUG_FETCH,
  payload: action.payload,
});

export const notifyDonationPage = (action) => ({
  type: DONATION_DETAILS_PAGES.NOTIFY,
  payload: action.payload,
});

export const soldOutDonationPage = (action) => ({
  type: DONATION_DETAILS_PAGES.SOLDOUT,
  payload: action.payload,
});

export const resumeSaleDonationPage = (action) => ({
  type: DONATION_DETAILS_PAGES.RESUMESALE,
  payload: action.payload,
});

export const DeactiveDonationPage = (action) => ({
  type: DONATION_DETAILS_PAGES.DELETE,
  payload: action.payload,
});

export const offlineDonation = (action) => ({
  type: DONATION_DETAILS_PAGES.DONATION_OFFLINE_DONATION,
  payload: action.payload,
});

export const fetchDonations = (action) => ({
  type: DONATION_DETAILS_PAGES.DONATION_PAYMENTS_FETCH,
  payload: action.payload,
});

export const fetchOfflineDonations = (action) => ({
  type: DONATION_DETAILS_PAGES.DONATION_OFFLINE_PAYMENTS_FETCH,
  payload: action.payload,
});

// export const fetchDonationReports = (action) => ({
//   type: DONATION_DETAILS_PAGES.FETCH_PAYMENTS_REPORT,
//   payload: action.payload,
// });
export const fetchDonationPagesEntries = (action) => ({
  type: DONATION_DETAILS_PAGES.DONATION_PAGE_FETCH,
  payload: action.payload,
});
