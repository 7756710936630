import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import DonationPagesList from './components/DonationPagesList';
import DonationPagesDetails from './components/DonationPageDetails';

const DonationPages = () => (
  <Switch>
    <Route exact path="/donation-pages" component={DonationPagesList} />
    <Route exact path="/donation-pages/:gid" component={DonationPagesDetails} />
  </Switch>
);

export default DonationPages;
