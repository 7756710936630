import { call, put } from 'redux-saga/effects';
import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  DONATION_DETAILS_PAGES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchDonationPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGES_API}/${gid}`);

const fetchDonationPageSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGE_SLUG_API}/${gid}`);

const notifyDonationPage = async gid => axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGE_NOTIFY}/${gid}/notify`);

const soldOutSelectedDonationPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.DONATIONPAGES_API}/${gid}/sold-out`);

const resumeSelectedDonationPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.DONATIONPAGES_API}/${gid}/resume-sale`);

const DeactiveDonationPage = async gid => axiosAuthInstance.patch(`${API_END_POINTS.DONATIONPAGES_API}/${gid}/deactivate`);

const offlineDonation = async payload => axiosAuthInstance.post(`${API_END_POINTS.DONATIONPAGE_LINK}/${payload.gid}/offline-payment`, payload.params);

const fetchDonations = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const queryParam = `&donationPageMetaGid.EQ=${payload.gid}`;
  const sorting = '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.GET_REPORTS}${query}${queryParam}${sorting}/donationPageMetaGid.EQ=${payload.gid}`);
};

const fetchDonationPagesEntries = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = '&sortBy=createdAt&direction=DESC';
  return axiosAuthInstance.get(`${API_END_POINTS.DONATIONPAGE_NOTIFY}${query}${sorting}&donationPageMetaGid.EQ=${payload.gid}`);
};

const fetchOfflineDonations = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = '&sortBy=createdAt&direction=DESC';
  const queryParam = `?donationPage.donationPageMetaGid.EQ=${payload.gid}`;
  const offline = '&paymentPage.offlinePaymentType.NULL=false';
  return axiosAuthInstance.get(`${API_END_POINTS.DONATION_RECEIPTS}${queryParam}${offline}${sorting}${query}`);
};

export function* getDonationPageDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchDonationPageDetails, action.payload);
    yield put({ type: DONATION_DETAILS_PAGES.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: DONATION_DETAILS_PAGES.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getDonationPageSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchDonationPageSlug, action.payload);
    yield put({ type: DONATION_DETAILS_PAGES.SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: DONATION_DETAILS_PAGES.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getNotifyDonationPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(notifyDonationPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* soldOutDonationPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(soldOutSelectedDonationPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.SOLDOUT_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* resumeSaleDonationPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(resumeSelectedDonationPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.RESUME_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deactiveDonationpage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeactiveDonationPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTPAGESDETAILS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* offlineDonationPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(offlineDonation, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Donation received successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getDonationPagesEntries(action) {
  try {
    const { data: { entity } } = yield call(fetchDonationPagesEntries, action.payload);
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_PAGE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_PAGE_FAILED, payload: e.response.data });
  }
}

export function* getDonationPagesList(action) {
  try {
    const { data: { entity } } = yield call(fetchDonations, action.payload);
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_PAYMENTS_FAILED, payload: e.response.data });
  }
}

export function* getOfflineDonationsList(action) {
  try {
    const { data: { entity } } = yield call(fetchOfflineDonations, action.payload);
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_OFFLINE_PAYMENTS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: DONATION_DETAILS_PAGES.DONATION_OFFLINE_PAYMENTS_FAILED, payload: e.response.data });
  }
}
