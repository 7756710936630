import { PAYMENT_STATUSES } from 'appconstants';

export default value => {
  if (value === 'SUCCEEDED') {
    return PAYMENT_STATUSES.SUCCEEDED;
  } if (value === 'SUCCESS') {
    return PAYMENT_STATUSES.SUCCEEDED;
  } if (value === 'CANCELLED') {
    return PAYMENT_STATUSES.CANCELLED;
  } if (value === 'REFUNDED') {
    return PAYMENT_STATUSES.REFUNDED;
  } if (value === 'PARTIAL_REFUNDED') {
    return PAYMENT_STATUSES.PARTIAL_REFUNDED;
  } if (value === 'FAILED') {
    return PAYMENT_STATUSES.FAILED;
  } if (value === 'DISPUTE_LOST') {
    return PAYMENT_STATUSES.DISPUTE_LOST;
  } if (value === 'DISPUTE_WON') {
    return PAYMENT_STATUSES.DISPUTE_WON;
  } if (value === 'DISPUTE_REVIEWING') {
    return PAYMENT_STATUSES.DISPUTE_REVIEWING;
  } if (value === 'DISPUTE_PENDING') {
    return PAYMENT_STATUSES.DISPUTE_PENDING;
  } if (value === 'REFUND_PENDING') {
    return PAYMENT_STATUSES.REFUND_PENDING;
  } if (value === 'REQUIRE_PAYMENT_METHOD') {
    return PAYMENT_STATUSES.REQUIRE_PAYMENT_METHOD;
  } if (value === 'REQUIRE_CONFIRMATION') {
    return PAYMENT_STATUSES.REQUIRE_CONFIRMATION;
  } if (value === 'REQUIRE_ACTION') {
    return PAYMENT_STATUSES.REQUIRE_ACTION;
  } if (value === 'DECLINED') {
    return PAYMENT_STATUSES.DECLINED;
  } if (value === 'DISPUTED') {
    return PAYMENT_STATUSES.DISPUTED;
  } if (value === 'DISPUTED_NEEDS_RESPONSE') {
    return PAYMENT_STATUSES.DISPUTED_NEEDS_RESPONSE;
  } if (value === 'DISPUTE_UNDER_REVIEW') {
    return PAYMENT_STATUSES.DISPUTED_UNDER_REVIEW;
  } if (value === 'REQUIRE_CAPTURE') {
    return PAYMENT_STATUSES.REQUIRE_CAPTURE;
  } if (value === 'PROCESSING') {
    return PAYMENT_STATUSES.PROCESSING;
  } if (value === 'Passed') {
    return PAYMENT_STATUSES.Passed;
  } if (value === 'REFUND_PROCESSING') {
    return PAYMENT_STATUSES.REFUND_PROCESSING;
  } if (value === 'Provided') {
    return PAYMENT_STATUSES.Provided;
  } return null;
};
